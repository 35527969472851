import { parse } from 'csv-parse/browser/esm/sync';

function detectDelimiter(csvText) {
  const delimiters = [';', ',', '\t'];
  const delimiterScores = new Map();
  delimiters.forEach((delimiter) => {
    delimiterScores.set(delimiter, 0);
  });

  const lines = csvText.split('\n');
  const maxLinesToCheck = Math.min(10, lines.length);

  // Analyze the first few lines to calculate scores for each delimiter
  for (let i = 0; i < maxLinesToCheck; i++) {
    for (const delimiter of delimiters) {
      const count = (lines[i].match(new RegExp(delimiter, 'g')) || []).length;
      // Increase the score based on the count
      delimiterScores.set(delimiter, delimiterScores.get(delimiter) + count);
      // Increase the score if there are quotation marks, which may indicate a different delimiter
      if (lines[i].includes('"')) {
        delimiterScores.set(delimiter, delimiterScores.get(delimiter) + 10);
      }
    }
  }

  // delimiter with the highest score
  let detectedDelimiter = ',';
  let maxScore = -1;

  delimiterScores.forEach((score, delimiter) => {
    if (score > maxScore) {
      maxScore = score;
      detectedDelimiter = delimiter;
    }
  });

  return detectedDelimiter;
}

export function parseCSVString(csv, delimiter = [',']) {
  // for removing unwanted quotes
  try {
    // const reg = /"/g;
    // let lines = csv.replace('\r\n', '\n').split('\n');
    // let headers = lines.shift().split(seperator);
    // let rows = lines
    //  .filter((l) => l.trim() !== '')
    //  .map((line) => line.replace(reg, '').split(seperator));
    let lines = parse(csv, { delimiter });
    let headers = lines.shift();
    let rows = lines;
    const result = rows.map((row) => {
      let obj = {};
      row.forEach((val, index) => (obj[headers[index].trim()] = val));
      return obj;
    });
    return result;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function parseCSVFromFile(file, delimiter) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      try {
        let usedDelimiter = delimiter;
        if (usedDelimiter === undefined) {
          usedDelimiter = detectDelimiter(reader.result);
        }
        const result = parseCSVString(reader.result, usedDelimiter);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
  });
}

function convertToCSV(objArray) {
  var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line !== '') line += ',';

      let currentValue = array[i][index];

      if (typeof currentValue === 'string') {
        currentValue = currentValue.replace(new RegExp('"', 'g'), '""');
      }

      line += `"${currentValue}"`;
    }

    str += line + '\r\n';
  }

  return str;
}

export function exportCSVFile(headers, items, fileTitle) {
  const formatedItems = items.map((item) => {
    let formatedItem = {};
    headers.forEach((col) => (formatedItem[col] = item[col]));
    return formatedItem;
  });
  if (headers) {
    formatedItems.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(formatedItems);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  createDownloadLink(blob, exportedFilenmae);
}

export function exportConfig(items, fileTitle) {
  const exportedFilenmae = fileTitle || '_baybe_config_export.json';
  const blob = new Blob([items], {
    type: 'application/json;charset=utf-8;',
  });
  createDownloadLink(blob, exportedFilenmae);
}

function createDownloadLink(blob, exportedFilenmae) {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
