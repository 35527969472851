import React from 'react';

import { LdButton, LdIcon, LdNotice } from '@emdgroup-liquid/liquid/dist/react';
import { Link } from 'react-router-dom';

interface Props {
  onAcknowledge?: () => void;
}

const Notification: React.FC<Props> = ({ onAcknowledge }) => {
  return (
    <LdNotice
      mode="info"
      headline=" Welcome to the new version 2.0 of BayChem!"
      className="w-full mb-ld-16"
    >
      <div className="absolute top-ld-12 left-0 flex p-ld-8 pr-ld-16 w-full justify-end">
        {onAcknowledge && (
          <LdButton
            onClick={onAcknowledge}
            size="sm"
            mode="ghost"
            className="text-black"
          >
            <LdIcon name="cross" />
          </LdButton>
        )}
      </div>
      You can find out about the new features in the{' '}
      <Link to="/changelog">Changelog</Link>. Be aware that you now have to
      explicitly trigger any recommendations, this is not automatically done
      after project creation anymore. If one of your old migrated projects shows
      an error, try to recreate it by cloning and continue the cloned project.
    </LdNotice>
  );
};

export default Notification;
