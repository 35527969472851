import {
  ProjectStatus,
  Experiment,
  Parameter,
  Project,
  ProjectRole,
  TargetVariable,
  ParameterType,
  Constraint,
} from 'types/api/types';

export const calculateCombinations = (
  parameterSpace: Parameter[],
  constraints: Constraint[] = []
) => {
  if (
    constraints.length > 0 ||
    parameterSpace.find(
      ({ parameterType }) => parameterType == ParameterType.continuous
    )
  ) {
    return Infinity;
  }
  const total = parameterSpace.reduce((prev, curr) => {
    return prev * (curr.activeValues?.length || curr.parameterValues.length);
  }, 1);
  return total;
};

export const parseTarget = (target: TargetVariable) => {
  const copy = { ...target };
  if (typeof copy.targetWeight !== 'number') {
    copy.targetWeight = parseFloat(copy.targetWeight || '1');
  }
  return copy;
};

export const calculateProgress = ({
  project,
  experiments,
}: {
  project: { parameterSpace: Parameter[]; constraints: Constraint[] };
  experiments: Experiment[];
}) => {
  const total = calculateCombinations(
    project.parameterSpace,
    project.constraints
  );
  const performed = experiments.length;
  return {
    performed,
    total,
  };
};

export const hasAtLeastRole = (role: ProjectRole, project?: Project) => {
  switch (role) {
    case ProjectRole.Viewer:
      return project !== undefined;
    case ProjectRole.Editor:
      return (
        project?.role === ProjectRole.Editor ||
        project?.role === ProjectRole.Owner
      );
    case ProjectRole.Owner:
      return project?.role === ProjectRole.Owner;
  }
};

export const isBusy = (project: Project) =>
  [ProjectStatus.creating, ProjectStatus.running].includes(project.status);
