import React from 'react';
import { LdTooltip, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import AppLayout from 'layouts/AppLayout';
import { Link } from 'react-router-dom';
import roadmapJsonData from 'assets/data/roadmap.json';

interface RoadmapPageProps {}

export const RoadmapPage: React.FC<RoadmapPageProps> = () => {
  const roadmap = roadmapJsonData as RoadmapItem[];

  return (
    <AppLayout>
      <div className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start w-full max-h-screen min-h-screen pt-8 pl-4">
          <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
            <Link to={`/projects`} className="flex hover:underline">
              <LdTypo variant="h1">Our roadmap</LdTypo>
            </Link>
          </div>

          <ol className="relative border-l border-l-rich-black-lightest border-l-2 ml-2">
            {roadmap.map((roadmapItem) => {
              return (
                <li key={roadmapItem.period} className="mb-10 ml-4">
                  <div className="absolute w-4 h-4 bg-vibrant-cyan rounded-full mt-1 -left-2"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400">
                    {roadmapItem.period}
                  </time>
                  {!!roadmapItem.title && (
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">
                      {roadmapItem.title}
                    </h3>
                  )}
                  <ul className="mb-4 ml-4 text-base font-normal list-disc">
                    {roadmapItem.features.map((feature) => {
                      const listItemStatusClassName =
                        feature.status === 'In progress'
                          ? ''
                          : 'text-rich-black-lighter';
                      const statusClassName =
                        feature.status === 'In progress'
                          ? 'bg-rich-green rounded-full'
                          : 'bg-rich-black-lighter';
                      return (
                        <li
                          key={feature.title}
                          className={`flex pb-2 ${listItemStatusClassName}`}
                        >
                          <div>
                            <LdTooltip arrow>
                              <div
                                slot="trigger"
                                className={`${statusClassName} w-2 h-2 mr-4 mt-1.5`}
                              ></div>
                              <div className="flex px-4 py-2">
                                <LdTypo className="font-black">
                                  {`Status: ${feature.status}`}
                                </LdTypo>
                              </div>
                            </LdTooltip>
                          </div>
                          <div className="">{feature.title}</div>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </AppLayout>
  );
};

type RoadmapFeatureStatus = 'Planned' | 'In progress';

type Feature = {
  title: string;
  status: RoadmapFeatureStatus;
};

type RoadmapItem = {
  period: string;
  title?: string;
  features: Feature[];
};
