import React, { useState, useEffect } from 'react';
import {
  LdSelect,
  LdLabel,
  LdOption,
} from '@emdgroup-liquid/liquid/dist/react';

import {
  AcquisitionFunctionType,
  ExpertOptions,
  InitialGuessMethodType,
  Project,
  SurrogateModelType,
} from 'types/api/types';
import {
  AcquisitionFunctionLabel,
  InitialGuessMethodLabel,
  SurroGateModelLabel,
} from 'types/app/types';
// eslint-disable-next-line import/named
import { LdSelectCustomEvent } from '@emdgroup-liquid/liquid';

interface Props {
  onChange: (arg0: ExpertOptions) => void;
  projectToClone?: Project;
}

const ExpertOptionsEntries = ({ selected }: { selected: string }) => {
  const options = [];
  for (let key of AcquisitionFunctionLabel.keys()) {
    options.push(
      <LdOption key={key} value={key} selected={selected === key}>
        {AcquisitionFunctionLabel.get(key)}
      </LdOption>
    );
  }
  return <>{options}</>;
};

const ExpertOptionsForm: React.FC<Props> = ({ onChange, projectToClone }) => {
  const [acquisitionFunction, setAcquisitionFunction] =
    useState<AcquisitionFunctionType>(AcquisitionFunctionType.EI);
  const [initialGuessMethod, setInitialGuessMethod] =
    useState<InitialGuessMethodType>(InitialGuessMethodType.rand);
  const [surrogateModel, setSurrogateModel] = useState<SurrogateModelType>(
    SurrogateModelType.GP
  );

  useEffect(() => {
    if (!projectToClone?.expertOptions) {
      return;
    }

    if (projectToClone.expertOptions.acquisitionFunction) {
      setAcquisitionFunction(projectToClone.expertOptions.acquisitionFunction);
    }
    if (projectToClone.expertOptions.surrogateModel) {
      setSurrogateModel(projectToClone.expertOptions.surrogateModel);
    }
    if (projectToClone.expertOptions.initialGuessMethod) {
      setInitialGuessMethod(projectToClone.expertOptions.initialGuessMethod);
    }
  }, [projectToClone?.expertOptions]);

  useEffect(() => {
    onChange({
      acquisitionFunction,
      initialGuessMethod,
      surrogateModel,
    });
  }, [acquisitionFunction, initialGuessMethod, surrogateModel]);
  return (
    <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
      <LdLabel>
        <span className="flex justify-between">Acquisition Function</span>
        <LdSelect
          preventDeselection
          onLdinput={(event: LdSelectCustomEvent<string[]>) => {
            setAcquisitionFunction(event.detail[0] as any);
          }}
          placeholder="Select"
        >
          <ExpertOptionsEntries selected={acquisitionFunction} />
        </LdSelect>
      </LdLabel>
      <LdLabel>
        <span className="flex justify-between">Initial Guess Method</span>
        <LdSelect
          preventDeselection
          onLdinput={(event: LdSelectCustomEvent<string[]>) => {
            setInitialGuessMethod(event.detail[0] as any);
          }}
          placeholder="Select"
        >
          {Object.keys(InitialGuessMethodType).map((key) => (
            <LdOption
              key={key}
              value={key}
              selected={initialGuessMethod === key}
            >
              {InitialGuessMethodLabel.get(key)}
            </LdOption>
          ))}
        </LdSelect>
      </LdLabel>
      <LdLabel>
        <span className="flex justify-between">Surrogate Model</span>
        <LdSelect
          preventDeselection
          onLdinput={(event: LdSelectCustomEvent<string[]>) => {
            setSurrogateModel(event.detail[0] as any);
          }}
          placeholder="Select"
        >
          {Object.keys(SurrogateModelType).map((key) => (
            <LdOption key={key} value={key} selected={surrogateModel === key}>
              {SurroGateModelLabel.get(key)}
            </LdOption>
          ))}
        </LdSelect>
      </LdLabel>
    </div>
  );
};

export default ExpertOptionsForm;
