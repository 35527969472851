import React from 'react';
import { useAuthentication } from 'services/user';
import { ReactComponent as LogoutIcon } from '../../../../assets/static/images/icon-logout.svg';

import './style.scss';
import { Link } from 'react-router-dom';
import { LdIcon } from '@emdgroup-liquid/liquid/dist/react';

interface Props {
  className?: string;
  isCollapsed?: boolean;
}

const User: React.FC<Props> = ({ isCollapsed, className }) => {
  const user = useAuthentication();
  const userLabel =
    user.info?.givenName || user.info?.familyName
      ? `${user.info.givenName} ${user.info.familyName}`
      : user.info?.email;

  return (
    <div className={className}>
      <div
        className={`h-px bg-sensitive-grey${
          user.session ? ' opacity-25' : ' opacity-0'
        }`}
      />
      <div
        className={`flex ${
          !isCollapsed ? 'justify-start' : 'justify-center'
        } px-6 py-4`}
      >
        {!isCollapsed && (
          <div
            data-test="sidebar-user-label"
            className="flex-grow -mt-px font-bold text-white truncate whitespace-no-wrap"
          >
            {!user.session ? '' : userLabel}
          </div>
        )}
        {user.session && (
          <Link to="/logout">
            <button
              data-test="sidebar-user-button-logout"
              title="Logout"
              className="ml-0 md:ml-2 focus:outline-none sidebar__user-button-logout"
            >
              <LdIcon>
                <LogoutIcon />
              </LdIcon>
            </button>
          </Link>
        )}
      </div>
      <div
        className={`h-px bg-sensitive-grey${
          user.session ? ' opacity-25' : ' opacity-0'
        }`}
      />
    </div>
  );
};

export default User;
