/* eslint-disable import/named */
import _ from 'lodash';
import { ProjectStatus } from 'types/api/types';
import { calculateProgress } from 'util/project';
import { ProjectsState } from './projects.types';
import { ExperimentsState } from '../experiments';

export const getProjectList = ({
  projects: { projects, order, sortBy },
}: {
  projects: ProjectsState;
}) => _.orderBy(Object.values(projects), [sortBy], [order]);

export const getActiveProjects = ({
  projects: { projects, order, sortBy },
}: {
  projects: ProjectsState;
}) =>
  _.orderBy(
    Object.values(projects).filter(
      ({ status }) => status !== ProjectStatus.closed
    ),
    [sortBy],
    [order]
  );

export const getArchivedProjects = ({
  projects: { projects, order, sortBy },
}: {
  projects: ProjectsState;
}) =>
  _.orderBy(
    Object.values(projects).filter(
      ({ status }) => status === ProjectStatus.closed
    ),
    [sortBy],
    [order]
  );

export const getProgressOfProject =
  (projectId: string) =>
  ({
    projects: { projects },
    experiments: { experiments },
  }: {
    projects: ProjectsState;
    experiments: ExperimentsState;
  }) => {
    return calculateProgress({
      project: projects[projectId],
      experiments: experiments[projectId],
    });
  };

export const selectedProject =
  () =>
  ({
    projects: { projects, selectedProject },
  }: {
    projects: ProjectsState;
    experiments: ExperimentsState;
  }) =>
    selectedProject ? projects[selectedProject] : undefined;

export const selectConstraintsForProject =
  (projectId: string) =>
  ({
    projects: { constraints, order, sortBy },
  }: {
    projects: ProjectsState;
  }) => {
    try {
      return _.orderBy(constraints[projectId], [sortBy], [order]);
    } catch {
      return [];
    }
  };
