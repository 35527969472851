import React from 'react';
import {
  LdAccordion,
  LdAccordionPanel,
  LdTypo,
  LdAccordionSection,
  LdAccordionToggle,
} from '@emdgroup-liquid/liquid/dist/react';
import AppLayout from 'layouts/AppLayout';
import faqJsonData from 'assets/data/faq.json';
import ReactMarkdown from 'react-markdown';

export const FaqPage: React.FC = () => {
  const faqItems = faqJsonData as FaqItem[];

  return (
    <AppLayout>
      <div className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col w-full max-h-screen min-h-screen py-8 px-4">
          <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
            <LdTypo variant="h1">Frequently Asked Questions</LdTypo>
          </div>
          <div className="flex-1">
            <LdAccordion>
              {faqItems.map((faqItem, index) => (
                <LdAccordionSection
                  key={`${index}-${faqItem.question}`}
                  expanded={index === 0}
                >
                  <LdAccordionToggle>{faqItem.question}</LdAccordionToggle>
                  <LdAccordionPanel>
                    <LdTypo
                      variant="body-s"
                      style={{
                        padding:
                          'var(--ld-sp-12) var(--ld-accordion-padding-x)',
                      }}
                    >
                      <ReactMarkdown className="markdown">
                        {faqItem.answer}
                      </ReactMarkdown>
                    </LdTypo>
                  </LdAccordionPanel>
                </LdAccordionSection>
              ))}
            </LdAccordion>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

type FaqItem = {
  question: string;
  answer: string;
};
