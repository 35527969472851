import {
  LdPagination,
  LdStep,
  LdStepper,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import React, { useMemo, useState } from 'react';
import AppLayout from '../../layouts/AppLayout';

import UserGuideData from 'assets/data/userguide.json';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const UserGuide = () => {
  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {
    trackPageView({
      documentTitle: 'User Guide',
    });
  }, []);
  const [index, setIndex] = useState<number>(0);
  const getCurrentGuide = useMemo(() => {
    return UserGuideData.find(({ order }) => order === index);
  }, [index]);
  const renderImage = () => {
    if (!getCurrentGuide || getCurrentGuide.image === undefined) return null;
    try {
      const image = require(`assets/static/images/userguide/${getCurrentGuide?.image}`);
      if (!image) return null;
      return <img src={image} className="my-ld-8" />;
    } catch {
      return null;
    }
  };
  return (
    <AppLayout>
      <div id="user-guide" className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start w-full max-h-screen min-h-screen pt-8 pl-4">
          <div className="flex flex-row">
            <div className="w-[50rem]">
              <div className="flex justify-center py-ld-8">
                <LdPagination
                  sticky={1}
                  selectedIndex={index}
                  onLdchange={(event) => {
                    trackEvent({
                      category: 'user-guide',
                      action: 'navigate',
                      name: event.detail.toString(),
                    });
                    if (
                      event.detail >= 0 &&
                      event.detail < UserGuideData.length
                    ) {
                      setIndex(event.detail);
                    } else if (event.detail < 0) {
                      setIndex(0);
                    } else if (event.detail >= UserGuideData.length) {
                      setIndex(UserGuideData.length - 1);
                    }
                  }}
                  length={UserGuideData.length}
                ></LdPagination>
              </div>
              <LdTypo variant="h3">{getCurrentGuide?.title}</LdTypo>
              {renderImage()}
              <ul className="list-disc">
                {getCurrentGuide?.bulletPoints.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </ul>
            </div>
            <div className="flex ml-ld-16">
              <LdStepper vertical fit-content>
                {UserGuideData.map(({ title, order }, i) => (
                  <LdStep
                    current={order === index}
                    done={order < index}
                    onLdstepselected={() => {
                      setIndex(i);
                      trackEvent({
                        category: 'user-guide',
                        action: 'jump',
                        name: title,
                      });
                    }}
                    key={order}
                  >
                    {title}
                  </LdStep>
                ))}
              </LdStepper>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default UserGuide;
