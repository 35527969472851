import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import {
  getInviteDetails,
  replyToInvitation,
  selectInvitationToMe,
} from '../../services/projectInvitations';
import InvitationCard from './InvitationCard';
import { ProjectInvite } from '../../types/api/types';
import LoadingOverlay from '../Project/components/LoadingOverlay';
import { LdTypo } from '@emdgroup-liquid/liquid/dist/react';

interface MailInvitationProps {}

const MailInvitation: React.FC<MailInvitationProps> = () => {
  const { projectId, inviteId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invitationToMe = useSelector(selectInvitationToMe(projectId, inviteId));

  useEffect(() => {
    if (!projectId || !inviteId) {
      return;
    }
    dispatch(getInviteDetails({ projectId, inviteId }));
  }, [dispatch, inviteId, projectId]);

  useEffect(() => {
    let timeoutInstance: any = null;
    if (invitationToMe && invitationToMe.status === 'accepted') {
      timeoutInstance = setTimeout(() => {
        navigate(`/projects/${projectId}`);
      }, 2000);
    }

    return () => {
      if (timeoutInstance) {
        timeoutInstance();
      }
    };
  }, [invitationToMe, navigate, projectId]);

  const handleActionClick = useCallback(
    (invite: ProjectInvite, action: 'accept' | 'reject') => {
      dispatch(
        replyToInvitation({
          projectId: invite.projectId,
          inviteId: invite.inviteId,
          action,
        })
      );
    },
    [dispatch]
  );

  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center">
      <div className="relative flex flex-row max-w-[1024px] mx-auto">
        {invitationToMe ? (
          <div className="flex flex-col items-center">
            <LdTypo variant="h3" className="mb-4">
              You have been invited to project
            </LdTypo>
            <InvitationCard
              invitation={invitationToMe}
              onActionClick={handleActionClick}
            />
          </div>
        ) : (
          <LoadingOverlay />
        )}
      </div>
    </div>
  );
};

export default MailInvitation;
