import {
  LdInputMessage,
  LdTable,
  LdTableBody,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
} from '@emdgroup-liquid/liquid/dist/react';
import { TargetTableAddRow } from 'components/ChemicalSpace/TargetList/TargetTableAddRow';
import { TargetTableFooter } from 'components/ChemicalSpace/TargetList/TargetTableFooter';
import { TargetTableItemRow } from 'components/ChemicalSpace/TargetList/TargetTableItemRow';
import { useTargetRowsWithErrors } from 'components/ChemicalSpace/TargetList/useTargetRowsWithErrors';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TargetVariable } from 'types/api/types';
import { castToNumberSafely } from 'util/utils';
import { tableColumns } from './tableColumns';
import { TargetFormState, Targets } from './types';

interface TargetsListProps {
  targets: Targets;
  readOnly?: boolean;
  onRemoveTarget?: (arg0: string) => void;
  onAddTarget?: (target: TargetVariable) => void;
  onEditTarget?: (targetKey: string, target: TargetVariable) => void;
}

export const TargetsList: FC<TargetsListProps> = ({
  targets,
  onRemoveTarget,
  readOnly,
  onAddTarget,
  onEditTarget,
}) => {
  const formMethods = useForm<TargetFormState>({
    mode: 'onChange',
    defaultValues: {
      targetName: '',
      targetWeight: '1',
      lowerBound: '',
      upperBound: '',
      targetObjective: 'Max',
    },
  });

  const { handleSubmit, setValue, formState, reset } = formMethods;

  const { errors } = formState;

  const errorEntries = Object.entries(errors);

  const [editingTarget, setEditingTarget] = useState<string>();

  const targetRowsWithErrors = useTargetRowsWithErrors(targets);
  const rowsWithErrorsEntries = Object.entries(targetRowsWithErrors);

  const formDataToSubmitData = (formData: TargetFormState): TargetVariable => {
    return {
      ...formData,
      targetWeight: castToNumberSafely(formData.targetWeight),
      lowerBound: castToNumberSafely(formData.lowerBound),
      upperBound: castToNumberSafely(formData.upperBound),
    };
  };

  return (
    <FormProvider {...formMethods}>
      <LdTable className="table-auto min-w-full mb-4">
        <LdTableHead>
          <LdTableRow>
            {tableColumns.map(({ title, key }) => (
              <LdTableHeader key={key} className="px-4 text-left">
                {title}
              </LdTableHeader>
            ))}

            {!readOnly && (
              <LdTableHeader className="px-4 text-left">Actions</LdTableHeader>
            )}
          </LdTableRow>
        </LdTableHead>
        <LdTableBody className="divide-y divide-sensitive-grey-darker">
          {Object.keys(targets).map((targetKey) => {
            const target = targets[targetKey];
            const isEditing = editingTarget === targetKey;
            const hasError = !!targetRowsWithErrors?.[targetKey];

            return (
              <TargetTableItemRow
                key={`inputs_${targetKey}`}
                target={target}
                hasError={hasError}
                isEditing={isEditing}
                readOnly={readOnly}
                onEditClick={() => {
                  setEditingTarget(targetKey);
                  reset();
                  Object.entries(target).forEach(([key, value]) => {
                    setValue(key as keyof TargetVariable, value);
                  });
                }}
                onRemoveClick={() => onRemoveTarget?.(targetKey)}
              />
            );
          })}
          {!readOnly && (
            <TargetTableAddRow
              editingTarget={!!editingTarget}
              targets={targets}
              onAddClick={handleSubmit((data) => {
                if (onAddTarget) {
                  onAddTarget?.(formDataToSubmitData(data));
                }
                reset();
              })}
              onEditClick={handleSubmit((data) => {
                if (editingTarget) {
                  onEditTarget?.(editingTarget, formDataToSubmitData(data));
                  setEditingTarget(undefined);
                  reset();
                }
              })}
              onEditCancelClick={() => {
                setEditingTarget(undefined);
                reset();
              }}
            />
          )}
          {!Object.keys(targets)?.length && (
            <LdInputMessage mode="info" className="ml-1 max-w-[200px]">
              For a single target in Min/Max mode, you can leave both bounds
              empty
            </LdInputMessage>
          )}
        </LdTableBody>
        {(!!errorEntries.length || !!rowsWithErrorsEntries.length) && (
          <TargetTableFooter
            errorsEntries={errorEntries}
            rowsWithErrorsEntries={rowsWithErrorsEntries}
          />
        )}
      </LdTable>
    </FormProvider>
  );
};
