import React from 'react';
import { LdTypo } from '@emdgroup-liquid/liquid/dist/react';

interface Props {
  entries: {
    version: string;
    date: string;
    bulletPoints: string[];
  }[];
}

const WhatsNew: React.FC<Props> = ({ entries }) => {
  return (
    <div className="flex flex-col w-full">
      {entries.map(({ version, date, bulletPoints }) => (
        <div key={version} className="my-ld-8">
          <LdTypo variant="h4">
            {version} ({date})
          </LdTypo>
          <ul className="list-disc list-inside">
            {bulletPoints.map((text) => (
              <li key={text} className="list-item mt-ld-4">
                {text}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default WhatsNew;
