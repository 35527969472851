import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './util/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import '@emdgroup-liquid/liquid/dist/css/liquid.css';
import '@emdgroup-liquid/liquid/dist/css/liquid.global.css';
import { defineCustomElements } from '@emdgroup-liquid/liquid/dist/loader';

import './index.css';

import App from './App';
// import reportWebVitals from "./reportWebVitals";

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
window.__LD_ASSET_PATH__ = `${window.location.origin}/liquid/`;

defineCustomElements();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
