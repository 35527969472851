import React, { FormEventHandler, useCallback } from 'react';
import { LdInput, LdInputMessage } from '@emdgroup-liquid/liquid/dist/react';
import { ErrorOption, FormState, UseFormRegister } from 'react-hook-form';
import { getNumericValueType } from './getNumericValueFromInput';

interface FieldValues {
  paramValue?: string;
}
interface ParameterInputCategoryProps {
  // value;
  setValue: (value: string) => void;
  setError: (errorOption: ErrorOption) => void;
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  value?: string;
}

export const ParameterInputNumber: React.FC<ParameterInputCategoryProps> = ({
  setValue: setValueProp,
  formState,
  register,
  value,
}) => {
  const { errors } = formState;

  const handleChange: FormEventHandler<HTMLLdInputElement> = useCallback(
    (event) => {
      const { value } = event.currentTarget;
      if (!value) {
        return;
      }
      setValueProp(value);
    },
    [setValueProp]
  );

  return (
    <div className="flex flex-col flex-grow">
      <LdInput
        tone="dark"
        type={'text'}
        placeholder="e.g. 5 or 1-5;1"
        className="flex-grow my-2 mr-2"
        {...register('paramValue', {
          required: 'Value is required',
          validate: (value) => {
            const inputType = getNumericValueType(value || '');
            if (inputType === 'invalid') {
              return 'Wrong format. Use numeric (e.g. 1) or "start-end;stride" (e.g. 1-3;1 or -1--3;-1)';
            }
            if (inputType === 'invalid_range') {
              return 'Invalid range. Start should be smaller than end and the stride should be positive';
            }
            return true;
          },
        })}
        invalid={errors.paramValue && errors.paramValue.type !== 'required'}
        onInput={handleChange}
        value={value}
      />
      <LdInputMessage
        className={
          errors.paramValue && errors.paramValue.type !== 'required'
            ? 'visible'
            : 'invisible'
        }
        mode={errors.paramValue ? 'error' : 'valid'}
      >
        {errors.paramValue?.message || 'Value must be a number.'}
      </LdInputMessage>
    </div>
  );
};
