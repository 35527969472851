import {
  LdButton,
  LdIcon,
  LdOption,
  LdSelect,
} from '@emdgroup-liquid/liquid/dist/react';
import * as yup from 'yup';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import { camelCaseToSpaces } from 'util/utils';
// eslint-disable-next-line import/named
import { LdSelectCustomEvent } from '@emdgroup-liquid/liquid';

const optionsSchema = yup
  .array(
    yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required()
  )
  .required();

type Options = yup.InferType<typeof optionsSchema>;

interface Props {
  onChange: (arg0: { orderBy?: string; order: 'asc' | 'desc' }) => void;
  options: Options | { [key: string]: any };
  default?: string;
}

const SortingBar: React.FC<Props> = ({
  default: defaultOrderBy,
  options,
  onChange,
}) => {
  const [orderBy, setOrderBy] = useState<string | undefined>(defaultOrderBy);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const handleChangeOrder = useCallback(() => {
    setOrder((state) => (state === 'asc' ? 'desc' : 'asc'));
  }, []);
  const handleChangeOrderBy = useCallback(
    (event: LdSelectCustomEvent<string[]>) => {
      setOrderBy(event.detail[0]);
    },
    []
  );

  const orderByOptions = useMemo(() => {
    if (optionsSchema.isValidSync(options)) return options;
    return Object.keys(options).map((key) => ({
      label: camelCaseToSpaces(key),
      value: key,
    }));
  }, [options]);

  useEffect(() => {
    onChange({ order, orderBy });
  }, [order, orderBy, onChange]);

  return (
    <div className="flex">
      <LdSelect
        onLdinput={handleChangeOrderBy}
        placeholder="Order by"
        mode="ghost"
        size="sm"
      >
        {orderByOptions.map(({ label, value }) => (
          <LdOption key={value} value={value} selected={orderBy === value}>
            {label}
          </LdOption>
        ))}
        <LdIcon name="arrow-up-n-down" slot="icon" />
      </LdSelect>
      <LdButton
        data-tip={order === 'asc' ? 'ascending' : 'descending'}
        size="sm"
        mode="ghost"
        onClick={handleChangeOrder}
        type="button"
      >
        <LdIcon name={order === 'asc' ? 'arrow-up' : 'arrow-down'} />
      </LdButton>
      <ReactTooltip type="light" effect="solid" className="tooltip" />
    </div>
  );
};

export default SortingBar;
