import React, { FC } from 'react';
import { LdLoading } from '@emdgroup-liquid/liquid/dist/react';

const LoadingOverlay: FC = () => (
  <div className="absolute w-full top-0 bottom-0 text-center flex justify-center items-center z-50 rounded-[0.375rem] opacity-50">
    <LdLoading neutral className="ld-loading-black" />
  </div>
);

export default LoadingOverlay;
