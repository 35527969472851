import { useUser } from '@emdgroup/react-auth';
import assert from 'assert';
import axios from 'axios';
// import { assert } from 'chai';
// import { useEffect, useMemo, useState } from 'react';
// import { setStatus, setError } from 'services/inputs';
// import { setProjectStatus } from 'services/projects';
// import { isCreateExperimentsResponse, ProjectStatus } from 'types/api/types';
import { BugFormData, Feedback } from 'types/app/types';
import { blobToBase64 } from 'util/utils';
// import { REQUEST_OPTIONS } from 'util/api';
import { API_ENDPOINT } from './useApi';

// const {
//   FEEDBACK_ENDPOINT = 'https://vnhzz7oz3a.execute-api.eu-central-1.amazonaws.com',
// } = process.env;

const apiEndpoint = API_ENDPOINT;

export const useFeedback = () => {
  const { authHeader } = useUser();
  // const [feedbackForm, setFeedbackForm] = useState<FeedbackProduct>();
  // const saveToStorage = (form: FeedbackProduct) =>
  //   sessionStorage.setItem(`feedback_${productId}`, JSON.stringify(form));
  // const retrieveFromSTorage = () => {
  //   try {
  //     return JSON.parse(sessionStorage.getItem(`feedback_${productId}`)!);
  //   } catch {
  //     return null;
  //   }
  // };
  // const request = (path = '', method: string, data?: any, options?: any) => {
  //   let init: RequestInit = {
  //     ...REQUEST_OPTIONS(),
  //     method,
  //   };
  //   if (data !== undefined) {
  //     init.body = JSON.stringify(data);
  //   }
  //   if (options !== undefined) {
  //     init = { ...init, ...options };
  //   }
  //   return fetch(`${FEEDBACK_ENDPOINT}/product/${productId}${path}`, init);
  // };

  // const getQuestions = async () => {
  //   const response = await request(undefined, 'GET');
  //   const data = await response.json();
  //   return data as FeedbackProduct;
  // };

  // useEffect(() => {
  //   const stored = retrieveFromSTorage();
  //   if (stored !== null) {
  //     setFeedbackForm(stored);
  //   } else {
  //     getQuestions().then((resp) => {
  //       setFeedbackForm(resp);
  //       saveToStorage(resp);
  //     });
  //   }
  // }, [productId]);

  // const questions = useMemo(() => {
  //   if (feedbackForm !== undefined) {
  //     return Object.values(feedbackForm.questions);
  //   }
  //   return [];
  // }, [feedbackForm]);

  // const sendFeedback = (feedback: FeedbackQuestion[]) => {
  //   return request('/feedback', 'POST', feedback).then(async (response) => {
  //     if (response.ok) {
  //       try {
  //         const d = await response.json();
  //         return d;
  //       } catch {
  //         return true;
  //       }
  //     } else if (response.bodyUsed) {
  //       throw new Error(await response.json());
  //     } else {
  //       throw new Error(`${response.status.toString()} ${response.statusText}`);
  //     }
  //   });
  // };

  const sendFeedback = async (feedback: Feedback) => {
    return axios.post(`${apiEndpoint}/feedback`, feedback, {
      headers: authHeader,
    });
  };

  const sendBug = async ({
    title,
    stepsToReproduce,
    expectedBehavior,
    systemInformation,
    attachment,
  }: BugFormData) => {
    assert(title);
    assert(stepsToReproduce);
    const data: Record<string, string | undefined> = {
      title,
      steps_to_reproduce: stepsToReproduce,
      expected_behavior: expectedBehavior,
      system_information: systemInformation,
    };
    if (attachment) {
      data['attachment_name'] = attachment.name;
      data['attachment'] = await blobToBase64(attachment);
    }

    return axios.post(`${apiEndpoint}/bugs`, data, {
      headers: authHeader,
    });
  };

  return { sendFeedback, sendBug };
};
