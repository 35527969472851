import _ from 'lodash';
import { Experiment, ExperimentResults } from 'types/api/types';
export const getResult = (results: ExperimentResults, targetName: string) => {
  return (results as { [key: string]: number })[targetName];
};
export const closestBy = (
  experiments: Experiment[],
  target: string,
  mean: number
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  try {
    const res: any = _.minBy(
      experiments.map((exp) => {
        const res = (exp.results as { [key: string]: number })[target];
        return {
          ...exp,
          distance: res > mean ? res - mean : mean - res,
        };
      }),
      'distance'
    );
    if (res !== undefined) {
      return res.results[target] as number;
    }
  } catch {
    return undefined;
  }
};

function linearTransform(
  val: number,
  lower: number,
  upper: number,
  weight: number,
  descending: boolean
): number {
  let res;
  if (descending) {
    res = Math.max(0.0, Math.min(1.0, (upper - val) / (upper - lower)));
  } else {
    res = Math.max(0.0, Math.min(1.0, (val - lower) / (upper - lower)));
  }

  return res ** weight;
}

function triangularTransform(
  val: number,
  lower: number,
  upper: number,
  weight: number
): number {
  const mid = lower + (upper - lower) / 2;
  let res;

  if (val <= mid) {
    res = Math.max(0.0, (val - lower) / (mid - lower));
  } else {
    res = Math.max(0.0, (upper - val) / (upper - mid));
  }

  return res ** weight;
}

function geomMean(numbers: number[] | undefined, x: number): number {
  if (!numbers) {
    return NaN;
  }
  const product = numbers?.reduce(
    (accumulator, currentValue) => accumulator * currentValue,
    1
  );
  return Math.pow(product, x);
}

export { linearTransform, triangularTransform, geomMean };
