import { RoleFilter } from 'types/app/types';
import { useUser } from '@emdgroup/react-auth';
import { useMemo } from 'react';
import { filterProjectByTerm } from 'services/search';
import { Project } from 'types/api/types';

type Filters = {
  query?: string;
  role?: RoleFilter;
};

export const useFilteredProjects = (
  projects: Project[],
  { query, role }: Filters
) => {
  const { info } = useUser();
  const userId = info?.sub;

  const filteredProjectsBySearchTerm = useMemo(() => {
    if (!query) return projects;
    return projects.filter((p) => filterProjectByTerm(p, query));
  }, [projects, query]);

  return useMemo(() => {
    switch (role) {
      case 'createdByMe':
        return filteredProjectsBySearchTerm.filter(
          (project) => project.projectOwner === userId
        );
      case 'Editor':
      case 'Owner':
      case 'Viewer': {
        return filteredProjectsBySearchTerm.filter(
          (project) => project.role === role
        );
      }
      default:
        return filteredProjectsBySearchTerm;
    }
  }, [role, filteredProjectsBySearchTerm, userId]);
};
