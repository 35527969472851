import { recommendationsSlice } from './recommendations.slice';

export const {
  updateRecommendation,
  selectRecommendation,
  unselectRecommendation,
  removeRecommendations,
  setRecommendations,
  addRecommendation,
} = recommendationsSlice.actions;

export {
  listRecommendationsForProject,
  recreateRecommendations,
  rejectRecommendation,
} from './recommendations.effects';

export * from './recommendations.selectors';

export const reducerName = recommendationsSlice.name;
export default recommendationsSlice.reducer;
