export enum NotificationType {
  'info' = 'info',
  'alert' = 'alert',
  'warn' = 'warn',
}

interface NotificationProps {
  content: string;
  type?: NotificationType;
  timeout?: number;
}

const useNotification = () => {
  const clearNotifications = () => {
    dispatchEvent(new CustomEvent('ldNotificationClear'));
  };

  const dismissNotification = () =>
    dispatchEvent(new CustomEvent('ldNotificationDismiss'));

  const sendNotification = ({
    content,
    type = NotificationType.info,
    timeout = 1000,
  }: NotificationProps) => {
    dispatchEvent(
      new CustomEvent('ldNotificationAdd', {
        detail: {
          content: content,
          type: type,
          timeout: timeout,
        },
      })
    );
  };
  return {
    clearNotifications,
    dismissNotification,
    sendNotification,
  };
};

export default useNotification;
