import React, { useCallback, useMemo, useRef } from 'react';
import type { FormEvent, SyntheticEvent } from 'react';
import {
  LdButton,
  LdCard,
  LdInput,
  LdInputMessage,
  LdLabel,
  LdRadio,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import { useDispatch } from '../../../store';
import { useForm } from 'react-hook-form';
import { createInvitation } from '../../../services/projectInvitations';
import { ProjectRole } from '../../../types/api/types';
import { useAuthentication } from 'services/user';
import { getEmailAliases } from 'util/utils';

interface ShareFormProps {
  projectId: string;
}

const ShareForm: React.FC<ShareFormProps> = ({ projectId }) => {
  const dispatch = useDispatch();

  const { info, identity } = useAuthentication();

  const { formState, getValues, register, setValue, reset, watch } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
      role: ProjectRole.Viewer,
    },
  });

  const { errors } = formState;
  const { role, email } = watch();
  const formRef = useRef<HTMLFormElement>(null);

  const submit = async () => {
    reset();
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    submit();
  };

  const valiedateEmail = useCallback(
    (email: string) => {
      return (
        (!getEmailAliases(info?.email.toLowerCase() || '').includes(
          email.toLowerCase()
        ) &&
          email
            .toLowerCase()
            .indexOf(`${identity?.externalId.toLowerCase()}`) == -1) ||
        "You can't invite yourself!"
      );
    },
    [info, identity]
  );

  const handleShareClick = useCallback(() => {
    if (!projectId) {
      return;
    }
    dispatch(
      createInvitation({
        projectId,
        body: {
          role,
          email,
        },
      })
    );
  }, [dispatch, email, projectId, role]);

  let buttons: JSX.Element[];
  buttons = useMemo(() => {
    return Object.values(ProjectRole).map((roleItem) => {
      const isSelected = role === roleItem;
      return (
        <LdLabel key={roleItem} size="m" position="right" className="mr-2">
          <LdRadio
            name="role"
            value={roleItem}
            checked={isSelected}
            onLdchange={(evt) => {
              setValue('role', evt.target.value as ProjectRole);
            }}
          />
          {roleItem}
        </LdLabel>
      );
    });
  }, [role, setValue]);

  return (
    <LdCard size="sm" className="mb-8">
      <LdTypo variant="h4">Invite people to the project</LdTypo>
      <div className="w-2/5 border-b border-b-sensitive-grey-darkest mb-2" />

      <form autoComplete="off" onSubmit={handleFormSubmit} ref={formRef}>
        <div>
          <LdLabel className={'w-full'}>
            Email
            <LdInput
              placeholder="Eg. name@mail.com"
              tone="dark"
              {...register('email', {
                required: 'required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
                validate: { valiedateEmail },
              })}
              type="email"
              onInput={(ev: SyntheticEvent<HTMLLdInputElement>) => {
                setValue('email', ev.currentTarget.value || '', {
                  shouldValidate: true,
                });
              }}
              invalid={Boolean(errors.email)}
            />
            <LdInputMessage
              className={errors.email ? 'visible' : 'invisible'}
              mode={errors.email ? 'error' : 'valid'}
            >
              {errors.email?.message}
              {/* {errors.email && 'Email is required.'} */}
            </LdInputMessage>
          </LdLabel>

          <div
            className="flex items-center justify-between overflow-hidden"
            style={{
              height: !!getValues('email') && !errors.email ? 64 : 0,
              transition: 'height ease-out 0.3s',
            }}
          >
            <div className="">
              <LdLabel>
                <span className="flex justify-between">Role</span>
              </LdLabel>

              <div className="flex mb-5">{buttons}</div>
            </div>

            <LdButton onClick={handleShareClick} mode="highlight">
              Send
            </LdButton>
          </div>
        </div>
      </form>
    </LdCard>
  );
};

export default ShareForm;
