import React, { FC, FormEventHandler } from 'react';
import {
  LdButton,
  LdInput,
  LdInputMessage,
  LdLabel,
} from '@emdgroup-liquid/liquid/dist/react';
import { Feedback } from 'types/app/types';
import { useForm } from 'react-hook-form';

interface Props {
  onSubmit: (arg0: Feedback) => void;
  onCancel: () => void;
}

const FeedbackForm: FC<Props> = ({ onSubmit, onCancel }) => {
  const { formState, register, setValue, trigger, clearErrors, watch, reset } =
    useForm<Feedback>({
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

  const { errors } = formState;
  const { subject, message } = watch();

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = await trigger();
    if (!isValid) return;
    onSubmit({ message, subject });
    clearErrors();
    reset();
  };

  const handleCancel = () => {
    clearErrors();
    reset();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-ld-16">
        <LdLabel className="w-full">
          Subject
          <LdInput
            className="w-full"
            onInput={(ev) =>
              setValue('subject', (ev.target as HTMLLdInputElement).value)
            }
            value={subject}
            {...register('subject', {
              required: false,
            })}
          />
        </LdLabel>
        <LdLabel className="w-full">
          Tell us what you think about it
          <LdInput
            className="w-full"
            onInput={(ev) =>
              setValue('message', (ev.target as HTMLLdInputElement).value)
            }
            multiline
            rows={3}
            value={message}
            {...register('message', {
              required: true,
            })}
          />
          {errors.message !== undefined && (
            <LdInputMessage>Message is required</LdInputMessage>
          )}
        </LdLabel>
      </div>
      <div className="flex flex-row justify-end gap-ld-8 mt-ld-16">
        <LdButton type="button" onClick={handleCancel} mode="danger-secondary">
          Cancel
        </LdButton>
        <LdButton>Submit</LdButton>
      </div>
    </form>
  );
};

export default FeedbackForm;
