import { LdLoading, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import React from 'react';
import { ProjectStatus } from 'types/api/types';

import './styles.scss';

interface Props {
  status: ProjectStatus;
}
const ProjectStatusBadge: React.FC<Props> = ({ status }) => {
  return (
    <div
      className={`badge flex items-center rounded-l px-ld-8 ${
        status === ProjectStatus.error ? 'bg-rich-red' : 'bg-vibrant-yellow'
      }`}
      style={{ display: 'flex' }}
    >
      {(status === ProjectStatus.creating ||
        status === ProjectStatus.running) && (
        <LdLoading neutral className="ld-loading-black mr-ld-4" />
      )}
      <LdTypo
        variant="body-xs"
        className={`${status === ProjectStatus.error && 'text-white'}`}
      >
        {status}
      </LdTypo>
    </div>
  );
};

export default ProjectStatusBadge;
