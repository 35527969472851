import usePrevious from 'hooks/usePrevious';
import { useEffect, useState } from 'react';

export function useWatchChange<T = unknown>(
  value: T,
  from: T,
  to: T,
  callback?: () => void
) {
  const previous = usePrevious(value);
  const [hasChanged, setHasChanged] = useState(false);
  useEffect(() => {
    const changed = previous === from && value === to;
    setHasChanged(changed);
    if (changed && callback) {
      callback();
    }
  }, [from, previous, to, value]);

  return hasChanged;
}
