/* eslint-disable import/named */
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  LdButton,
  LdIcon,
  LdInput,
  LdInputMessage,
  LdLabel,
  LdLoading,
} from '@emdgroup-liquid/liquid/dist/react';
import { ReactComponent as IconPlus } from 'assets/static/images/icon-plus.svg';
import {
  CustomParameterValue,
  ParameterType,
  SmilesParameterValue,
  NumericalContinuousParameter,
} from '../../../types/api/types';
import { validateSmiles } from 'util/chemical';
import { useSearch } from 'services/search';
import { useHints } from 'hooks/useHints';
import { Hits } from './Hits';
import { MoleculeEditorModal } from './MoleculeEditorModal';
import { ParameterInputCustom } from './ParameterInputCustom';
import {
  getNumericValueFromInput,
  getNumericValueType,
} from './getNumericValueFromInput';
import { ParameterInputNumber } from './ParameterInputNumber';
import { useDebounce } from 'hooks/useDebounce';
import LoadingOverlay from 'pages/Project/components/LoadingOverlay';

type FormData = {
  paramValue?: string;
  paramSmilesValue?: string;
  paramLowerBound?: string;
  paramUpperBound?: string;
};

interface ParameterValuesFormProps {
  onSubmit: (
    arg0:
      | string
      | SmilesParameterValue
      | CustomParameterValue[]
      | string[]
      | NumericalContinuousParameter
  ) => void;
  parameterType?: ParameterType;
  tetherOptions: any;
}

export const ParameterValuesForm: React.FC<ParameterValuesFormProps> = ({
  onSubmit,
  parameterType,
  tetherOptions,
}) => {
  const {
    formState,
    getValues,
    register,
    setValue,
    trigger,
    watch,
    setError,
    clearErrors,
  } = useForm<FormData>({
    mode: 'onChange',
    // reValidateMode: 'onChange',
  });

  const { wrapLabel } = useHints();

  const watchedParamValue = watch('paramValue');
  const watchedSmilesValue = watch('paramSmilesValue');
  const watchedLowerBound = watch('paramLowerBound');
  const watchedUpperBound = watch('paramUpperBound');

  const smilesNameRef = useRef<HTMLLdInputElement | null>(null);
  // const { query, refine } = useSearchBox(props);
  const { query, refine, hits, status } = useSearch();
  // const refineDebounced = _.debounce(refine, 300);

  const { dirtyFields, errors } = formState;
  const isFormDirty = formState.submitCount > 0;
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(false);

  const [customParameter, setCustomParameter] =
    useState<CustomParameterValue[]>();
  const [customParameterError, setCustomParameterError] = useState<
    string | undefined
  >();

  const handleFormInvalid = React.useCallback(() => {
    setValue('paramValue', '');
    setValue('paramSmilesValue', '');
  }, [setValue]);

  const handleSubmit = async () => {
    const { paramValue, paramSmilesValue, paramLowerBound, paramUpperBound } =
      getValues();
    await trigger();
    if (
      parameterType === ParameterType.custom &&
      customParameter !== undefined
    ) {
      onSubmit(customParameter);
    } else if (
      parameterType === ParameterType.smiles &&
      paramValue !== undefined &&
      paramSmilesValue !== undefined
    ) {
      onSubmit({ SMILES: paramSmilesValue, Name: paramValue });
    } else if (
      parameterType === ParameterType.numeric &&
      paramValue !== undefined
    ) {
      const value = getNumericValueFromInput(paramValue);
      if (typeof value === 'number') {
        onSubmit(value.toString());
      } else if (Array.isArray(value)) {
        onSubmit(value.map((v) => v.toString()));
      }
      setValue('paramValue', '');
    } else if (parameterType === ParameterType.continuous) {
      if (
        paramLowerBound !== undefined &&
        paramLowerBound !== '' &&
        paramUpperBound !== undefined &&
        paramUpperBound !== ''
      ) {
        onSubmit([Number(paramLowerBound), Number(paramUpperBound)]);
      }
    } else if (paramValue !== undefined) {
      onSubmit(paramValue);
      setValue('paramValue', '');
    }
    handleFormInvalid();
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = await trigger();
    if (!isValid || !isFormValid) return;
    handleSubmit();
  };

  const handleSelectSearchResult = (val: SmilesParameterValue) => {
    setValue('paramValue', val.Name);
    setValue('paramSmilesValue', val.SMILES, {
      shouldValidate: isFormDirty || dirtyFields.paramSmilesValue,
    });
    setShowSearch(false);
  };

  const debouncedParamValue = useDebounce(watchedParamValue, 300);

  useEffect(() => {
    if (parameterType !== ParameterType.smiles) {
      return;
    }
    if (
      debouncedParamValue !== undefined &&
      query !== debouncedParamValue &&
      debouncedParamValue.length > 2
    ) {
      refine(debouncedParamValue);
      if (document.activeElement === smilesNameRef.current) {
        setShowSearch(true);
      }
    }
  }, [debouncedParamValue, parameterType, query]);

  const validateBounds =
    (valueName: 'paramLowerBound' | 'paramUpperBound') => (value: any) => {
      if (
        valueName == 'paramLowerBound' &&
        Number(value) <= Number(getValues(valueName))
      ) {
        return 'Lower Bound should be less than Upper Bound';
      }
      if (
        valueName == 'paramUpperBound' &&
        Number(value) >= Number(getValues(valueName))
      ) {
        return 'Lower Bound should be less than Upper Bound';
      }
      if (isNaN(Number(value))) {
        return 'Input must be a number';
      }
      return true;
    };

  const [isSmilesValidationLoading, setIsSmilesValidationLoading] =
    useState(false);

  const debouncedSmilesString = useDebounce(watchedSmilesValue, 300);
  const debouncedLowerBound = useDebounce(watchedLowerBound, 500);
  const debouncedUpperBound = useDebounce(watchedUpperBound, 500);

  useEffect(() => {
    if (
      debouncedLowerBound !== undefined &&
      debouncedLowerBound !== '' &&
      debouncedUpperBound !== undefined &&
      debouncedUpperBound !== ''
    ) {
      const isInvalid: boolean =
        isNaN(Number(debouncedLowerBound)) ||
        isNaN(Number(debouncedUpperBound)) ||
        Number(debouncedLowerBound) >= Number(debouncedUpperBound);

      if (isInvalid) {
        setError('paramLowerBound', {
          message: 'Lower Bound should be less than Upper Bound',
        });
      } else {
        clearErrors('paramLowerBound');
        clearErrors('paramUpperBound');
      }
      handleSubmit();
    }
  }, [debouncedLowerBound, debouncedUpperBound]);

  useEffect(() => {
    if (watchedSmilesValue) {
      setIsSmilesValidationLoading(true);
    }
  }, [watchedSmilesValue]);

  useEffect(() => {
    if (debouncedSmilesString !== undefined && debouncedSmilesString !== '') {
      validateSmiles(debouncedSmilesString).then((result) => {
        setIsSmilesValidationLoading(false);
        const { valid, error, smiles } = result;

        if (valid === true) {
          clearErrors('paramSmilesValue');
          if (!!smiles && smiles !== debouncedSmilesString) {
            setValue('paramSmilesValue', smiles);
          }
        } else {
          setError('paramSmilesValue', { message: error || 'unknown error' });
        }
      });
    }
  }, [clearErrors, setError, debouncedSmilesString]);

  useEffect(() => {
    if (customParameter !== undefined) {
      handleSubmit();
    }
  }, [customParameter, watchedLowerBound, watchedUpperBound]);

  const smilesNameRegistration = register('paramValue', {
    required: 'Molecule Name is required',
  });

  const isFormValid = useMemo<boolean>(() => {
    if (
      isFormDirty ||
      errors.paramValue?.message ||
      errors.paramSmilesValue?.message ||
      isSmilesValidationLoading ||
      errors.paramLowerBound?.message ||
      errors.paramUpperBound?.message
    ) {
      return false;
    }

    const paramValue = watchedParamValue;

    const paramValueIsNotEmpty =
      paramValue !== undefined && paramValue.length > 0;

    const smilesParamValueIsNotEmpty =
      watchedSmilesValue !== undefined && watchedSmilesValue.length > 0;
    const paramBoundsAreValid =
      watchedLowerBound !== undefined &&
      watchedUpperBound !== undefined &&
      parseFloat(watchedLowerBound) > parseFloat(watchedUpperBound);

    const isCustomParameterValid =
      parameterType === ParameterType.custom && customParameter !== undefined;

    const isSmilesValid =
      parameterType === ParameterType.smiles &&
      paramValueIsNotEmpty &&
      smilesParamValueIsNotEmpty;

    const isNumericValid =
      parameterType === ParameterType.numeric &&
      paramValueIsNotEmpty &&
      getNumericValueType(paramValue) !== 'invalid';

    const isTaskCategoryValid =
      (parameterType === ParameterType.task ||
        parameterType === ParameterType.category) &&
      paramValueIsNotEmpty;

    const isContinuousValid =
      parameterType === ParameterType.continuous && paramBoundsAreValid;

    return (
      isCustomParameterValid ||
      isSmilesValid ||
      isNumericValid ||
      isTaskCategoryValid ||
      isContinuousValid
    );
  }, [
    isSmilesValidationLoading,
    isFormDirty,
    watchedSmilesValue,
    watchedParamValue,
    watchedLowerBound,
    watchedUpperBound,
    customParameter,
    errors.paramSmilesValue?.message,
    errors.paramValue?.message,
    errors.paramLowerBound?.message,
    errors.paramUpperBound?.message,
    // getValues,
    parameterType,
  ]);

  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <div className="flex left-0 right-0 ml-auto mr-auto">
          {parameterType === ParameterType.custom && (
            <ParameterInputCustom
              setValue={setCustomParameter}
              setError={setCustomParameterError}
              error={customParameterError}
            />
          )}
          {parameterType === ParameterType.numeric && (
            <ParameterInputNumber
              setValue={(value) => setValue('paramValue', value)}
              setError={(error) => setError('paramValue', error)}
              register={register}
              formState={formState}
              value={watchedParamValue}
            />
          )}
          {(parameterType === ParameterType.category ||
            parameterType === ParameterType.task) && (
            <div className="flex flex-col flex-grow">
              <LdInput
                tone="dark"
                type="text"
                placeholder={
                  parameterType === ParameterType.category
                    ? 'Category name'
                    : 'Task name'
                }
                className="flex-grow my-2 mr-2"
                {...register('paramValue', { required: 'Value is required' })}
                invalid={
                  errors.paramValue && errors.paramValue.type !== 'required'
                }
                onInput={(ev) => {
                  setValue(
                    'paramValue',
                    (ev.target as HTMLLdInputElement).value,
                    {
                      shouldValidate: isFormDirty || dirtyFields.paramValue,
                    }
                  );
                }}
                value={watchedParamValue}
              />
              <LdInputMessage
                className={
                  errors.paramValue && errors.paramValue.type !== 'required'
                    ? 'visible'
                    : 'invisible'
                }
                mode={errors.paramValue ? 'error' : 'valid'}
              >
                {errors.paramValue?.type || 'Value is required.'}
              </LdInputMessage>
            </div>
          )}
          {parameterType === ParameterType.smiles && (
            <div className="grid grid-cols-2 gap-1 flex-grow mb-2 mr-2">
              <LdLabel>
                <span className="flex justify-between">
                  {wrapLabel('Par_3', 'Molecule Name', tetherOptions)}
                </span>
                <LdInput
                  tone="dark"
                  {...smilesNameRegistration}
                  ref={(e) => {
                    smilesNameRegistration.ref(e);
                    smilesNameRef.current = e; // you can still assign to ref
                  }}
                  onInput={(ev) => {
                    setValue(
                      'paramValue',
                      (ev.target as HTMLLdInputElement).value,
                      {
                        shouldValidate: isFormDirty || dirtyFields.paramValue,
                      }
                    );
                  }}
                >
                  {status === 'loading' && (
                    <span slot="end">
                      <LdLoading />
                    </span>
                  )}
                </LdInput>
              </LdLabel>
              <LdLabel>
                <span className="flex justify-between">
                  {wrapLabel('Par_3', 'SMILES String', tetherOptions)}
                </span>
                <LdInput
                  tone="dark"
                  {...register('paramSmilesValue')}
                  onInput={(ev) => {
                    setValue(
                      'paramSmilesValue',
                      (ev.target as HTMLLdInputElement).value,
                      {
                        shouldValidate:
                          isFormDirty || dirtyFields.paramSmilesValue,
                      }
                    );
                  }}
                  value={watchedSmilesValue}
                >
                  <LdButton
                    onClick={() => setShowEditor(true)}
                    slot="end"
                    type="button"
                    aria-label="Draw Molecule"
                  >
                    <LdIcon name="pen" />
                  </LdButton>
                </LdInput>
                {errors.paramSmilesValue && (
                  <LdInputMessage>
                    {errors.paramSmilesValue.message}
                  </LdInputMessage>
                )}
              </LdLabel>
              {showSearch && (
                <Hits
                  onSelectResult={handleSelectSearchResult}
                  query={query || ''}
                  hits={hits}
                />
              )}
            </div>
          )}
          {parameterType !== ParameterType.custom &&
            parameterType !== ParameterType.continuous && (
              <LdButton
                name="btn-add-param"
                onClick={handleSubmit}
                mode="secondary"
                type="button"
                className={`mb-ld-16 ml-2 h-ld-32 ${
                  parameterType === ParameterType.smiles ? 'mt-6' : 'mt-2'
                } `}
                disabled={!isFormValid}
              >
                {isSmilesValidationLoading ? (
                  <LoadingOverlay />
                ) : (
                  <LdIcon name="plus" />
                )}
              </LdButton>
            )}
          {parameterType === ParameterType.continuous && (
            <div className="grid grid-cols-2 gap-5 flex-grow mb-2">
              <LdLabel>
                <span className="flex justify-between">
                  {wrapLabel('Par_7', 'Lower Bound', tetherOptions, {
                    position: 'top left',
                  })}
                </span>
                <LdInput
                  tone="dark"
                  type={'text'}
                  placeholder="e.g. 0"
                  {...register('paramLowerBound', {
                    required: 'Lower Bound is required',
                    validate: validateBounds('paramUpperBound'),
                  })}
                  invalid={
                    errors.paramLowerBound &&
                    errors.paramLowerBound.type !== 'required'
                  }
                  onInput={(ev) => {
                    const newValue = (ev.target as HTMLLdInputElement).value;
                    setValue('paramLowerBound', newValue, {
                      shouldValidate:
                        isFormDirty || dirtyFields.paramLowerBound,
                    });
                  }}
                  value={watchedLowerBound}
                ></LdInput>
                <LdInputMessage
                  className={
                    errors.paramLowerBound &&
                    errors.paramLowerBound.type !== 'required'
                      ? 'visible'
                      : 'invisible'
                  }
                  mode={errors.paramLowerBound ? 'error' : 'valid'}
                >
                  {errors.paramLowerBound?.message || 'Value must be a number.'}
                </LdInputMessage>
              </LdLabel>
              <LdLabel>
                <span className="flex justify-between">
                  {wrapLabel('Par_8', 'Upper Bound', tetherOptions)}
                </span>
                <LdInput
                  tone="dark"
                  placeholder="e.g. 100"
                  {...register('paramUpperBound', {
                    required: 'Upper Bound is required',
                    validate: validateBounds('paramLowerBound'),
                  })}
                  invalid={
                    errors.paramUpperBound &&
                    errors.paramUpperBound.type !== 'required'
                  }
                  onInput={(ev) => {
                    const newValue = (ev.target as HTMLLdInputElement).value;
                    setValue('paramUpperBound', newValue, {
                      shouldValidate:
                        isFormDirty || dirtyFields.paramUpperBound,
                    });
                  }}
                  value={watchedUpperBound}
                ></LdInput>
                <LdInputMessage
                  className={
                    errors.paramUpperBound &&
                    errors.paramUpperBound.type !== 'required'
                      ? 'visible'
                      : 'invisible'
                  }
                  mode={errors.paramUpperBound ? 'error' : 'valid'}
                >
                  {errors.paramUpperBound?.message || 'Value must be a number.'}
                </LdInputMessage>
              </LdLabel>
            </div>
          )}
        </div>
      </form>

      <MoleculeEditorModal
        isVisible={showEditor}
        onClose={() => setShowEditor(false)}
        smiles={watchedSmilesValue}
        onCancel={() => setShowEditor(false)}
        onSave={(smiles) => {
          setShowEditor(false);
          setValue('paramSmilesValue', smiles);
        }}
      />
    </>
  );
};
