import React, { useCallback, useEffect } from 'react';
import AppLayout from '../../layouts/AppLayout';
import { useDispatch, useSelector } from '../../store';
import {
  listInvitationsToMe,
  replyToInvitation,
} from '../../services/projectInvitations';
import { LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import { ProjectInvite } from '../../types/api/types';
import InvitationCard from './InvitationCard';
import EmptyList from './EmptyList';

const Invitations: React.FC = () => {
  const dispatch = useDispatch();
  const invitationsToMe = useSelector(
    (store) => store.projectInvitations.invitationsToMe
  );

  useEffect(() => {
    dispatch(listInvitationsToMe());
  }, [dispatch]);

  const handleButtonClick = useCallback(
    (invite: ProjectInvite, action: 'accept' | 'reject') => {
      dispatch(
        replyToInvitation({
          projectId: invite.projectId,
          inviteId: invite.inviteId,
          action,
        })
      );
    },
    [dispatch]
  );

  /**
   * Check Causes to render Page empty on rejected api call
   */
  // if (!invitationsToMe?.length) {
  //   return null;
  // }

  return (
    <AppLayout>
      <div className="relative flex flex-row max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start justify-center w-full max-h-screen min-h-screen pt-8 database__mainContainer">
          <div className="pl-4 mb-5">
            <LdTypo variant="body-s" className="hydrated">
              Invitations
            </LdTypo>
          </div>

          <div className="flex gap-4 flex-col items-center justify-start flex-grow w-full px-4 pb-6 overflow-y-auto database__content">
            {!invitationsToMe?.length && <EmptyList />}
            {invitationsToMe.map((invitation) => {
              return (
                <InvitationCard
                  key={invitation.inviteId}
                  invitation={invitation}
                  onActionClick={handleButtonClick}
                />
              );
            })}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Invitations;
