import React from 'react';
import { NavLink } from 'react-router-dom';
import { LdIcon, LdTypo } from '@emdgroup-liquid/liquid/dist/react';

import { Head } from './Head';
import { Item } from './Item';
import { User } from './User';
import logo from 'assets/static/images/logo.png';

// import { ReactComponent as IconDatabase } from '../../../assets/static/images/icon-database.svg';
// import { ReactComponent as IconUpload } from '../../../assets/static/images/icon-upload.svg';
// import { ReactComponent as IconMail } from '../../../assets/static/images/icon-mail.svg';
import './style.scss';
import { InfoIcon } from 'components/Icons';
import SidebarMore from './GroupItem/SidebarMore';

const isMd = window.innerWidth > 500;

const Sidebar = ({ className }: { className?: string }) => {
  return (
    <div className={className} id="sidebar">
      <div className="flex flex-col h-full bg-rich-purple-light overflow-visible sidebar-content">
        <div className="">
          <Head isCollapsed={!isMd} />
        </div>
        <div className="flex-grow">
          <NavLink to="/new-project">
            {({ isActive }) => (
              <Item
                data-test="sidebar-item-upload"
                label="Create Project"
                icon={<LdIcon name="upload" />}
                isCollapsed={!isMd}
                isSelected={isActive}
              />
            )}
          </NavLink>
          <NavLink to="/projects">
            {({ isActive }) => (
              <Item
                data-test="sidebar-item-database"
                label="My Projects"
                icon={<LdIcon name="dashboard" />}
                isCollapsed={!isMd}
                isSelected={isActive}
              />
            )}
          </NavLink>
          {process.env.USER_GUIDE === 'true' && (
            <NavLink to="/user-guide">
              {({ isActive }) => (
                <Item
                  data-test="sidebar-item-user-guide"
                  label="User Guide"
                  icon={
                    <LdIcon>
                      <InfoIcon />
                    </LdIcon>
                  }
                  isCollapsed={!isMd}
                  isSelected={isActive}
                />
              )}
            </NavLink>
          )}
          <SidebarMore />
        </div>
        <div className="">
          <div>
            <NavLink to="/feedback">
              {({ isActive }) => (
                <Item
                  data-test="sidebar-item-feedback"
                  label="Feedback & Issues"
                  icon={<LdIcon name="conversation" />}
                  isCollapsed={!isMd}
                  isSelected={isActive}
                />
              )}
            </NavLink>
          </div>
        </div>
        <div>
          <User isCollapsed={!isMd} />
        </div>
        <div className="px-6 py-4">
          <LdTypo className="text-white" variant="body-xs">
            powered by
          </LdTypo>
          <a
            href="https://uptimize.merckgroup.com/"
            target="_blank"
            className="flex flex-row items-center gap-ld-4"
            rel="noreferrer"
          >
            <img src={logo} className="h-ld-16" />
            <span className="text-white font-uptimize font-black text-xs">
              UPTIMIZE
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
