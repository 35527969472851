import React from 'react';
import { LdSwitch, LdSwitchItem } from '@emdgroup-liquid/liquid/dist/react';

export const SUPPORTED_FILE_TYPES = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

type Props = {
  selectedType: string;
  updateSelectedFileType: (type: string) => void;
};

export const FileTypes = ({ selectedType, updateSelectedFileType }: Props) => {
  return (
    <LdSwitch legend="File type" name="file-type-switch" required>
      {Object.entries(SUPPORTED_FILE_TYPES).map((t) => (
        <LdSwitchItem
          value={t[1]}
          checked={selectedType === t[1]}
          key={`file-type-${t[1]}`}
          onClick={() => updateSelectedFileType(t[1])}
        >
          {t[0]}
        </LdSwitchItem>
      ))}
    </LdSwitch>
  );
};
