import theme, {
  richBlackLightest,
  richBlue,
  richPurple,
} from 'assets/styles/charts';
import _ from 'lodash';
import { ParameterType, Parameter, TargetVariable } from 'types/api/types';
import { TargetObjective } from 'types/app/types';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from 'victory';

interface Props {
  selectedTarget?: TargetVariable;
  selectedParam?: Parameter;
  selectedParamName: string;
  sortedLabels: string[];
  selectedTargetName: string;
  lineData: {
    batch: number;
    result: number | undefined;
  }[];
  arithmeticMeanData: number | undefined;
  scatterData: ({ x: any; y: any } | null)[];
}

const ParamChart = ({
  selectedParam,
  scatterData,
  sortedLabels,
  selectedParamName,
  selectedTargetName,
  lineData,
  selectedTarget,
  arithmeticMeanData,
}: Props) => {
  const min = _.minBy(lineData, 'batch')?.batch || 0;
  const max = (_.maxBy(lineData, 'result')?.result || 0).toString();

  let tickValues;
  let tickFormat;
  let data;

  switch (selectedParam?.parameterType) {
    case ParameterType.continuous:
    case ParameterType.numeric:
      tickValues = undefined;
      tickFormat = (t: number) => Math.round(t).toString();
      data = scatterData.length;
      break;
    case ParameterType.task:
    case ParameterType.custom:
    case ParameterType.smiles:
    case ParameterType.category:
      tickValues = sortedLabels;
      tickFormat = undefined;
      data = sortedLabels?.length ?? 0;
      break;
    default:
      if (selectedParamName === 'batch') {
        tickValues = undefined;
        tickFormat = (t: number) => Math.round(t).toString();
        data = Math.max(lineData.length, min + 4);
      } else {
        return null;
      }
  }
  const yDomain: [number, number] | undefined =
    selectedTargetName === 'target'
      ? [
          Math.min(selectedTarget?.lowerBound || 0, min),
          Math.max(Number(max), selectedTarget?.upperBound || 0),
        ]
      : undefined;

  return (
    <VictoryChart domainPadding={20} theme={theme}>
      <VictoryAxis
        // x- axis
        label={selectedParamName}
        domain={[min, data]}
        tickValues={tickValues}
        tickFormat={tickFormat}
        style={{
          axisLabel: {
            transform: `translate(0, ${4 + max.length})`,
          },
        }}
      />

      <VictoryAxis
        // y- axis
        domain={yDomain}
        dependentAxis
        label={selectedTargetName}
        //tickFormat={(t: number) => t.toFixed(3)}
        style={{
          axisLabel: {
            transform: `translate(-${14 + max.length},0)`,
          },
        }}
      />
      <VictoryScatter data={scatterData} x="x" y="y" />

      {selectedTarget?.targetObjective === TargetObjective.Match &&
        selectedTargetName !== 'desirability' && (
          <VictoryAxis
            dependentAxis
            tickValues={[arithmeticMeanData]}
            style={{
              grid: { stroke: richBlackLightest },
              tickLabels: { display: 'none' },
            }}
          />
        )}
      {selectedParamName === 'batch' && (
        <VictoryScatter
          data={lineData}
          style={{ data: { fill: richPurple } }}
          x="batch"
          y="result"
        />
      )}
      {selectedParamName === 'batch' && lineData.length > 1 && (
        <VictoryLine data={lineData} x="batch" y="result" />
      )}
    </VictoryChart>
  );
};

export default ParamChart;
