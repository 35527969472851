import { LdBadge, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import { MailIcon } from 'components/Icons';
import React from 'react';

import './styles.scss';

const EmptyList = () => {
  return (
    <div className="flex h-full items-center">
      <div className="flex flex-col items-center">
        <div className="relative">
          <LdBadge
            icon="checkmark"
            size="lg"
            className="ld-badge--success absolute -right-1 top-2.5"
          />
          <MailIcon className="h-24 w-24" />
        </div>
        <LdTypo variant="h4">No open Invitations</LdTypo>
      </div>
    </div>
  );
};

export default EmptyList;
