/* eslint-disable import/named */
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { Constraint, Parameter, Project, ProjectStatus } from 'types/api/types';
import { StateStatus } from 'types/app/types';

import { changeHistory } from '../experiments';
import {
  PendingAction,
  ProjectsServiceType,
  ProjectsState,
  RejectedAction,
} from './projects.types';
import {
  closeProject,
  createProject,
  expandChemicalSpace,
  getProjectById,
  getProjectStatusById,
  listConstraintsForProject,
  listProjects,
  reopenProject,
} from './projects.effects';
import { recreateRecommendations } from 'services/recommendations';

export const initialState: ProjectsState = {
  projects: {},
  constraints: {},
  selectedProject: undefined,
  sortBy: 'createdAt',
  showClosed: false,
  order: 'desc',
  status: StateStatus.idle,
  error: null,
};

function isPendingAction(action: AnyAction): action is PendingAction {
  return (
    action.type.endsWith('/pending') && action.type.startsWith('projects/')
  );
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    action.type.endsWith('/rejected') && action.type.startsWith('projects/')
  );
}

export const projectsSlice: ProjectsServiceType = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<{ [key: string]: Project }>) => {
      state.projects = action.payload;
    },
    selectProject: (state, action: PayloadAction<string>) => {
      state.selectedProject = action.payload;
    },
    unselectProject: (state) => {
      state.selectedProject = undefined;
    },
    updateProject: (
      state: WritableDraft<ProjectsState>,
      action: { payload: { id: string; updates: any } }
    ) => {
      Object.assign(state.projects[action.payload.id], action.payload.updates);
    },
    updateParameter: (
      state,
      action: PayloadAction<{ projectId: string; parameter: Parameter }>
    ) => {
      const { projectId, parameter } = action.payload;
      for (
        let i = 0;
        i < (state.projects[projectId]?.parameterSpace.length || 0);
        i++
      ) {
        if (
          state.projects[projectId].parameterSpace[i].parameterName ===
          parameter.parameterName
        ) {
          state.projects[projectId].parameterSpace[i].parameterValues = [
            ...parameter.parameterValues,
          ];
          return;
        }
      }
      state.projects[projectId].parameterSpace.push(parameter);
    },
    changeOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setShowClosed: (state, action: PayloadAction<boolean>) => {
      state.showClosed = action.payload;
    },
    setSortyBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.projects[action.payload.projectId] = action.payload;
    },
    setProjectStatus: (
      state,
      action: PayloadAction<{ projectId: string; status: ProjectStatus }>
    ) => {
      const { projectId, status } = action.payload;
      state.projects[projectId].status = status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listProjects.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      Object.assign(
        state.projects,
        ...(payload as Project[]).map((project) => ({
          [project.projectId]: project,
        }))
      );
    });
    builder.addCase(getProjectStatusById.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      const { status, projectId } = payload as Project;
      state.projects[projectId].status = status;
    });
    builder.addCase(getProjectById.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      const project = payload as Project;
      state.projects[project.projectId] = project;
    });
    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      const project = payload as Project;
      state.projects[project.projectId] = project;
    });
    builder.addCase(closeProject.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      state.projects[payload].status = ProjectStatus.closed;
    });
    builder.addCase(reopenProject.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      state.projects[payload].status = ProjectStatus.idle;
    });
    builder.addCase(expandChemicalSpace.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      const { projectId, ...updates } = payload as Project;
      Object.assign(state.projects[projectId], updates);
    });
    builder.addCase(recreateRecommendations.pending, (state, { meta }) => {
      state.status = StateStatus.loading;
      const { projectId } = meta.arg;
      state.projects[projectId].status = ProjectStatus.running;
    });
    // builder.addCase(expandChemicalSpace.pending, (state, { meta }) => {
    //   state.status = StateStatus.loading;
    //   const { projectId } = meta.arg;
    //   state.projects[projectId].status = ProjectStatus.running;
    // });
    // builder.addCase(createExperiments.pending, (state, { meta }) => {
    //   state.status = StateStatus.loading;
    //   const { projectId } = meta.arg;
    //   state.projects[projectId].status = ProjectStatus.running;
    // });
    // builder.addCase(changeHistory.fulfilled, (state, { meta }) => {
    //   const { projectId } = meta.arg;
    //   state.projects[projectId].status = ProjectStatus.running;
    // });
    builder.addCase(
      listConstraintsForProject.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: Constraint[] } }
      ) => {
        state.status = StateStatus.succeeded;
        state.constraints[payload.projectId] = payload.data;
      }
    );
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});
