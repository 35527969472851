import { createInstance } from '@datapunt/matomo-tracker-react';

const { REACT_APP_MATOMO_DOMAIN, REACT_APP_MATOMO_SITE_ID = '0' } = process.env;

const instance = createInstance({
  urlBase: `https://${REACT_APP_MATOMO_DOMAIN}/`,
  siteId: parseInt(REACT_APP_MATOMO_SITE_ID),
  linkTracking: false,
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
});

export { instance };
