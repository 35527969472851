import { ProjectsState } from 'services/projects/projects.types';
import {
  RecommendationsState,
  SelectableRecommendation,
} from './recommendations.types';

export const selectRecommendationsForProject =
  (projectId: string) =>
  ({
    recommendations: { recommendations },
  }: {
    recommendations: RecommendationsState;
  }): SelectableRecommendation[] =>
    recommendations[projectId]?.filter(({ isSelected }) => !isSelected) || [];
