import React from 'react';
import { Link } from 'react-router-dom';
import useWhatsNew from 'hooks/useWhatsNew';

interface Props {
  isCollapsed: boolean;
  className?: string;
}

const Head: React.FC<Props> = ({ isCollapsed, className }) => {
  const { latestVersion, latestEntry } = useWhatsNew();
  return (
    <div
      className={`flex flex-col items-start justify-start p-6 ${className}`}
      title={`BayChem v${latestVersion} (${process.env.REACT_APP_BUILD_NO})`}
    >
      <Link
        to={{
          pathname: '/',
        }}
      >
        <span
          className={`-mb-px text-white uppercase transition-opacity duration-300 ease-out text-s ${
            isCollapsed ? 'opacity-0' : 'opacity-100'
          }`}
        >
          <span className="font-extrabold">Bay</span>
          Chem {latestEntry.major}.{latestEntry.minor}
        </span>
      </Link>
    </div>
  );
};

export default Head;
