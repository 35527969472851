import { LdModal, LdBadge, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import React, { PropsWithChildren } from 'react';

type UploadInputValuesModalProps = PropsWithChildren<{
  isVisible: boolean;
  onClose: () => void;
}>;

export const UploadInputValuesModal: React.FC<UploadInputValuesModalProps> = ({
  isVisible,
  onClose,
  children,
}) => {
  return (
    <LdModal
      blurryBackdrop
      open={isVisible}
      onLdmodalclosed={onClose}
      style={{ '--ld-modal-min-inline-size': '40rem' }}
    >
      <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
        <LdBadge icon="attention" size="lg" class="ld-badge--info" />
        <LdTypo variant="h4">Import Parameters</LdTypo>
      </div>
      <div className="relative flex flex-col gap-ld-4">
        <div className="py-4">{children}</div>
      </div>
    </LdModal>
  );
};
