import React from 'react';

import { LdNotice } from '@emdgroup-liquid/liquid/dist/react';

const Disclaimer = () => {
  return (
    <LdNotice mode="warning" headline="Disclaimer" className="w-full mb-ld-16">
      {/* You are currently using a beta version of BayChem which is not classified
      for handling confidential data. Please do not enter any confidential data
      (i.e. Molecules)! <br /> */}
      <strong>
        BayChem was optimized for Google Chrome, please consider using it.
      </strong>
    </LdNotice>
  );
};

export default Disclaimer;
