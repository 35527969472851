import React from 'react';
import { Link } from 'react-router-dom';
import { LdBadge, LdButton, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import moment from 'moment';
import { ProjectInvite } from '../../types/api/types';

interface InvitationCardProps {
  invitation: ProjectInvite;
  onActionClick: (invite: ProjectInvite, action: 'accept' | 'reject') => void;
}

const InvitationCard: React.FC<InvitationCardProps> = ({
  invitation,
  onActionClick,
}) => {
  return (
    <div
      className="flex flex-wrap flex-col items-center justify-center max-w-full card p-8 bg-white rounded-md overflow-visible w-full"
      key={invitation.inviteId}
    >
      <div className="flex flex-col justify-between w-full gap-4">
        <Link
          to={`/projects/${invitation.projectId}`}
          className="hover:underline"
        >
          <LdTypo variant="h3" className="hydrated">
            {invitation.projectName ?? 'Project'}
          </LdTypo>
        </Link>

        <LdTypo variant="body-xs" className="mt-[-1rem]">
          {moment(invitation.createdAt).format('DD.MM.YYYY')}
        </LdTypo>
        <div className="grid grid-cols-2">
          <div className="gap-2 flex flex-col">
            <LdTypo variant="label-s">
              Role: <span className="font-normal">{invitation.role}</span>
            </LdTypo>
            <LdTypo variant="label-s">
              Sender:{' '}
              <span className="font-normal">
                {invitation.sender?.name ?? invitation.sender?.email}
              </span>
            </LdTypo>
          </div>
          <div className="flex flex-row gap-1 justify-end">
            {invitation.status === 'pending' ? (
              <>
                <LdButton
                  size="sm"
                  onClick={() => {
                    onActionClick(invitation, 'accept');
                  }}
                  type="button"
                >
                  Accept
                </LdButton>
                <LdButton
                  size="sm"
                  mode="danger"
                  onClick={() => {
                    onActionClick(invitation, 'reject');
                  }}
                  type="button"
                >
                  Reject
                </LdButton>
              </>
            ) : (
              <div>
                <LdBadge className={`ld-badge--${invitation.status} mr-2`}>
                  {invitation.status}
                </LdBadge>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitationCard;
