import {
  LdInput,
  LdInputMessage,
  LdLabel,
  LdSwitch,
  LdSwitchItem,
} from '@emdgroup-liquid/liquid/dist/react';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ConstraintDiscreteConditionOperatorType,
  DiscreteConditions,
  ThresholdCondition,
} from 'types/api/types';

type Props = {
  entries: Record<string, string | undefined>;
  conditions: Record<string, DiscreteConditions | undefined>;
  onSubmit: (c: ThresholdCondition) => void;
};

type FormData = {
  paramValue?: string;
};

const SingleTresholdConditionForm = ({ conditions, onSubmit }: Props) => {
  const { formState, clearErrors, setError, trigger } = useForm<FormData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const { errors } = formState;
  const [threshold, setThreshold] = useState<number | undefined>();
  const [operator, setOperator] = useState<
    ConstraintDiscreteConditionOperatorType | undefined
  >();
  const [tolerance, setTolerance] = useState<number | undefined>();

  useEffect(() => {
    if (conditions) {
      const thCondition = Object.values(conditions)[0] as ThresholdCondition;
      if (thCondition) {
        setThreshold(thCondition.threshold);
        setOperator(thCondition.operator);
        setTolerance(thCondition.tolerance);
      }
    }
  }, [conditions]);

  const handleChange = (): Promise<ThresholdCondition> | undefined => {
    if (!operator) {
      setError('paramValue', {
        type: 'Operator is required!',
      });
      return;
    }
    if (!threshold) {
      setError('paramValue', {
        type: 'Threshold is required!',
      });
      return;
    }

    clearErrors('paramValue');
    if (
      operator != ConstraintDiscreteConditionOperatorType['!='] &&
      operator != ConstraintDiscreteConditionOperatorType['=']
    ) {
      setTolerance(undefined);
    }
    const cond: ThresholdCondition = {
      tolerance,
      threshold,
      operator,
      type: 'ThresholdCondition',
    };
    const isValid = trigger();
    if (!isValid) return;

    onSubmit(cond);
  };

  useEffect(() => {
    handleChange();
  }, [operator, threshold, tolerance]);

  return (
    <form
      className="mb-3"
      autoComplete="off"
      onKeyDown={(e) => {
        e.key === 'Enter' && e.preventDefault();
      }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <LdLabel>Threshold Condition</LdLabel>
      <div className="flex w-full flex-row flex-grow justify-between items-end border border-collapse rounded-m py-3">
        <div className="flex-2 mx-2">
          <LdLabel>
            <span className="flex justify-between">Operator</span>
            <LdSwitch
              required
              onLdswitchchange={(e) => {
                setOperator(
                  e.detail as ConstraintDiscreteConditionOperatorType
                );
              }}
              placeholder="Select Operator"
            >
              {Object.keys(ConstraintDiscreteConditionOperatorType).map(
                (val) => (
                  <LdSwitchItem key={val} value={val} checked={operator == val}>
                    {val}
                  </LdSwitchItem>
                )
              )}
            </LdSwitch>
          </LdLabel>
        </div>

        <div className="flex mx-1">
          <LdLabel>
            Threshold
            <LdInput
              required
              placeholder="e.g. 3"
              type="number"
              tone="dark"
              onInput={(ev) => {
                const v = (ev.target as HTMLLdInputElement).value;
                v ? setThreshold(Number(v)) : setThreshold(undefined);
              }}
              value={threshold?.toString()}
            />
          </LdLabel>
        </div>

        <div className="flex mx-1">
          <LdLabel>
            Tolerance
            <LdInput
              placeholder="e.g. 0.1 (Optional)"
              type="number"
              tone="dark"
              onInput={(ev) => {
                const v = (ev.target as HTMLLdInputElement).value;
                v ? setTolerance(Number(v)) : setTolerance(undefined);
              }}
              value={tolerance?.toString()}
              disabled={
                ![
                  ConstraintDiscreteConditionOperatorType['!='],
                  ConstraintDiscreteConditionOperatorType['='],
                ].includes(operator as ConstraintDiscreteConditionOperatorType)
              }
            />
          </LdLabel>
        </div>
      </div>

      <LdInputMessage
        className={
          errors.paramValue && errors.paramValue.type !== 'required'
            ? 'visible'
            : 'invisible'
        }
        mode={errors.paramValue ? 'error' : 'valid'}
      >
        {errors.paramValue?.type || 'Value is required.'}
      </LdInputMessage>
    </form>
  );
};

export default SingleTresholdConditionForm;
