/* eslint-disable import/named */
import {
  createAsyncThunk,
  createSlice,
  AnyAction,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import type {
  ProjectInvite as ProjectInviteAPI,
  ProjectRole,
} from 'types/api/types';
import { StateStatus } from 'types/app/types';
import { get, post, del, put } from 'util/api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

type ProjectInvite = ProjectInviteAPI & {
  role: ProjectRole;
};

export interface ProjectInvitationsState {
  invitations: Record<string, ProjectInvite[]>;
  invitationsToMe: ProjectInvite[];
  sortBy: string;
  order: 'asc' | 'desc';
  status: StateStatus;
  error: string | null;
}

export const initialState: ProjectInvitationsState = {
  invitations: {},
  invitationsToMe: [],
  sortBy: 'experimentId',
  order: 'asc',
  status: StateStatus.idle,
  error: null,
};

export const listInvitationsForProject = createAsyncThunk(
  'invitations/listInvitationsForProject',
  async (projectId: string) => {
    const data = await get(`/project/${projectId}/invite`);
    return {
      projectId,
      data,
    };
  }
);

export const getInviteDetails = createAsyncThunk(
  'invitations/getInviteDetails',
  async ({ projectId, inviteId }: { projectId: string; inviteId: string }) => {
    const data = await get(`/project/${projectId}/invite/${inviteId}`);
    return {
      projectId,
      data,
    };
  }
);

export const listInvitationsToMe = createAsyncThunk(
  'invitations/listInvitationsToMe',
  async () => {
    const data = await get(`/invite`);
    return {
      data,
    };
  }
);

export const createInvitation = createAsyncThunk(
  'invitations/createInvitation',
  async ({
    projectId,
    body,
  }: {
    projectId: string;
    body: {
      role: ProjectRole;
      email: string;
    };
  }) => {
    const data = await post(`/project/${projectId}/invite`, body);
    return {
      projectId,
      data,
    };
  }
);

export const revokeInvitation = createAsyncThunk(
  'invitations/revokeInvitation',
  async ({ projectId, inviteId }: { projectId: string; inviteId: string }) => {
    await del(`/project/${projectId}/invite/${inviteId}`);
    return {
      projectId,
      inviteId,
    };
  }
);

export const replyToInvitation = createAsyncThunk(
  'invitations/replyToInvitation',
  async ({
    projectId,
    inviteId,
    action,
  }: {
    projectId: string;
    inviteId: string;
    action: 'accept' | 'reject';
  }) => {
    const data = await put(`/project/${projectId}/invite/${inviteId}`, {
      action,
    });
    return {
      projectId,
      data,
    };
  }
);

function isPendingAction(action: AnyAction): action is PendingAction {
  return (
    action.type.endsWith('/pending') && action.type.startsWith('experiments/')
  );
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    action.type.endsWith('/rejected') && action.type.startsWith('experiments/')
  );
}

export const projectInvitationsSlice = createSlice({
  name: 'projectInvitations',
  initialState,
  reducers: {
    changeOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setSortyBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      listInvitationsForProject.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: ProjectInvite[] } }
      ) => {
        state.status = StateStatus.succeeded;
        state.invitations[payload.projectId] = payload.data;
      }
    );
    builder.addCase(
      listInvitationsToMe.fulfilled,
      (state, { payload }: { payload: { data: ProjectInvite[] } }) => {
        state.status = StateStatus.succeeded;
        state.invitationsToMe = payload.data;
      }
    );
    builder.addCase(
      getInviteDetails.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: ProjectInvite } }
      ) => {
        state.status = StateStatus.succeeded;
        state.invitationsToMe = [payload.data];
      }
    );
    builder.addCase(
      createInvitation.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: ProjectInvite } }
      ) => {
        state.status = StateStatus.succeeded;
        // Check if invite for sam user and project altready exists
        let replaceExisting = false;
        state.invitations[payload.projectId] = state.invitations[
          payload.projectId
        ].map((invite) => {
          if (invite.inviteId === payload.data.inviteId) {
            replaceExisting = true;
            return payload.data;
          }
          return invite;
        });
        if (!replaceExisting) {
          state.invitations[payload.projectId].push(payload.data);
        }
      }
    );
    builder.addCase(
      revokeInvitation.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; inviteId: string } }
      ) => {
        state.status = StateStatus.succeeded;
        state.invitations[payload.projectId] = state.invitations[
          payload.projectId
        ].filter(({ inviteId }) => inviteId !== payload.inviteId);
      }
    );
    builder.addCase(
      replyToInvitation.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: ProjectInvite } }
      ) => {
        state.status = StateStatus.succeeded;
        state.invitationsToMe = state.invitationsToMe.map((invitation) => {
          const shouldUpdate =
            invitation.inviteId === payload.data.inviteId &&
            invitation.projectId === payload.projectId;
          return shouldUpdate ? payload.data : invitation;
        });
      }
    );
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export const selectInvitationsForProject =
  (projectId: string) =>
  ({ projectInvitations }: { projectInvitations: ProjectInvitationsState }) => {
    try {
      return projectInvitations.invitations[projectId];
    } catch {
      return [];
    }
  };

export const selectInvitation =
  (projectId?: string, inviteId?: string) =>
  ({ projectInvitations }: { projectInvitations: ProjectInvitationsState }) => {
    try {
      if (!projectId || !inviteId) {
        return null;
      }
      return projectInvitations.invitations[projectId].find(
        (projectInvite) => projectInvite.inviteId === inviteId
      );
    } catch {
      return null;
    }
  };

export const selectInvitationToMe =
  (projectId?: string, inviteId?: string) =>
  ({ projectInvitations }: { projectInvitations: ProjectInvitationsState }) => {
    try {
      if (!projectId || !inviteId) {
        return null;
      }
      return projectInvitations.invitationsToMe.find(
        (projectInvite) =>
          projectInvite.inviteId === inviteId &&
          projectInvite.projectId === projectId
      );
    } catch {
      return null;
    }
  };

export default projectInvitationsSlice.reducer;
