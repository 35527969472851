import 'react';
import { useLocalStorage } from '@emdgroup/react-storage';
import Notice from 'layouts/Notification';
const useNotice = () => {
  const [acknowledged, setAcknowledged] =
    useLocalStorage<string>('version-notice-2.0');

  const showNotice = () => {
    return !acknowledged ? (
      <Notice onAcknowledge={() => setAcknowledged('true')} />
    ) : null;
  };

  return {
    showNotice,
  };
};

export { useNotice as default };
