import React from 'react';
import {
  LdInputMessage,
  LdTableCell,
  LdTableFoot,
  LdTableRow,
} from '@emdgroup-liquid/liquid/dist/react';
import { tableColumns } from 'components/ChemicalSpace/TargetList/tableColumns';
import { FieldError } from 'react-hook-form';

interface TargetTableFooterProps {
  errorsEntries: [string, FieldError | undefined][];
  rowsWithErrorsEntries: [string, string][];
}

export const TargetTableFooter: React.FC<TargetTableFooterProps> = ({
  errorsEntries,
  rowsWithErrorsEntries,
}) => {
  return (
    <LdTableFoot>
      <LdTableRow>
        <LdTableCell colspan={tableColumns.length}>
          <div className={'flex flex-col'}>
            {errorsEntries.map(([key, value]) => (
              <LdInputMessage key={key}>{value?.message}</LdInputMessage>
            ))}
            {rowsWithErrorsEntries.map(([key, value]) => (
              <LdInputMessage key={key}>{value}</LdInputMessage>
            ))}
          </div>
        </LdTableCell>
      </LdTableRow>
    </LdTableFoot>
  );
};
