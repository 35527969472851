import { LdTypo, LdTooltip } from '@emdgroup-liquid/liquid/dist/react';
import TooltipData from 'assets/data/tooltips.json';

export const useHints = () => {
  const getToolTipTextById = (id: string) =>
    TooltipData.find(
      ({ HintID, Picture_ID }) => HintID === id || Picture_ID === id
    )?.Text;

  const getToolTipById = (id: string, tetherOptions?: any, configs?: any) => (
    <LdTooltip arrow className="h-1" tetherOptions={tetherOptions} {...configs}>
      <LdTypo className="p-ld-8" variant="body-xs">
        {getToolTipTextById(id)}
      </LdTypo>
    </LdTooltip>
  );

  const wrapLabel = (
    id: string,
    label: string,
    tetherOptions?: any,
    configs?: any
  ) => (
    <span className="flex gap-2">
      {label} {getToolTipById(id, tetherOptions, configs)}
    </span>
  );
  return {
    tooltips: TooltipData,
    getToolTipById,
    wrapLabel,
    getToolTipTextById,
  };
};
