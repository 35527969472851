import React from 'react';
import { LdLabel, LdIcon } from '@emdgroup-liquid/liquid/dist/react';

interface Props {
  readOnly?: boolean;
  onDelete?: () => void;
}

const Tag: React.FC<Props> = ({ onDelete, children, readOnly }) => {
  return (
    <LdLabel
      position="left"
      className="bg-rich-purple-lighter rounded-m text-white p-ld-2"
    >
      <span className="text-white">{children}</span>
      {!readOnly && onDelete !== undefined && (
        <button
          onClick={onDelete}
          type="button"
          className="text-white ld-select__btn-clear-single"
        >
          <LdIcon name="cross" aria-label="remove" />
        </button>
      )}
    </LdLabel>
  );
};

export default Tag;
