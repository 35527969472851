/**
 * expandParameterSpace: {
 *  [projectId: string]: {
 *   selectedParameter: Parameter
 *   newParameters: {
 *    [key: string] Parameter
 *   }
 *   newParameterValues: {
 *    [parameterName: string]: ParameterValue[]
 *   }
 *  }
 * }
 */
/* eslint-disable import/named */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { Recommendation, Parameter, ParameterValue } from 'types/api/types';

export interface SelectableRecommendation extends Recommendation {
  isSelected?: boolean;
}

export interface RecommendationsState {
  [key: string]: {
    selectedParameter?: Parameter;
    newParameters: {
      [key: string]: Parameter;
    };
    newParameterResults: {
      [experimentId: string]: { [parameterName: string]: any };
    };
    newParameterValues: {
      [parameterName: string]: ParameterValue[];
    };
  };
}

export const initialState: RecommendationsState = {};

export const expandSlice = createSlice({
  name: 'expand',
  initialState,
  reducers: {
    startExpanding(
      state: WritableDraft<RecommendationsState>,
      action: PayloadAction<string>
    ) {
      state[action.payload] = {
        newParameters: {},
        newParameterResults: {},
        newParameterValues: {},
      };
    },
    setNewParameter(
      state: WritableDraft<RecommendationsState>,
      action: PayloadAction<{
        projectId: string;
        key: string;
        parameter: Parameter;
      }>
    ) {
      const { projectId, parameter } = action.payload;
      state[projectId].newParameterValues[parameter.parameterName] =
        parameter.parameterValues;
      state[projectId].newParameters[parameter.parameterName] = parameter;
    },
    removeParameter(
      state: WritableDraft<RecommendationsState>,
      action: PayloadAction<{
        projectId: string;
        key: string;
      }>
    ) {
      const { projectId, key } = action.payload;
      delete state[projectId].newParameters[key];
    },
    setNewParameterValues(
      state: WritableDraft<RecommendationsState>,
      action: PayloadAction<{
        projectId: string;
        parameterName: string;
        values: ParameterValue[];
      }>
    ) {
      const { projectId, parameterName, values } = action.payload;
      if (values.length === 0) {
        delete state[projectId].newParameterValues[parameterName];
      } else {
        state[projectId].newParameterValues[parameterName] = values;
      }
    },
    setNewParameterResults(
      state: WritableDraft<RecommendationsState>,
      action: PayloadAction<{
        projectId: string;
        experimentId: string;
        parameters: {
          [parameterName: string]: any;
        };
      }>
    ) {
      const { projectId, experimentId, parameters } = action.payload;
      state[projectId].newParameterResults[experimentId] = {
        ...(state[projectId].newParameterResults[experimentId] || {}),
        ...parameters,
      };
    },
    clear: (
      state: WritableDraft<RecommendationsState>,
      action: PayloadAction<string>
    ) => {
      delete state[action.payload];
    },
  },
});

export const {
  clear,
  setNewParameter,
  setNewParameterValues,
  setNewParameterResults,
  removeParameter,
  startExpanding,
} = expandSlice.actions;
export default expandSlice.reducer;
