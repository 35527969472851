/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';

import {
  LdButton,
  LdInput,
  LdInputMessage,
  LdLabel,
  LdOption,
  LdSelect,
} from '@emdgroup-liquid/liquid/dist/react';

import {
  FormError,
  ParameterFormEntry,
  ParameterTypesLabel,
} from 'types/app/types';
import {
  CustomParameterValue,
  Parameter,
  ParameterType,
  ParameterValue,
  ProjectStatus,
  SmilesParameterValue,
  NumericalContinuousParameter,
  isNumericalContinuousParameter,
} from 'types/api/types';
import Tag from 'components/Tag';
import { ParameterValuesForm } from './ParameterValuesForm';
import { CustomParameterTable } from './CustomParameterTable';

import { useHints } from 'hooks/useHints';
// eslint-disable-next-line import/named
import { LdSelectCustomEvent } from '@emdgroup-liquid/liquid';

interface Props {
  onSubmit: (arg0: Parameter) => void;
  onCancel: () => void;
  parameter?: Parameter;
  values?:
    | string[]
    | number[]
    | CustomParameterValue[]
    | SmilesParameterValue[]
    | NumericalContinuousParameter[];
  readOnlyParameter?: Parameter;
  projectStatus?: string;
  isExpanded?: boolean;
}

export interface TableProps {
  values: CustomParameterValue[];
  name: string;
}

const AddParameterForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  parameter,
  values,
  readOnlyParameter,
  projectStatus,
  isExpanded,
}) => {
  const { wrapLabel } = useHints();
  const {
    formState,
    getValues,
    setError,
    register,
    setValue,
    trigger,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      parameterName: parameter?.parameterName,
      parameterValueCount: parameter?.parameterValues.length || 0,
      paramLowerBound: parameter?.parameterValues[0],
      paramUpperBound: parameter?.parameterValues[1],
    },
  });
  const { errors, dirtyFields } = formState;
  const watchedParameterName = watch('parameterName');
  const isFormDirty = formState.submitCount > 0;
  const formRef = useRef<HTMLFormElement>(null);

  const [parameterType, setParameterType] = useState<ParameterType | undefined>(
    parameter?.parameterType
  );
  const [entries, setEntries] = useState<ParameterFormEntry[]>([]);
  const [activeValues, setActiveValue] = React.useState<string[]>([]);
  const [valueError, setValueError] = React.useState<string>();

  const modalRefParams = useRef(null);
  const tetherOptions: any = {
    bodyElement: modalRefParams.current,
    constraints: [{ to: 'scrollParent' }],
  };

  const addEntryNonCustom = (
    value?:
      | string
      | SmilesParameterValue
      | CustomParameterValue[]
      | string[]
      | NumericalContinuousParameter
  ) => {
    let err = 'Value already exists';
    if (
      entries.some((entry) => {
        if (
          isSmilesParameterValue(entry.value) &&
          isSmilesParameterValue(value)
        ) {
          if (entry.value.Name === value.Name) {
            err =
              'A molecule with that name already exists. All names must be unique.';
            return true;
          } else if (
            entry.value.SMILES &&
            entry.value.SMILES === value.SMILES
          ) {
            err =
              'A molecule with that structure already exists. All structures must be unique.';
            return true;
          }
        }
        return entry.value === value;
      })
    ) {
      setValueError(err);
      return;
    }
    setValueError(undefined);
    setEntries((old) => [
      ...old,
      {
        id: nanoid(),
        value,
      },
    ]);
  };
  const addEntry = (
    value?:
      | string
      | SmilesParameterValue
      | CustomParameterValue[]
      | string[]
      | NumericalContinuousParameter
  ) => {
    if (parameterType === ParameterType.custom && Array.isArray(value)) {
      const firstEntry = value.at(0) as { [key: string]: any };
      setEntries(
        value.map((val) => ({
          id: nanoid(),
          value: watchedParameterName ? renameCustomParameter(val) : val,
        }))
      );
      if (
        watchedParameterName === undefined ||
        (watchedParameterName === '' && firstEntry !== undefined)
      ) {
        setValue('parameterName', Object.keys(firstEntry)[0]);
      }
    } else {
      if (parameterType === ParameterType.numeric && Array.isArray(value)) {
        // @ts-ignore
        value.forEach((v) => addEntryNonCustom(v));
      } else {
        addEntryNonCustom(value);
      }
    }
  };

  const removeEntry = (id: string) =>
    setEntries((old) => old.filter((entry) => id !== entry.id));

  const renameCustomParameter = (entry: any) => {
    if (watchedParameterName !== undefined) {
      const keys = [watchedParameterName, ...Object.keys(entry).slice(1)];
      const newCopy: { [key: string]: any } = {};
      Object.values(entry).forEach((value, index) => {
        newCopy[keys[index]] = value;
      });
      return newCopy;
    }
    return entry;
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = await trigger();

    if (!isValid) return;

    const { parameterName } = getValues();
    let parameterValues: any[] = entries
      .filter(({ value }) => value !== undefined && value !== '')
      .reduce((acc: any[], curr, index, array) => {
        switch (parameterType) {
          case ParameterType.numeric:
            acc.push(parseFloat(curr.value as string));
            break;
          case ParameterType.custom:
            acc.push(renameCustomParameter(curr.value));
            break;
          case ParameterType.continuous:
            if (
              index === array.length - 1 &&
              isNumericalContinuousParameter(curr.value)
            ) {
              acc.push(...curr.value);
            }
            break;
          default:
            acc.push(curr.value);
            break;
        }
        return acc;
      }, []);

    const newParameter: Parameter = {
      parameterName,
      parameterType,
      parameterValues,
    } as Parameter;

    if (parameterType == ParameterType.task)
      newParameter['activeValues'] = activeValues;

    try {
      onSubmit(newParameter);
      handleFormInvalid();
    } catch (err) {
      const { message, field } = err as FormError;
      setError(field as any, { type: 'custom', message });
    }
  };
  const handleFormInvalid = React.useCallback(() => {
    setEntries([]);
    setValue('parameterName', undefined);
    setValue('parameterValueCount', 0);
  }, []);

  const handleCancel = () => {
    handleFormInvalid();
    onCancel();
  };

  useEffect(() => {
    setEntries([]);
  }, [parameterType]);

  useEffect(() => {
    if (parameterType === ParameterType.custom && Array.isArray(values)) {
      addEntry(values as CustomParameterValue[]);
    } else if (values) {
      values.forEach((val) => {
        if (typeof val === 'number') {
          addEntry(val.toString());
        } else {
          addEntry(val as SmilesParameterValue | string);
        }
      });
    }
  }, [values, parameterType]);

  useEffect(() => {
    setValue(
      'parameterValueCount',
      entries.length + (readOnlyParameter?.parameterValues.length || 0)
    );
    if (entries.length > 1) clearErrors('parameterValueCount');

    if (parameterType == ParameterType.task && entries.length > 1) {
      if (activeValues.length > 0) {
        clearErrors('parameterValueCount');
        setValue('parameterValueCount', activeValues.length);
      } else {
        setValue('parameterValueCount', 0);
      }
    }
  }, [
    entries,
    readOnlyParameter?.parameterValues.length,
    entries.length,
    activeValues,
  ]);

  const handleParameterTypeChange = React.useCallback(
    (event: LdSelectCustomEvent<string[]>) => {
      setEntries([]);
      setParameterType(event.detail[0] as any);
    },
    []
  );

  const valuesLabel = useMemo(() => {
    switch (parameterType) {
      case ParameterType.custom:
        return wrapLabel('Par_5', 'Values', tetherOptions, {
          position: 'top left',
        });
      case ParameterType.numeric:
        return wrapLabel('Par_6', 'Values', tetherOptions, {
          position: 'top left',
        });
      case ParameterType.continuous:
        return;
      default:
        return 'Values';
    }
  }, [parameterType]);

  const projectParameterValues = readOnlyParameter?.parameterValues;

  function isSmilesParameterValue(
    value?: ParameterValue | SmilesParameterValue
  ): value is SmilesParameterValue {
    return (
      (value as SmilesParameterValue).SMILES !== undefined ||
      (value as SmilesParameterValue).Name !== undefined
    );
  }

  const isValueReadOnly = useCallback(
    (value?: ParameterValue | SmilesParameterValue) => {
      if (!value) {
        return false;
      }

      if (isSmilesParameterValue(value)) {
        return Boolean(
          projectParameterValues?.find(
            (v) =>
              isSmilesParameterValue(v) &&
              v.SMILES === value.SMILES &&
              v.Name === value.Name
          )
        );
      }

      return Boolean(projectParameterValues?.find((v) => `${v}` === value));
    },
    []
  );
  return (
    <div className="flex w-full flex-col relative" ref={modalRefParams}>
      <form
        autoComplete="off"
        className="flex w-full flex-col"
        onSubmit={handleFormSubmit}
        ref={formRef}
      >
        <input
          type="hidden"
          {...register('parameterValueCount', {
            required: true,
            validate: (value) => value >= 1,
          })}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-ld-24">
          <LdLabel>
            <span className="flex justify-between">
              {wrapLabel('Par_1', 'Parameter Type', tetherOptions, {
                position: 'bottom center',
              })}
            </span>
            <LdSelect
              tetherOptions={tetherOptions}
              disabled={parameter !== undefined}
              onLdinput={handleParameterTypeChange}
              placeholder="Select"
              name="param-type-select"
            >
              {Object.keys(ParameterType).map((val) => (
                <LdOption
                  key={val}
                  value={val}
                  selected={parameterType === val}
                >
                  {ParameterTypesLabel.get(val)}
                </LdOption>
              ))}
            </LdSelect>
            <LdInputMessage
              className={errors.parameterValueCount ? 'visible' : 'invisible'}
              mode={errors.parameterValueCount ? 'error' : 'valid'}
            >
              {errors.parameterValueCount &&
                (parameterType === ParameterType.task
                  ? 'Parameters and active values are required.'
                  : 'You must add at least two parameter values!')}
            </LdInputMessage>
          </LdLabel>
          <LdLabel>
            {wrapLabel('Par_2', 'Parameter Name', tetherOptions)}
            <LdInput
              placeholder="e.g. Time"
              type="text"
              tone="dark"
              {...register('parameterName', {
                required: true,
              })}
              onInput={(ev) => {
                setValue(
                  'parameterName',
                  (ev.target as HTMLLdInputElement).value,
                  {
                    shouldValidate: isFormDirty || dirtyFields.parameterName,
                  }
                );
              }}
              onBlur={(ev) => {
                setValue('parameterName', ev.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              value={watchedParameterName}
              invalid={Boolean(errors.parameterName)}
              disabled={
                projectStatus !== undefined &&
                ProjectStatus.draft != projectStatus &&
                !isExpanded
              }
            />
            <LdInputMessage
              className={errors.parameterName ? 'visible' : 'invisible'}
              mode={errors.parameterName ? 'error' : 'valid'}
            >
              {(errors.parameterName && errors.parameterName.message) ||
                'Name is required.'}
            </LdInputMessage>
          </LdLabel>
        </div>
        {parameterType !== undefined && (
          <LdLabel>
            <span className="flex justify-between mb-1">{valuesLabel}</span>
          </LdLabel>
        )}
        {valueError && (
          <LdInputMessage
            className={valueError ? 'visible' : 'invisible'}
            mode={valueError ? 'error' : 'valid'}
          >
            {valueError}
          </LdInputMessage>
        )}
        <div className="flex flex-wrap gap-ld-4 mb-ld-4">
          {parameterType !== ParameterType.custom &&
            parameterType !== ParameterType.continuous &&
            entries.map((entry) => {
              return (
                <Tag
                  readOnly={isValueReadOnly(entry.value)}
                  onDelete={() => removeEntry(entry.id)}
                  key={entry.id}
                >
                  {parameterType === ParameterType.smiles
                    ? (entry.value as SmilesParameterValue).Name
                    : entry.value}
                </Tag>
              );
            })}
          {parameterType === ParameterType.custom && (
            <CustomParameterTable
              name={parameter?.parameterName || 'Custom'}
              values={
                entries.length > 0
                  ? (entries.map(
                      ({ value }) => value
                    ) as CustomParameterValue[])
                  : parameter?.parameterValues || []
              }
            />
          )}
        </div>
        {/* {entries.map((entry, index, arr) =>
        renderEntry(entry, index === arr.length - 1)
      )} */}
      </form>
      {parameterType !== undefined && (
        <ParameterValuesForm
          tetherOptions={tetherOptions}
          onSubmit={addEntry}
          parameterType={parameterType}
        />
      )}
      {parameterType === ParameterType.task && (
        <LdLabel>
          {wrapLabel('Par_9', 'Active Values', tetherOptions, {
            position: 'top left',
          })}
          <LdSelect
            tetherOptions={tetherOptions}
            placeholder="Select active values"
            name="activeValues"
            onLdinput={(ev) => setActiveValue(ev.detail)}
            multiple
            mode="inline"
            disabled={parameter?.parameterValues?.length == 0}
            required
          >
            {entries.map((entry) => (
              <LdOption key={entry.id} value={entry.value?.toString()}>
                {entry.value}
              </LdOption>
            ))}
          </LdSelect>
          <div className="mb-6"></div>
        </LdLabel>
      )}

      <div className="flex flex-row items-end justify-end gap-x-ld-8">
        <LdButton onClick={handleCancel} mode="danger-secondary" type="button">
          Cancel
        </LdButton>
        <LdButton
          form="parameter-form"
          name="btn-submit-params"
          onClick={(e) => handleFormSubmit(e)}
        >
          Submit
        </LdButton>
      </div>
    </div>
  );
};

AddParameterForm.propTypes = {};

export default AddParameterForm;
