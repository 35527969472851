import React from 'react';
import { LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import { Link } from 'react-router-dom';

interface Props {
  projectId?: string;
  projectName?: string;
}

const Breadcrumbs: React.FC<Props> = () => {
  return (
    <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
      <Link to={`/projects`} className="flex hover:underline">
        <LdTypo variant="body-s">My Projects</LdTypo>
      </Link>
    </div>
  );
};

export { Breadcrumbs as default };
