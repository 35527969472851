import React, { useEffect, useMemo, useState } from 'react';
import {
  LdButton,
  LdIcon,
  LdTable,
  LdTableHead,
  LdTableBody,
  LdTableHeader,
  LdTableRow,
  LdTableCell,
  LdModal,
  LdBadge,
  LdTypo,
  LdInput,
  LdLabel,
  LdInputMessage,
} from '@emdgroup-liquid/liquid/dist/react';
import { ParameterRecommendation, Project } from 'types/api/types';
import Card from 'components/Card';
import LoadingOverlay from './LoadingOverlay';
import { useHints } from 'hooks/useHints';
import useRecommendations from '../hooks/useRecommendations';
import { isBusy as isProjectBusy } from 'util/project';
import {
  FileTypes,
  SUPPORTED_FILE_TYPES,
} from '../../../components/ChemicalSpace/UploadDropZone/FileTypes';
import { toSafeInteger } from 'lodash';

interface Props {
  selectedProject: Project;
  remainingExperiments: number;
  isBusy?: boolean;
  disabled?: boolean;
}

export const RecommendationsList: React.FC<Props> = ({
  selectedProject,
  disabled,
  remainingExperiments,
  isBusy,
}) => {
  const [selectedFileType, updateFileType] = useState(SUPPORTED_FILE_TYPES.CSV);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [batchSize, setBatchSize] = useState(
    selectedProject.batchSize.toString()
  );

  const isValidBatchSize =
    batchSize == toSafeInteger(batchSize).toFixed(0) &&
    !!batchSize &&
    batchSize != '' &&
    toSafeInteger(batchSize) >= 1;

  const {
    recommendations,
    selectRecommendation,
    exportRecommendations,
    recreateRecommendations,
  } = useRecommendations(selectedProject.projectId);
  const { getToolTipById } = useHints();

  const _isBusy = useMemo(() => {
    return isProjectBusy(selectedProject) || isBusy;
  }, [selectedProject, isBusy]);

  const hasRecommendations = recommendations.length > 0;

  const getParameterValues = (
    parameters: ParameterRecommendation,
    parameterName: string,
    index: number
  ) => {
    const value = (parameters as { [key: string]: any })[parameterName];

    return (
      value ??
      (
        Object.values(parameters) as {
          [key: string]: any;
        }
      )[index]
    );
  };

  const onExport = () => {
    exportRecommendations(selectedFileType);
    setExportModalOpen(false);
  };

  const onEditClick = () => {
    recreateRecommendations(batchSize);
  };

  const exportFileModal = useMemo(
    () => (
      <LdModal
        blurryBackdrop
        open={exportModalOpen}
        onLdmodalclosed={() => setExportModalOpen(false)}
        style={{ '--ld-modal-min-inline-size': '40rem' }}
      >
        <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
          <LdBadge icon="attention" size="lg" class="ld-badge--info" />
          <LdTypo variant="h4">Export file type</LdTypo>
        </div>
        <div className="flex-col">
          <LdTypo variant="body-l">
            Please select the file type you want to export
          </LdTypo>
          <FileTypes
            selectedType={selectedFileType}
            updateSelectedFileType={updateFileType}
          />

          <div
            slot="footer"
            className="flex flex-row items-end justify-end gap-x-ld-8"
          >
            <LdButton
              onClick={onExport}
              data-for="export-tooltip"
              disabled={!selectedFileType}
              mode="ghost"
            >
              <LdIcon name="cloud-download" />
              Export
            </LdButton>
          </div>
        </div>
      </LdModal>
    ),
    [exportModalOpen, selectedFileType]
  );

  return (
    <Card className="p-4 mt-8 relative">
      <>
        {_isBusy && <LoadingOverlay />}
        <div className="w-full text-right">
          {!_isBusy && hasRecommendations && getToolTipById('Exp_7')}
        </div>
        <div className={`w-full ${_isBusy && 'blur-sm'}`}>
          {hasRecommendations && (
            <LdTable
              className={`table-auto min-w-full ${_isBusy && 'blur-sm'}`}
            >
              <LdTableHead>
                <LdTableRow>
                  {selectedProject.parameterSpace.map((p) => (
                    <LdTableHeader
                      className="px-4 text-left"
                      key={`recommendation_header_${p.parameterName}`}
                    >
                      {p.parameterName}
                    </LdTableHeader>
                  ))}
                  <LdTableHeader />
                </LdTableRow>
              </LdTableHead>
              <LdTableBody className="divide-y divide-sensitive-grey-darker">
                {recommendations.map(({ recommendationId, parameters }) => (
                  <LdTableRow key={recommendationId}>
                    {selectedProject.parameterSpace.map((p, index) => (
                      <LdTableCell
                        key={`${recommendationId}_${p.parameterName}`}
                        className="px-4"
                      >
                        {getParameterValues(parameters, p.parameterName, index)}
                      </LdTableCell>
                    ))}
                    <LdTableCell className="flex justify-end">
                      <div
                        data-name="select-recommendation"
                        className="flex w-max justfiy-end gap-2"
                      >
                        <LdButton
                          size="sm"
                          mode="secondary"
                          slot="trigger"
                          disabled={disabled || _isBusy}
                          progress={_isBusy ? 'pending' : undefined}
                          onClick={
                            !disabled && !_isBusy
                              ? () => selectRecommendation(recommendationId)
                              : undefined
                          }
                        >
                          <LdIcon name="test-tube" />
                        </LdButton>
                        {/* <LdButton
                    size="sm"
                    slot="trigger"
                    mode="danger"
                    onClick={handleRejectRecommendation(
                      recommendationId
                    )}
                  >
                    <LdIcon name="bin" />
                  </LdButton> */}
                      </div>
                    </LdTableCell>
                  </LdTableRow>
                ))}
              </LdTableBody>
            </LdTable>
          )}

          <div className="flex justify-end gap-4 w-full mt-ld-8">
            {hasRecommendations && (
              <LdLabel>
                <br />
                <LdButton
                  mode="ghost"
                  onClick={() => setExportModalOpen(true)}
                  data-for="export-tooltip"
                >
                  <LdIcon name="cloud-download" />
                  Export
                </LdButton>
              </LdLabel>
            )}
            <LdLabel>
              Recommendations:
              <LdInput
                style={{ maxWidth: '10rem' }}
                name="extendedRecommendation"
                min="1"
                max={remainingExperiments}
                step="1"
                type="number"
                value={batchSize}
                onLdchange={(ev) => {
                  setBatchSize(ev.detail);
                }}
              >
                <LdButton
                  onClick={onEditClick}
                  slot="end"
                  disabled={
                    disabled ||
                    !isValidBatchSize ||
                    remainingExperiments < parseInt(batchSize)
                  }
                  type="button"
                >
                  Recommend
                </LdButton>
              </LdInput>
              {!isValidBatchSize && (
                <LdInputMessage
                  mode="error"
                  className="break-words max-w-[10rem]"
                >
                  Must be valid integer greater than 0
                </LdInputMessage>
              )}
            </LdLabel>
          </div>
        </div>
      </>
      {exportFileModal}
    </Card>
  );
};
