import React from 'react';
import {
  LdTable,
  LdTableBody,
  LdTableCaption,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
  LdTableToolbar,
} from '@emdgroup-liquid/liquid/dist/react';
import { arrayToTableData } from 'util/utils';
import { TableProps } from './AddParameterForm';

export const CustomParameterTable: React.FC<TableProps> = ({
  values,
  name,
}) => {
  const { headers, rows } = arrayToTableData<string>(values);
  return (
    <LdTable className="max-h-[26rem]">
      <LdTableToolbar slot="toolbar">
        <LdTableCaption>{name}</LdTableCaption>
      </LdTableToolbar>

      <LdTableHead className="text-left">
        <LdTableRow>
          {headers.map((key) => (
            <LdTableHeader key={`header_${key}`} sortable className="text-left">
              {key}
            </LdTableHeader>
          ))}
        </LdTableRow>
      </LdTableHead>

      <LdTableBody className="text-left">
        {rows.map((row: string[]) => (
          <LdTableRow key={`row_${row.join('-')}`}>
            {row.map((col: string, index: number) => (
              <LdTableCell key={`col_${row.join('-')}_${headers[index]}`}>
                {col}
              </LdTableCell>
            ))}
          </LdTableRow>
        ))}
      </LdTableBody>
    </LdTable>
  );
};
