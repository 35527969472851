import _ from 'lodash';

export type NumericValueType = 'number' | 'range' | 'invalid_range' | 'invalid';

type NumberRange = {
  start: number;
  end: number;
  stride: number;
};

const floatingNumberRegexString = '[+-]?\\d+(\\.\\d+)?';
const floatingNumberRegex = new RegExp(`^${floatingNumberRegexString}$`);
const rangeRegex = new RegExp(
  `^(?<start>${floatingNumberRegexString})-(?<end>${floatingNumberRegexString});(?<stride>${floatingNumberRegexString})$`
);

const isValidRange = (range: NumberRange) => {
  const start = Number(range.start);
  const end = Number(range.end);
  const stride = Number(range.stride);

  if (isNaN(start) || isNaN(end) || isNaN(stride)) {
    return false;
  }

  return start <= end && stride > 0;
};

const getRangeFromInput = (input: string): NumberRange | null => {
  const result = rangeRegex.exec(input);
  if (!result || !result.groups) {
    return null;
  }

  const { groups } = result;

  return {
    start: Number(groups.start),
    end: Number(groups.end),
    stride: Number(groups.stride),
  };
};

export const getNumericValueType = (value: string): NumericValueType => {
  if (floatingNumberRegex.test(value)) {
    return 'number';
  }

  if (rangeRegex.test(value)) {
    const range = getRangeFromInput(value);

    if (range === null || !isValidRange(range)) {
      return 'invalid_range';
    }

    return 'range';
  }

  return 'invalid';
};

const getArrayFromRange = (range: NumberRange): number[] => {
  const arr = [];
  let current = range.start;

  do {
    arr.push(current);
    current = addNumbersSafe(current, range.stride);
    // current += range.stride;
  } while (current <= range.end);

  return arr;
};

export const getNumericValueFromInput = (
  input: string
): number | number[] | null => {
  const inputType = getNumericValueType(input);

  switch (inputType) {
    case 'number':
      return Number(input);
    case 'range': {
      const range = getRangeFromInput(input);

      if (range === null) {
        return null;
      }

      return getArrayFromRange(range);
    }
    default:
      return null;
  }
};

const addNumbersSafe = (n1: number, n2: number) => {
  const str1 = n1.toString();
  const str2 = n2.toString();
  const [, decimals1 = ''] = str1.split('.');
  const [, decimals2 = ''] = str2.split('.');

  const maxDecimalsLength = Math.max(decimals1.length, decimals2.length);

  return _.round(n1 + n2, maxDecimalsLength);
};
