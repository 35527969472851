import {
  LdNotification,
  LdModal,
  LdTypo,
  LdLink,
} from '@emdgroup-liquid/liquid/dist/react';
import React, { SyntheticEvent, useMemo } from 'react';
import WhatsNew from 'components/WhatsNew';
import Sidebar from './components/Sidebar';
import useWhatsNew from 'hooks/useWhatsNew';
import { useNavigate } from 'react-router-dom';

import './lds.scss';

// const { FEEDBACK_PRODUCT_ID = '000062bc65aa7c8a3dbb16ed4d26' } = process.env;

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const {
    setlastViewed,
    latestVersion,
    hasNewEntries,
    newEntries,
    lastViewed,
  } = useWhatsNew();

  const newVersion = (
    <>
      <LdTypo slot="header">What&apos;s New</LdTypo>
      <WhatsNew entries={newEntries} />
    </>
  );

  const acknowledgeVersion = (redirectPath: string) => (e: SyntheticEvent) => {
    e.preventDefault();
    setlastViewed(latestVersion);
    navigate(redirectPath);
  };

  const initialView = useMemo(
    () => (
      <>
        <LdTypo slot="header">Welcome</LdTypo>
        <div className="flex flex-col w-full">
          <LdTypo variant="body-l">
            Since it is the first time here, we are inviting you to check these
            essential links
            <div className="flex flex-col">
              {process.env.USER_GUIDE === 'true' && (
                <span className="flex">
                  <LdLink
                    chevron="start"
                    onClick={acknowledgeVersion('/user-guide')}
                  >
                    User Guide
                  </LdLink>
                </span>
              )}
              <span className="flex">
                <LdLink chevron="start" onClick={acknowledgeVersion('/faq')}>
                  FAQ
                </LdLink>
              </span>
            </div>
          </LdTypo>
        </div>
      </>
    ),
    [lastViewed]
  );

  return (
    <>
      <LdNotification placement="top" />
      <div className="fixed flex flex-col items-stretch w-full h-screen overflow-hidden bg-sensitive-grey-darker text-rich-purple-dark">
        <div className="flex flex-grow h-full overflow-auto">
          <Sidebar className="h-full" />
          <main className="flex-grow h-full min-h-screen overflow-auto">
            {children}
          </main>
        </div>
      </div>
      <LdModal
        blurryBackdrop
        open={hasNewEntries}
        className="min-w-[200rem]"
        onLdmodalclosed={() => setlastViewed(latestVersion)}
      >
        {!lastViewed ? initialView : newVersion}
      </LdModal>
    </>
  );
};

export default AppLayout;
