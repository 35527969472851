/* eslint-disable import/named */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { Recommendation, ExperimentResults } from 'types/api/types';
import { ExperimentResult, StateStatus } from 'types/app/types';

export interface SelectableRecommendation extends Recommendation {
  isSelected?: boolean;
}

export interface RecommendationsState {
  input: { [key: string]: ExperimentResult };
  status: StateStatus;
  error: string | null;
}

export const initialState: RecommendationsState = {
  input: {},
  status: StateStatus.idle,
  error: null,
};

export const inputSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<string>) {
      state.status = StateStatus[action.payload as keyof typeof StateStatus];
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    updateResults: (
      state,
      action: PayloadAction<{
        key: string;
        results: ExperimentResults;
      }>
    ) => {
      Object.entries(action.payload.results).forEach((entry) => {
        const [key, value] = entry as [keyof ExperimentResults, never];

        if (value !== undefined) {
          state.input[action.payload.key].results[key] = value;
        } else {
          delete state.input[action.payload.key].results[key];
        }
      });
    },
    addBulkData: (
      state,
      action: PayloadAction<
        {
          key: string;
          input: ExperimentResult;
        }[]
      >
    ) => {
      action.payload.forEach(({ key, input }) => {
        state.input[key] = input;
      });
    },
    addInput: (
      state,
      action: PayloadAction<{
        key: string;
        input: ExperimentResult;
      }>
    ) => {
      const { key, input } = action.payload;
      state.input[key] = input;
    },
    updateInputParams: (
      state,
      action: PayloadAction<{
        key: string;
        paramName: string;
        value: string;
      }>
    ) => {
      const { key, paramName, value } = action.payload;
      // @ts-expect-error
      state.input[key].parameters[paramName] = value;
    },
    removeInput: (
      state: WritableDraft<RecommendationsState>,
      action: {
        payload: { key: string };
      }
    ) => {
      const { key } = action.payload;
      delete state.input[key];
    },
    clear: (state: WritableDraft<RecommendationsState>) => {
      state.input = {};
    },
  },
});

export const {
  addBulkData,
  addInput,
  updateInputParams,
  removeInput,
  updateResults,
  clear,
  setError,
  setStatus,
} = inputSlice.actions;
export default inputSlice.reducer;
