import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Item } from '../Item';
import {
  LdAccordion,
  LdAccordionPanel,
  LdAccordionSection,
  LdAccordionToggle,
  LdIcon,
} from '@emdgroup-liquid/liquid/dist/react';

import { NavLink } from 'react-router-dom';
import { TeamsIcon } from '../../../../components/Icons';
import './SidebarMore.scss';

const { REACT_APP_TEAMS_CHANNEL = '' } = process.env;
const isMd = window.innerWidth > 500;

const SidebarMore = () => {
  const [open, toggleOpen] = useState(false);
  const accordionRef = useRef<HTMLLdAccordionElement | null>(null);
  const accordionSectionRef = useRef<HTMLLdAccordionSectionElement | null>(
    null
  );

  const toggleAccordion = useCallback(
    (state: boolean) => {
      if (accordionSectionRef.current) {
        toggleOpen(state);
        accordionSectionRef.current.expanded = state;
      }
    },
    [accordionSectionRef]
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accordionRef.current &&
      open &&
      !accordionRef.current.contains(event.target as Node)
    ) {
      toggleAccordion(false);
    }
  };
  useEffect(() => {
    const curr = accordionRef.current;
    function _toggleOpen(e: Event) {
      toggleOpen((e as CustomEvent).detail);
    }
    if (curr) {
      curr.addEventListener('ldaccordionchange', _toggleOpen);
      return () => curr.removeEventListener('ldaccordionchange', _toggleOpen);
    }
  }, [accordionRef]);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    if (
      accordionSectionRef.current &&
      open !== accordionSectionRef.current.expanded
    ) {
      accordionSectionRef.current.expanded = open;
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, accordionSectionRef]);

  return (
    <LdAccordion brandColor class="accordion-menu z-50" ref={accordionRef}>
      <LdAccordionSection ref={accordionSectionRef}>
        <LdAccordionToggle className="accordion-menu-toggle">
          <div className="flex flex-row ml-1">
            <div className="flex items-center justify-center w-5 max-h-full">
              <LdIcon name="data-storage" />
            </div>
            <div className="ml-3 whitespace-no-wrap transform translate-y-px">
              More ...
            </div>
          </div>
        </LdAccordionToggle>
        {true && (
          <LdAccordionPanel
            className={`accordion-menu-panel ${open && 'open'}`}
          >
            <NavLink to="/roadmap">
              {({ isActive }) => {
                if (isActive) toggleOpen(true);
                return (
                  <Item
                    data-test="sidebar-item-roadmap"
                    label="Roadmap"
                    icon={<LdIcon name="list" />}
                    isCollapsed={!isMd}
                    isSelected={isActive}
                  />
                );
              }}
            </NavLink>
            <NavLink to="/changelog">
              {({ isActive }) => {
                if (isActive) toggleOpen(true);
                return (
                  <Item
                    data-test="sidebar-item-changelog"
                    label="Changelog"
                    icon={<LdIcon name="documents" />}
                    isCollapsed={!isMd}
                    isSelected={isActive}
                  />
                );
              }}
            </NavLink>
            <NavLink to="/invitations">
              {({ isActive }) => {
                if (isActive) toggleOpen(true);
                return (
                  <Item
                    data-test="sidebar-item-database"
                    label="Invitations"
                    icon={<LdIcon name="mail" />}
                    isCollapsed={!isMd}
                    isSelected={isActive}
                  />
                );
              }}
            </NavLink>

            <NavLink to="/faq">
              {({ isActive }) => {
                if (isActive) toggleOpen(true);
                return (
                  <Item
                    data-test="sidebar-item-faq"
                    label="FAQ"
                    icon={<LdIcon name="conversation" />}
                    isCollapsed={!isMd}
                    isSelected={isActive}
                  />
                );
              }}
            </NavLink>

            <a href={REACT_APP_TEAMS_CHANNEL} target="_blank" rel="noreferrer">
              <Item
                data-test="sidebar-item-teams"
                label="Teams Channel"
                icon={
                  <LdIcon>
                    <TeamsIcon />
                  </LdIcon>
                }
                isCollapsed={!isMd}
                isSelected={false}
                onClick={() => toggleAccordion(false)}
              />
            </a>
          </LdAccordionPanel>
        )}
      </LdAccordionSection>
    </LdAccordion>
  );
};

export default SidebarMore;
