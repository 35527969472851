import { useMemo } from 'react';
import { tableColumns } from './tableColumns';
import { Targets } from './types';

export const useTargetRowsWithErrors = (targets: Targets) =>
  useMemo<Record<string, string>>(() => {
    const targetEntries = Object.entries(targets);

    if (targetEntries.length <= 1) {
      return {};
    }

    return Object.entries(targets).reduce<Record<string, string>>(
      (acc, [targetKey, target]) => {
        const hasError = tableColumns.some(
          ({ key }) => target[key] === undefined || !`${target[key]}`
        );

        if (hasError) {
          acc[targetKey] =
            'All fields (including bounds) need to be filled with values when there is more than one target';
        }

        return acc;
      },
      {}
    );
  }, [targets]);
