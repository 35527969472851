import { useUser } from '@emdgroup/react-auth';
import { useCachedQuery } from '@emdgroup/react-query';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT.replace(/\/+$/, '')
  : '';

function useApi<T>(...[method, path, body]: Parameters<typeof useCachedQuery>) {
  const { authHeader } = useUser();
  return useCachedQuery<T>(method, path, body, authHeader);
}

export { API_ENDPOINT, useApi as default };
