import React, { useMemo, useState, useRef, useEffect } from 'react';
import {
  LdButton,
  LdIcon,
  LdTableCell,
  LdTableRow,
  LdTooltip,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import { Parameter, TargetVariable } from 'types/api/types';
import { ParameterInputSelection } from './ParameterInputSelection';
import useRecommendations from '../hooks/useRecommendations';

interface Props {
  onAddParamChange: (p: Parameter, value: string) => void;
  projectId: string;
  parameters: Parameter[];
  targets: TargetVariable[];
}

const AddInputForm: React.FC<Props> = ({
  projectId,
  parameters,
  targets,
  onAddParamChange,
}) => {
  const tooltipRef = useRef<HTMLLdButtonElement | null>(null);
  const initialState = parameters.reduce((prev, cur) => {
    return Object.assign(prev, { [cur.parameterName]: undefined });
  }, {});
  const { addOwnInput, clearInputs } = useRecommendations(projectId);

  useEffect(() => {
    return () => {
      clearInputs();
    };
  }, []);

  const [selection, setSelection] =
    useState<{ [key: string]: string }>(initialState);
  const [touched, setTouched] = useState(false);

  const isValid = useMemo(() => {
    for (let i = 0; i < Object.values(selection).length; i++) {
      if (Object.values(selection)[i] === undefined) return false;
    }
    return true;
  }, [selection]);

  const handleChange =
    (paramter: Parameter) =>
    (param: { value: any; isNew?: boolean | undefined }) => {
      const { value, isNew = false } = param;
      setTouched(true);
      const newState = { ...selection, [paramter.parameterName]: value };
      setSelection(newState);
      if (isNew) {
        onAddParamChange(paramter, value);
      }
    };

  const handleSubmit = () => {
    if (isValid) {
      addOwnInput(selection);
      setSelection(initialState);
    } else {
      setTouched(touched);
    }
  };
  return (
    <LdTableRow>
      {parameters.map((p) => {
        return (
          <LdTableCell
            className="py-1 px-4 text-left"
            key={`new_input_${p.parameterName}`}
          >
            <ParameterInputSelection
              touched={touched && !isValid}
              parameter={p}
              selected={selection[p.parameterName]}
              onChange={handleChange(p)}
            />
          </LdTableCell>
        );
      })}
      {targets.map(({ targetName }) => (
        <LdTableCell
          className="py-1 px-4 text-left"
          key={`new_input_${targetName}`}
        ></LdTableCell>
      ))}
      <LdTableCell className="py-1 text-right">
        <LdTooltip className="h-1" disabled={isValid}>
          <LdButton
            ref={tooltipRef}
            size="sm"
            slot="trigger"
            disabled={!isValid}
            onClick={handleSubmit}
            mode="secondary"
          >
            <LdIcon name="test-tube" />
          </LdButton>
          <LdTypo className="p-ld-8" variant="body-xs">
            Please select a value for all Parameters
          </LdTypo>
        </LdTooltip>
      </LdTableCell>
    </LdTableRow>
  );
};

export { AddInputForm as default };
