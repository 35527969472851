import React from 'react';
import ContentLoader from 'react-content-loader';
import Card from '../Card';

const SpaceLoader = () => {
  return (
    <Card className=" w-full">
      <ContentLoader viewBox="0 0 380 50">
        <rect x="0" y="0" rx="1" ry="1" width="100" height="10" />
        <rect x="0" y="20" rx="1" ry="1" width="150" height="7" />
        <rect x="0" y="32" rx="1" ry="1" width="150" height="7" />
        <rect x="0" y="44" rx="1" ry="1" width="150" height="7" />
      </ContentLoader>
    </Card>
  );
};

export default SpaceLoader;
