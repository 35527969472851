import { LdIcon, LdOption, LdSelect } from '@emdgroup-liquid/liquid/dist/react';
import React, { useCallback, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import { camelCaseToSpaces } from 'util/utils';
import { type LdSelectCustomEvent } from '@emdgroup-liquid/liquid';

interface DropdownProps {
  onChange: (value: string) => void;
  options: string[];
  value?: string;
  placeholder?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  value,
  options,
  onChange,
  placeholder,
}) => {
  const mappedOptions = useMemo(() => {
    return options.map((item) => ({
      label: camelCaseToSpaces(item),
      value: item,
    }));
  }, [options]);

  const handleChange = useCallback(
    (event: LdSelectCustomEvent<string[]>) => {
      onChange(event.detail[0]);
    },
    [onChange]
  );

  return (
    <div className="flex">
      <LdSelect
        onLdchange={handleChange}
        placeholder={placeholder}
        mode="ghost"
        size="sm"
      >
        {mappedOptions.map((option) => (
          <LdOption
            key={option.value}
            value={option.value}
            selected={value === option.value}
          >
            {option.label}
          </LdOption>
        ))}
        <LdIcon name="arrow-up-n-down" slot="icon" />
      </LdSelect>
      <ReactTooltip type="light" effect="solid" className="tooltip" />
    </div>
  );
};

export default Dropdown;
