import React, { Dispatch, SetStateAction, useState } from 'react';
import { LdInputMessage } from '@emdgroup-liquid/liquid/dist/react';
import UploadDropzone from '../UploadDropZone';
import { parseCSVFromFile } from '../../../util/csv';
import { CustomParameterValue } from '../../../types/api/types';
import { readXlsxFile } from '../../../util/xlsx';
import { FileTypes, SUPPORTED_FILE_TYPES } from '../UploadDropZone/FileTypes';

interface ParameterInputCustomProps {
  error?: string;
  setValue: Dispatch<SetStateAction<CustomParameterValue[] | undefined>>;
  setError: (error?: string) => void;
}

export const ParameterInputCustom: React.FC<ParameterInputCustomProps> = ({
  error,
  setValue,
  setError,
}) => {
  const [selectedType, updateFileType] = useState(SUPPORTED_FILE_TYPES.CSV);

  const handleDropFile = async (files: File[] | FileList) => {
    if (!selectedType) return;

    setError(undefined);
    const file = files[0];
    if (file) {
      try {
        if (file.type === SUPPORTED_FILE_TYPES.CSV) {
          const response = await parseCSVFromFile(file);
          setValue(response);
        } else if (file.type === SUPPORTED_FILE_TYPES.XLSX) {
          const response = await readXlsxFile(file);
          setValue(response);
        }
      } catch (err) {
        setError((err as any).toString());
        console.log(err);
      }
    }
  };

  return (
    <div className="flex flex-col w-full">
      <FileTypes
        selectedType={selectedType}
        updateSelectedFileType={updateFileType}
      />
      <LdInputMessage
        className={error ? 'visible' : 'invisible'}
        mode={error ? 'error' : 'valid'}
      >
        {error}
      </LdInputMessage>
      <UploadDropzone
        allowedTypes={[selectedType]}
        className="my-ld-16"
        onDrop={(files: File[]) => handleDropFile(files)}
      />
    </div>
  );
};
