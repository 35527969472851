import { projectsSlice } from './projects.slice';

export * from './projects.effects';
export * from './projects.selectors';
export const {
  setProjects,
  setProject,
  selectProject,
  unselectProject,
  updateProject,
  setSortyBy,
  updateParameter,
  changeOrder,
  setShowClosed,
  setProjectStatus,
} = projectsSlice.actions;

export const reducerName = projectsSlice.name;
export default projectsSlice.reducer;
