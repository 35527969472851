import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useUser } from '@emdgroup/react-auth';
import assert from 'assert';
import axios from 'axios';
import { API_ENDPOINT } from 'hooks/useApi';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import {
  addInput,
  clear,
  removeInput,
  setError,
  setStatus,
  updateResults,
  addBulkData,
  updateInputParams,
} from 'services/inputs';
import { updateProject } from 'services/projects';
import {
  selectRecommendation,
  unselectRecommendation,
  selectRecommendationsForProject,
  recreateRecommendations,
  setRecommendations,
} from 'services/recommendations';
import { useDispatch, useSelector } from 'store';
import { isCreateExperimentsResponse } from 'types/api/types';
import moment from 'moment/moment';
import { exportCSVFile } from 'util/csv';
import useProjectDetails from 'pages/Project/hooks/useProjectDetails';
import { SUPPORTED_FILE_TYPES } from '../../../components/ChemicalSpace/UploadDropZone/FileTypes';
import { exportXlsxFile } from '../../../util/xlsx';

const apiEndpoint = API_ENDPOINT;

const useRecommendations = (projectId: string) => {
  const { trackEvent } = useMatomo();
  const { authHeader } = useUser();
  const dispatch = useDispatch();
  const recommendations = useSelector(
    selectRecommendationsForProject(projectId)
  );

  // todo:  remove heavy dependency to useProjectDetails
  const { project } = useProjectDetails(projectId);
  const input = useSelector((state) => state.input.input);

  // TODO: check if commenting this block doesn't affect other places.
  //  This is a fix for BUG 14342 (https://dev.azure.com/Uptimize/factory-baychem-nreg-ec1/_workitems/edit/14342)
  //  Bug title: Transferring all Recommendations Deletes them From Input
  // useEffect(() => {
  //   return () => {
  //     dispatch(clear());
  //   };
  // }, []);

  const _selectRecommendation = (recommendationId: string) => {
    trackEvent({
      category: 'project',
      action: 'add-input:recommendation',
    });
    const recommendation = recommendations.find(
      (x) => x.recommendationId === recommendationId
    );
    if (recommendation !== undefined) {
      dispatch(
        addInput({
          key: recommendationId,
          input: { ...recommendation, results: {} },
        })
      );
      dispatch(selectRecommendation({ projectId, recommendationId }));
    }
  };
  const _removeInput = (recommendationId: string) => {
    try {
      dispatch(unselectRecommendation({ projectId, recommendationId }));
    } finally {
      dispatch(removeInput({ key: recommendationId }));
    }
  };

  type AddBulkDataInput = {
    parameters: Record<string, any>;
    results: Record<string, any>;
  };

  const addOwnBulkData = (inputs: AddBulkDataInput[]) => {
    trackEvent({
      category: 'project',
      action: 'add-bulk-data',
    });

    const dataWithIds = inputs.map((input) => ({ input, key: nanoid() }));

    dispatch(addBulkData(dataWithIds));
    return true;
  };
  const addOwnInput = (parameters: { [key: string]: any }) => {
    trackEvent({
      category: 'project',
      action: 'add-input:own',
    });
    const key = nanoid();
    dispatch(
      addInput({
        key,
        input: { parameters, results: {} },
      })
    );
    return true;
  };

  const updateParams = (key: string, paramName: string, value: string) => {
    dispatch(
      updateInputParams({
        key,
        paramName,
        value,
      })
    );
  };

  const _updateResults =
    ({ key, target }: { key: string; target: string }) =>
    (value: string) => {
      const floatVal = parseFloat(value);
      dispatch(
        updateResults({
          key,
          results: { [target]: isNaN(floatVal) ? undefined : floatVal },
        })
      );
    };

  const submit = useCallback(async () => {
    trackEvent({
      category: 'project',
      action: 'run-batch',
    });
    try {
      const { data, statusText, status } = await axios.post(
        `${apiEndpoint}/project/${projectId}/experiment`,
        Object.values(input),
        {
          headers: authHeader,
        }
      );
      assert(
        isCreateExperimentsResponse({
          statusCode: status,
          json: data,
        })
      );
      // dispatch(setProjectStatus({ projectId, status: ProjectStatus.running }));
      dispatch(setStatus(statusText));
      dispatch(clear());
      // dispatch(setRecommendations({ projectId, recommendations: [] }));
    } catch (err) {
      dispatch(setError((err as Error).message));
      dispatch(setStatus('error'));
    }
  }, [projectId, authHeader, input]);

  const exportRecommendations = (fileType: string) => {
    trackEvent({
      category: 'project',
      action: 'export-recommendations',
    });
    if (!recommendations || !recommendations.length) return;

    const parameterNames =
      project?.parameterSpace.map(({ parameterName }) => parameterName) || [];

    const targets = project?.targets || [];

    const targetNames = targets.map(({ targetName }) => targetName) || '';
    const emptyTargets = targets.reduce<Record<string, string>>(
      (acc, target) => {
        acc[target.targetName] = '';
        return acc;
      },
      {}
    );
    const headers = [...parameterNames, ...targetNames];

    const rows = recommendations.map((recommendation) => ({
      ...recommendation.parameters,
      ...emptyTargets,
    }));

    const fileTitleParts = [
      'experiments',
      project?.name,
      moment().format('YYYYMMDDHHmm'),
      'recommendations',
    ];

    if (fileType === SUPPORTED_FILE_TYPES.CSV) {
      exportCSVFile(headers, rows, `${fileTitleParts.join('_')}.csv`);
    } else if (fileType === SUPPORTED_FILE_TYPES.XLSX) {
      exportXlsxFile(headers, rows, `${fileTitleParts.join('_')}.xlsx`);
    }
  };

  const _recreateRecommendations = async (batchSize?: string) => {
    if (batchSize) {
      trackEvent({
        category: 'project',
        action: 'update:recreate-recmmendations',
      });
      // set on the project too
      await dispatch(
        recreateRecommendations({
          projectId,
          batchSize: parseInt(batchSize),
        })
      );

      dispatch(
        updateProject({
          id: projectId,
          updates: { batchSize },
        })
      );
    }
  };

  return {
    recommendations,
    input,
    recreateRecommendations: _recreateRecommendations,
    selectRecommendation: _selectRecommendation,
    addOwnInput,
    removeInput: _removeInput,
    updateResults: _updateResults,
    submit,
    addOwnBulkData,
    clearInputs: () => dispatch(clear()),
    exportRecommendations,
    updateParams,
  };
};
export { useRecommendations as default };
