import { LdButton, LdIcon, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import { FC, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  CustomParameterValue,
  Parameter,
  ParameterType,
  ParameterValue,
  Project,
  SmilesParameterValue,
} from 'types/api/types';

type ParametersPanelProps = {
  selectedProject: Project;
  isEditing: boolean;
  openExpandModal: (parameter?: Parameter) => void;
  newParameterValues: (parameterName: string) => ParameterValue[];
  getToolTipTextById: (id: string) => string | undefined;
  newParameters: Record<string, Parameter>;
};

const ParametersPanel: FC<ParametersPanelProps> = ({
  getToolTipTextById,
  newParameterValues,
  openExpandModal,
  selectedProject,
  isEditing,
  newParameters,
}) => {
  const combinedParameters = useMemo(() => {
    return [...selectedProject.parameterSpace, ...Object.values(newParameters)];
  }, [selectedProject.parameterSpace, newParameters]);

  return (
    <div className="p-ld-24 gap-y-2 grid grid-cols-4">
      {combinedParameters.map((parameter) => [
        <div key={parameter.parameterName} className="grid">
          <LdTypo variant="label-m">
            <span className="font-normal">{parameter.parameterName}</span>
          </LdTypo>
        </div>,
        <div
          key={`${parameter.parameterName}_values`}
          className="flex col-span-3 flex-col"
        >
          {parameter.parameterType === ParameterType.custom ? (
            <div className="flex flex-row items-center">
              <span>
                {parameter.parameterValues
                  .map((row: CustomParameterValue) => Object.values(row)[0])
                  .join(', ')}
              </span>
              {isEditing && (
                <LdButton
                  size="sm"
                  className="inline-block"
                  mode="ghost"
                  onClick={() => openExpandModal(parameter)}
                >
                  <LdIcon name="pen" />
                </LdButton>
              )}
            </div>
          ) : (
            <div className="flex flex-row">
              <span className="leading-loose">
                {parameter.parameterType === ParameterType.continuous
                  ? `Lower Bound: ${parameter.parameterValues[0]}, Upper Bound:${parameter.parameterValues[1]}`
                  : (newParameterValues(parameter.parameterName).length
                      ? newParameterValues(parameter.parameterName)
                      : parameter.parameterValues
                    )
                      .map((val) => {
                        return (
                          (val as SmilesParameterValue).Name || val.toString()
                        );
                      })
                      .join(', ')}
              </span>
              <span className="flex flex-col leading-loose ml-3">
                {parameter.parameterType === ParameterType.task &&
                  (parameter.activeValues != undefined
                    ? '|  Active values: ' + parameter.activeValues?.join(', ')
                    : '|  No active values.')}
              </span>
              {isEditing && (
                <LdButton
                  size="sm"
                  className="inline-block"
                  mode="ghost"
                  onClick={() => openExpandModal(parameter)}
                >
                  <LdIcon name="pen" />
                </LdButton>
              )}
            </div>
          )}
        </div>,
      ])}
      {isEditing && (
        <>
          <LdButton
            size="sm"
            className="inline-block"
            mode="ghost"
            onClick={() => openExpandModal()}
            data-tip={getToolTipTextById('Spc_2')}
            data-for="add-parameter-tooltip"
          >
            <LdIcon name="add" />
            Add Parameter
          </LdButton>
          <ReactTooltip
            id="add-parameter-tooltip"
            type="light"
            effect="solid"
            className="tooltip"
          />
        </>
      )}
    </div>
  );
};

export { ParametersPanel };
