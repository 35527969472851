/* eslint-disable import/named */
import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

export interface ModalState {
  open: { [key: string]: boolean };
  current?: string;
  state: { [key: string]: any };
}

export const initialState: ModalState = {
  open: {},
  state: {},
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    reset: (state: WritableDraft<ModalState>) => {
      Object.assign(state, initialState);
    },
    closeModal: (
      state: WritableDraft<ModalState>,
      action: {
        payload: string;
      }
    ) => {
      const { payload: modalName } = action;
      state.open[modalName] = false;
      delete state.state[modalName];
      if (state.current === modalName) {
        state.current = undefined;
      }
    },
    openModal: (
      state: WritableDraft<ModalState>,
      action: {
        payload: { modalName: string; modalState?: any };
      }
    ) => {
      const { modalName, modalState } = action.payload;
      state.open[modalName] = true;
      state.current = modalName;
      if (modalState) {
        state.state[modalName] = state;
      }
    },
  },
});

export const { reset, openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
