/* eslint-disable import/named */
import {
  createAsyncThunk,
  createSlice,
  AnyAction,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { WritableDraft } from 'immer/dist/internal';
import moment from 'moment';
import {
  ChangeHistoryRequestJson,
  Experiment,
  ExperimentResults,
  ParameterRecommendation,
  TargetVariable,
} from 'types/api/types';
import { StateStatus } from 'types/app/types';
import { get, post, put } from 'util/api';
import { exportCSVFile } from 'util/csv';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

export interface ExperimentsState {
  experiments: { [key: string]: Experiment[] };
  // originalHistory?: Experiment[];
  // deletions: { [key: string]: Experiment };
  // modifications: { [key: string]: Experiment };
  sortBy: string;
  order: 'asc' | 'desc';
  status: StateStatus;
  error: string | null;
}

export const initialState: ExperimentsState = {
  experiments: {},
  // deletions: {},
  // modifications: {},
  sortBy: 'experimentId',
  order: 'asc',
  status: StateStatus.idle,
  error: null,
};

export const listExperimentsForProject = createAsyncThunk(
  'experiments/listExperimentsForProject',
  async (projectId: string) => {
    const data = await get(`/project/${projectId}/experiment`);
    return {
      projectId,
      data,
    };
  }
);

export const createExperiments = createAsyncThunk(
  'experiments/createExperiments',
  async ({
    projectId,
    body,
  }: {
    projectId: string;
    body: {
      recommendationId?: string;
      results: ExperimentResults;
      parameters: ParameterRecommendation;
    }[];
  }) => {
    const data = await post(`/project/${projectId}/experiment`, body);
    return {
      projectId,
      data,
    };
  }
);

export const changeHistory = createAsyncThunk(
  'experiments/changeHistory',
  async ({
    projectId,
    body,
  }: {
    projectId: string;
    body: ChangeHistoryRequestJson;
  }) => {
    const data = await put(`/project/${projectId}/experiment`, body);
    return {
      projectId,
      data,
    };
  }
);

function updateItem(
  state: WritableDraft<ExperimentsState>,
  action: { payload: { projectId: string; experimentId: string; updates: any } }
) {
  const { projectId, experimentId, updates } = action.payload;
  const match = state.experiments[projectId].find(
    (experiment) => experiment.experimentId === experimentId
  );
  if (match !== undefined) {
    Object.assign(match, updates);
  }
}

function isPendingAction(action: AnyAction): action is PendingAction {
  return (
    action.type.endsWith('/pending') && action.type.startsWith('experiments/')
  );
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    action.type.endsWith('/rejected') && action.type.startsWith('experiments/')
  );
}

export const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    changeOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    setSortyBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setExperiments: (
      state,
      action: PayloadAction<{ projectId: string; experiments: Experiment[] }>
    ) => {
      const { projectId, experiments } = action.payload;
      state.experiments[projectId] = experiments;
    },
    addExperiment: (
      state,
      action: PayloadAction<{ projectId: string; experiments: Experiment }>
    ) => {
      const { projectId, experiments } = action.payload;

      if (!state.experiments[projectId]) state.experiments[projectId] = [];
      const exist = state.experiments[projectId].find(
        (e) => e.experimentId == experiments.experimentId
      );
      if (!exist) state.experiments[projectId].push(experiments);
    },
    updateExperiment: updateItem,
    exportExperiments(
      state: WritableDraft<ExperimentsState>,
      action: { payload: { projectId: string; targets: TargetVariable[] } }
    ) {
      const list = state.experiments[action.payload.projectId];
      if (!list || !list.length) return;
      const first = list[0];
      const headers = [
        'Batch',
        ...Object.keys(first.parameters),
        ...action.payload.targets.map(({ targetName }) => targetName),
      ];
      const rows = list.map((experiment) => ({
        ...experiment.parameters,
        Batch: experiment.batch,
        ...experiment.results,
      }));
      exportCSVFile(
        headers,
        rows,
        `experiments_${first.projectId}_${moment().format('YYYYMMDDHHmm')}.csv`
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      listExperimentsForProject.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: Experiment[] } }
      ) => {
        state.status = StateStatus.succeeded;
        state.experiments[payload.projectId] = payload.data;
      }
    );
    builder.addCase(
      createExperiments.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: Experiment[] } }
      ) => {
        state.status = StateStatus.succeeded;
        state.experiments[payload.projectId].push(...payload.data);
      }
    );
    builder.addCase(
      changeHistory.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: Experiment[] } }
      ) => {
        state.status = StateStatus.succeeded;
        state.experiments[payload.projectId] = payload.data;
      }
    );
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export const selectExperimentsForProject =
  (projectId: string) =>
  ({
    experiments: { experiments, order, sortBy },
  }: {
    experiments: ExperimentsState;
  }) => {
    try {
      return _.orderBy(experiments[projectId], [sortBy], [order]);
    } catch {
      return [];
    }
  };

export const {
  updateExperiment,
  exportExperiments,
  changeOrder,
  setSortyBy,
  setExperiments,
  addExperiment,
} = experimentsSlice.actions;
export default experimentsSlice.reducer;
