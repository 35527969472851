import React from 'react';
import { LdIcon, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import { Link } from 'react-router-dom';

interface Props {
  projectId?: string;
  projectName?: string;
}

const Breadcrumbs: React.FC<Props> = ({ projectId, projectName }) => {
  return (
    <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
      <Link to={`/projects`} className="flex hover:underline">
        <LdTypo variant="body-s">My Projects</LdTypo>
      </Link>
      <LdIcon name="arrow-right" size="sm" />
      <Link to={`/projects/${projectId}`} className="flex hover:underline">
        <LdTypo variant="body-s">{projectName || projectId}</LdTypo>
      </Link>
    </div>
  );
};

export { Breadcrumbs as default };
