import {
  LdOption,
  LdInput,
  LdSelect,
} from '@emdgroup-liquid/liquid/dist/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  CustomParameterValue,
  Parameter,
  ParameterType,
  ParameterValue,
  SmilesParameterValue,
} from 'types/api/types';
import type {
  LdInputCustomEvent,
  LdSelectCustomEvent,
} from '@emdgroup-liquid/liquid';
import { nanoid } from 'nanoid';

interface Props {
  parameter: Parameter;
  touched?: boolean;
  selected?: string;
  onChange: (p: { value: string; isNew?: boolean }) => void;
}

export var ParameterInputSelection: React.FC<Props> = ({
  parameter,
  // touched,
  selected,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  useEffect(() => {
    setSelectedValue(selected);
  }, [selected]);

  const getLabel = (value: ParameterValue) => {
    let label: string;
    switch (parameter.parameterType) {
      case ParameterType.smiles:
        label = (value as SmilesParameterValue).Name;
        break;
      case ParameterType.custom:
        label = Object.values(value as CustomParameterValue)[0];
        break;
      default:
        label = value.toString();
    }
    return label;
  };

  const selectRef = useRef<HTMLLdSelectElement | null>(null);

  const handleValueChange = React.useCallback(
    (event: LdSelectCustomEvent<string[]>) => {
      setSelectedValue(event.detail[0]);
      onChange({
        value: event.detail[0],
        isNew: false,
      });
    },
    [onChange]
  );

  const handleInputValueChange = React.useCallback(
    (event: LdInputCustomEvent<string>) => {
      const value = event.detail;
      if (value === undefined || isNaN(Number(value))) return;
      setSelectedValue(value);
      onChange({
        value,
        isNew: false,
      });
    },
    [onChange]
  );

  const onChangeInput = (event: LdSelectCustomEvent<string>) => {
    const value = event.detail;
    if (value === undefined || isNaN(Number(value))) return;
    onChange({ value, isNew: true });
    setSelectedValue(value);
  };

  const nanoId = nanoid();

  // if (parameter.parameterType === ParameterType.numeric) {
  //   return (
  //     <span>
  //       <LdSelect
  //         filter
  //         creatable
  //         filterPlaceholder="Custom Value"
  //         size="sm"
  //         ref={selectRef}
  //         onLdinput={handleValueChange}
  //         onLdoptioncreate={onChangeInput}
  //         name={parameter.parameterName}
  //         required
  //         class="own-experiment-parameter-input max-w-[14rem]"
  //         placeholder={`${parameter.parameterName}`}
  //         mode="ghost"
  //         key={`select_${nanoId}`}
  //         id={`select_${nanoId}`}
  //       >
  //         {parameter.parameterValues.map((val) => {
  //           const label = getLabel(val);
  //           if (typeof label !== 'string') return null;
  //           return (
  //             <LdOption
  //               selected={selectedValue === label}
  //               value={label}
  //               key={`${parameter.parameterName}_option_${label}`}
  //             >
  //               {label}
  //             </LdOption>
  //           );
  //         })}
  //       </LdSelect>
  //     </span>
  //   );
  // }

  if (
    parameter.parameterType === ParameterType.continuous ||
    parameter.parameterType === ParameterType.numeric
  ) {
    return (
      <span>
        <LdInput
          size="sm"
          onLdchange={handleInputValueChange}
          name={parameter.parameterName}
          value={selectedValue}
          required
          type="number"
          class="own-experiment-parameter-input max-w-[14rem]"
          placeholder={`${parameter.parameterName}`}
          key={`select_${nanoId}`}
          id={`select_${nanoId}`}
        />
      </span>
    );
  }

  return (
    <span>
      <LdSelect
        size="sm"
        ref={selectRef}
        onLdinput={handleValueChange}
        name={parameter.parameterName}
        required
        class="own-experiment-parameter-input max-w-[14rem]"
        placeholder={`${parameter.parameterName}`}
        mode="ghost"
        key={`select_${nanoId}`}
        id={`select_${nanoId}`}
      >
        {parameter.parameterValues.map((val) => {
          const label = getLabel(val);
          if (typeof label !== 'string') return null;
          return (
            <LdOption
              selected={selectedValue === label}
              value={label}
              key={`${parameter.parameterName}_option_${label}`}
            >
              {label}
            </LdOption>
          );
        })}
      </LdSelect>
    </span>
  );
};
