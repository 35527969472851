import * as XLSX from 'xlsx';

export const readXlsxFile = (file: File): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = e?.target?.result;
        const workbook = XLSX.read(data, { type: 'binary' });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const result = XLSX.utils.sheet_to_json(sheet);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsBinaryString(file);
  });
};

export const exportXlsxFile = (
  headers: string[],
  rows: any[], // rows = list of header objects
  exportedFilename: string
): void => {
  const data = rows.map((r: any) => {
    const row: string[] = [];

    headers.forEach((h) => {
      row.push(r[h]);
    });

    return row;
  });
  data.unshift(headers);

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFileXLSX(wb, exportedFilename, {
    bookType: 'xlsx',
  });
};
