import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Theme } from '@liquid-design/liquid-design-react';
import { UserContextProvider } from '@emdgroup/react-auth';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';

import { instance } from 'services/matomo';
import Pages from './pages';
import store from 'store';

const App: FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <UserContextProvider
          autoLogin
          domainHint="merckgroup.com"
          clientId={process.env.REACT_APP_CLIENT_ID || 'NbIxOQoWfrXO_SSQofn8kQ'}
        >
          <MatomoProvider value={instance}>
            <Theme
              className="inset-0 flex flex-col max-h-full"
              themeName="vibrantMagenta"
            >
              <Pages />
            </Theme>
          </MatomoProvider>
        </UserContextProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
