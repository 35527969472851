import React from 'react';
import { LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import AppLayout from 'layouts/AppLayout';
import { Link } from 'react-router-dom';
import changelog from 'assets/data/changelog.json';

type ChangelogItem = {
  version: string;
  date?: string;
  bulletPoints: string[];
};

const ChangelogPage: React.FC<any> = () => {
  const data = changelog as ChangelogItem[];

  return (
    <AppLayout>
      <div className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start w-full max-h-screen min-h-screen pt-8 pl-4">
          <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
            <Link to={`/projects`} className="flex hover:underline">
              <LdTypo variant="h1">Changelog</LdTypo>
            </Link>
          </div>

          <ol className="relative border-l border-l-rich-black-lightest border-l-2 ml-2">
            {data.map((item) => {
              return (
                <li key={item.date} className="mb-10 ml-4">
                  <div className="absolute w-4 h-4 bg-vibrant-cyan rounded-full mt-1 -left-2"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400">
                    {item.date}
                  </time>
                  {item.version && (
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">
                      {item.version}
                    </h3>
                  )}
                  <ul className="mb-4 ml-4 text-base font-normal d-list">
                    {item.bulletPoints.map((b, i) => {
                      return (
                        <li
                          key={`${i}-changelog`}
                          className={`flex pb-2 text-rich-black-lighter`}
                        >
                          <div className="">&#8594; {b}</div>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </AppLayout>
  );
};

export default ChangelogPage;
