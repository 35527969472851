import React from 'react';
import { LdCard, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import SmilesDrawer from 'smiles-drawer';
import { SmilesParameterValue } from '../../types/api/types';
import { hashCode } from '../../util/utils';

type Props = {
  id: string;
  values: SmilesParameterValue[];
};

export const SmilesParameterTable = ({ values, id }: Props) => {
  const smilesDrawer = new SmilesDrawer.Drawer({ width: 200, height: 180 });

  return (
    <div className="max-h-[40rem] grid grid-cols-4 gap-2">
      {values.map((row: SmilesParameterValue, index) => (
        <LdCard
          shadowInteractive="active"
          key={`smiles-card-${hashCode(row['SMILES'])}`}
        >
          <canvas id={`output-${id}-${index}`} width="200" height="180" />
          {SmilesDrawer.parse(
            row['SMILES'],
            (tree: any) => {
              smilesDrawer.draw(tree, `output-${id}-${index}`);
            },
            function (err: any) {
              console.log(err);
            }
          )}
          <LdTypo>{row['Name']}</LdTypo>
        </LdCard>
      ))}
    </div>
  );
};
