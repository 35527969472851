import { createAsyncThunk } from '@reduxjs/toolkit';
import { Recommendation } from 'types/api/types';
import { get, del, post } from 'util/api';
import { getProjectById } from 'services/projects';

export const listRecommendationsForProject = createAsyncThunk(
  'recommendations/listRecommendationsForProject',
  async (payload: { projectId: string; batch?: number }) => {
    const { projectId, batch } = payload;
    const url = `/project/${projectId}/recommendation?batch=${
      batch || 'latest'
    }`;
    const data = await get(url);
    return {
      projectId,
      data: (data as Recommendation[]).map((recommendation) => ({
        ...recommendation,
        isSelected: false,
      })),
    };
  }
);

export const rejectRecommendation = createAsyncThunk(
  'recommendations/rejectRecommendation',
  async ({
    projectId,
    recommendationId,
  }: {
    projectId: string;
    recommendationId: string;
  }) => {
    await del(`/project/${projectId}/recommendation/${recommendationId}`);
    return {
      projectId,
      recommendationId,
    };
  }
);

export const recreateRecommendations = createAsyncThunk(
  'recommendations/recreateRecommendations',
  async ({
    projectId,
    batchSize,
  }: {
    projectId: string;
    batchSize: number;
  }) => {
    return post(`/project/${projectId}/recommendation`, {
      batchSize,
    });
  }
);
