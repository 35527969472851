import { useSearchParams } from 'react-router-dom';
import useApi from 'hooks/useApi';
import { useMemo } from 'react';
import { Project } from 'types/api/types';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT.replace(/\/+$/, '')
  : '';

export const useProjectToClone = () => {
  const [searchParams] = useSearchParams();
  const projectIdToClone = searchParams.get('cloneProject');

  const projectApi = useApi<Project>(
    projectIdToClone ? 'GET' : null,
    projectIdToClone ? `${apiEndpoint}/project/${projectIdToClone}` : ''
  );

  return useMemo(() => {
    if (!projectApi.response) {
      return undefined;
    }

    return {
      ...projectApi.response,
      name: `${projectApi.response.name} Copy`,
    };
  }, [projectApi.response]);
};
