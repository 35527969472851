import { useEffect, useMemo, useState } from 'react';
import type { QueryStatus } from '@emdgroup/react-query';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  selectExperimentsForProject,
  setExperiments,
} from 'services/experiments';
import {
  closeProject,
  reopenProject,
  setProject,
  updateParameter,
  selectConstraintsForProject,
  listConstraintsForProject,
} from 'services/projects';
import {
  setRecommendations,
  selectRecommendationsForProject,
} from 'services/recommendations';
import { useDispatch, useSelector } from 'store';
import {
  Experiment,
  Project,
  Recommendation,
  Parameter,
} from 'types/api/types';
import useApi from 'hooks/useApi';
import _ from 'lodash';
import useWebsocket from 'hooks/useWebsocket';
import { isBusy as isProjectBusy } from 'util/project';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT.replace(/\/+$/, '')
  : '';

const useProjectDetails = (projectId: string) => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const experiments = useSelector(selectExperimentsForProject(projectId));
  const constraints = useSelector(selectConstraintsForProject(projectId));

  const projectApi = useApi<Project>(
    'GET',
    `${apiEndpoint}/project/${projectId}`
  );
  const experimentApi = useApi<Experiment[]>(
    'GET',
    `${apiEndpoint}/project/${projectId}/experiment`
  );
  const recommendationsApi = useApi<Recommendation[]>(
    'GET',
    `${apiEndpoint}/project/${projectId}/recommendation?batch=latest`
  );

  const [error, setError] = useState<Error>();
  const [status, setStatus] = useState<QueryStatus>('idle');

  const project = useSelector(({ projects }) => {
    try {
      return projects.projects[projectId];
    } catch {
      return undefined;
    }
  });

  const { loading } = useWebsocket({ projectId });

  const recommendations = useSelector(
    selectRecommendationsForProject(projectId)
  );

  useEffect(() => {
    if (experimentApi.status === 'success' && experimentApi.response) {
      dispatch(
        setExperiments({ projectId, experiments: experimentApi.response })
      );
    }
  }, [experimentApi.status, experimentApi.response, projectId]);

  useEffect(() => {
    if (
      recommendationsApi.status === 'success' &&
      recommendationsApi.response
    ) {
      dispatch(
        setRecommendations({
          projectId,
          recommendations: recommendationsApi.response,
        })
      );
    }
  }, [recommendationsApi.status, recommendationsApi.response, projectId]);

  useEffect(() => {
    if (projectApi.error) {
      setError(projectApi.error);
    } else if (recommendationsApi.error) {
      setError(recommendationsApi.error);
    } else if (experimentApi.error) {
      setError(experimentApi.error);
    } else {
      setError(undefined);
    }
  }, [experimentApi.error, projectApi.error, recommendationsApi.error]);

  useEffect(() => {
    const combinedStatus = [
      experimentApi.status,
      projectApi.status,
      recommendationsApi.status,
    ];
    if (combinedStatus.includes('loading')) {
      setStatus('loading');
    } else if (combinedStatus.includes('error')) {
      setStatus('error');
    } else if (combinedStatus.includes('success')) {
      setStatus('success');
    } else {
      setStatus('idle');
    }
  }, [experimentApi.status, projectApi.status, recommendationsApi.status]);

  const isBusy = useMemo(() => {
    return project && isProjectBusy(project);
  }, [project, loading]);

  useEffect(() => {
    if (projectApi.status === 'success' && projectApi.response) {
      dispatch(setProject(projectApi.response));
    }
  }, [projectApi.status, projectApi.response, projectId]);

  const isLoading = useMemo(() => status === 'loading', [status]);

  useEffect(() => {
    if (!constraints.length) dispatch(listConstraintsForProject(projectId));
  }, [projectApi.status]);

  const close = () => {
    trackEvent({
      category: 'project',
      action: 'close-project',
    });
    return dispatch(closeProject(projectId));
  };

  const reopen = () => {
    trackEvent({
      category: 'project',
      action: 'reopen-project',
    });
    return dispatch(reopenProject(projectId));
  };

  const refresh = () => {
    projectApi.revalidate();
    recommendationsApi.revalidate();
    experimentApi.revalidate();
  };

  const _updateParameter = (projectId: string, parameter: Parameter) => {
    return dispatch(updateParameter({ projectId, parameter }));
  };

  return {
    constraints,
    isBusy,
    isLoading,
    status,
    error,
    project,
    experiments,
    recommendations,
    close,
    reopen,
    refresh,
    updateParameter: _updateParameter,
  };
};

export { useProjectDetails as default };
