import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Hexagon } from 'assets/static/images/hexagon.svg';
import './styles.scss';

interface Props {
  allowedTypes: string[];
  multiple?: boolean;
  onDrop: (files: File[]) => void;
  className?: string;
}

const UploadDropzone: React.FC<Props> = ({
  allowedTypes,
  onDrop,
  multiple = false,
  className,
}) => {
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    onDrop(acceptedFiles);
  }, []);
  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: allowedTypes,
    multiple,
  });

  return (
    <div
      className={`drop-zone drop-zone-wrapper ${
        isDragActive && 'dragging'
      } ${className}`}
    >
      <div className="" {...getRootProps()}>
        <input {...getInputProps()} />
        <label className="upload__drop">
          <div
            data-test="upload-dropzone-label"
            className="flex flex-col items-center p-ld-16 text-center cursor-pointer"
          >
            <Hexagon className="mb-3 stroke-current text-rich-purple"></Hexagon>
            <p>
              Please drag & drop your file
              {multiple ? 's' : ''} into the window{' '}
              <span className="upload__drop-content-link">
                or click to select {multiple ? '' : 'a '}file
                {multiple ? 's' : ''}.
              </span>
            </p>
          </div>
        </label>
      </div>
    </div>
  );
};

export default UploadDropzone;
