import { useHints } from 'hooks/useHints';
import _ from 'lodash';
import React, { useMemo } from 'react';
import {
  CustomParameterValue,
  Experiment,
  Parameter,
  ParameterType,
  SmilesParameterValue,
  TargetVariable,
} from 'types/api/types';
import { TargetObjective } from 'types/app/types';
import {
  closestBy,
  geomMean,
  getResult,
  linearTransform,
  triangularTransform,
} from 'util/experiments';
import ParamChart from './Chart';

interface Props {
  experiments: Experiment[];
  selectedTarget?: TargetVariable;
  selectedTargetName: string;
  selectedParam?: Parameter;
  selectedParamName: string;
  targets?: TargetVariable[];
}

const BatchPlot: React.FC<Props> = ({
  experiments,
  selectedTarget,
  selectedTargetName,
  selectedParam,
  selectedParamName,
  targets,
}) => {
  const { getToolTipById } = useHints();

  const sortedLabels = useMemo(() => {
    if (selectedParamName === 'batch') {
      return experiments.map((exp) => exp.batch);
    }
    if (!selectedParam) {
      return [];
    }
    let labels: any[];
    switch (selectedParam.parameterType) {
      case 'smiles':
        labels = selectedParam.parameterValues
          .map((value) => (value as SmilesParameterValue).Name)
          .sort();
        break;
      case 'task':
      case 'category':
        labels = selectedParam.parameterValues
          .map((value) => value.toString())
          .sort();
        break;
      case 'custom':
        labels = selectedParam.parameterValues.map(
          (value) => Object.values(value as CustomParameterValue)[0]
        );
        break;
      default:
        labels = selectedParam.parameterValues;
    }
    return labels;
  }, [experiments, selectedParam, selectedParamName]);

  const scatterData = useMemo(() => {
    if (
      selectedParam?.parameterType === ParameterType.continuous ||
      selectedParam?.parameterType === ParameterType.numeric
    ) {
      const data = experiments
        .map((experiment) => {
          const xValue = Number(
            (experiment.parameters as Record<string, any>)[selectedParamName]
          );

          const sum = targets
            ?.map((target) => target.targetWeight)
            .reduce((prev = 0, curr = 0) => prev + curr, 0);

          const inverseSum = 1 / (sum || 1);

          const scaledValues = targets?.map((target) => {
            if (experiment) {
              const originValue = (experiment.results as Record<string, any>)[
                target.targetName
              ];
              switch (target.targetObjective) {
                case 'Min':
                  const resMin = linearTransform(
                    originValue,
                    target.lowerBound!,
                    target.upperBound!,
                    target.targetWeight!,
                    true
                  );
                  return resMin;
                case 'Max':
                  const resMax = linearTransform(
                    originValue,
                    target.lowerBound!,
                    target.upperBound!,
                    target.targetWeight!,
                    false
                  );
                  return resMax;
                case 'Match':
                  const resMatch = triangularTransform(
                    originValue,
                    target.lowerBound!,
                    target.upperBound!,
                    target.targetWeight!
                  );
                  return resMatch;
                default:
                  return 0;
              }
            }
            return 0;
          });

          const yValue =
            selectedTargetName === 'desirability'
              ? geomMean(scaledValues, inverseSum)
              : experiment
              ? (experiment.results as Record<string, any>)[selectedTargetName]
              : undefined;
          if (yValue === undefined) {
            return null;
          }
          return {
            x: xValue,
            y: yValue,
          };
        })
        .filter((item) => item !== null);
      return _.orderBy(data, ['x'], ['asc']);
    }

    if (selectedParamName === 'batch' && selectedTargetName != 'desirability') {
      const data = experiments
        .map(({ batch, results }) => ({
          x: batch,
          y: getResult(results, selectedTargetName),
        }))
        .filter(({ y }) => y !== undefined);
      return _.orderBy(data, ['x'], ['asc']);
    }
    if (!sortedLabels) {
      return [];
    }

    const data = sortedLabels
      .flatMap((label, index) => {
        const xValue =
          selectedParamName === 'batch' ||
          selectedParam?.parameterType === ParameterType.numeric ||
          selectedParam?.parameterType === ParameterType.continuous
            ? label
            : index + 1;

        const matchingExperiments = experiments.filter((exp) => {
          if (selectedParamName === 'batch') {
            return exp.batch === label;
          }
          if (
            selectedParam?.parameterType === ParameterType.numeric ||
            selectedParam?.parameterType == ParameterType.continuous
          ) {
            return Number((exp.parameters as any)[selectedParamName]) === label;
          }
          return (exp.parameters as any)[selectedParamName] === label;
        });

        const sum = targets
          ?.map((target) => target.targetWeight)
          .reduce((prev = 0, curr = 0) => prev + curr, 0);

        const inverseSum = 1 / (sum || 1);

        return matchingExperiments.map((experiment) => {
          const scaledValues = targets?.map((target) => {
            if (experiment) {
              const originValue = (experiment.results as Record<string, any>)[
                target.targetName
              ];
              switch (target.targetObjective) {
                case 'Min':
                  const resMin = linearTransform(
                    originValue,
                    target.lowerBound!,
                    target.upperBound!,
                    target.targetWeight!,
                    true
                  );
                  return resMin;
                case 'Max':
                  const resMax = linearTransform(
                    originValue,
                    target.lowerBound!,
                    target.upperBound!,
                    target.targetWeight!,
                    false
                  );
                  return resMax;
                case 'Match':
                  const resMatch = triangularTransform(
                    originValue,
                    target.lowerBound!,
                    target.upperBound!,
                    target.targetWeight!
                  );
                  return resMatch;
                default:
                  return 0;
              }
            }
            return 0;
          });

          const yValue =
            selectedTargetName === 'desirability'
              ? geomMean(scaledValues, inverseSum)
              : experiment
              ? (experiment.results as Record<string, any>)[selectedTargetName]
              : undefined;
          if (yValue === undefined) {
            return null;
          }
          return {
            x: xValue,
            y: yValue,
          };
        });
      })
      .filter((item) => item !== null);

    return data;
  }, [
    selectedParamName,
    selectedTargetName,
    sortedLabels,
    experiments,
    selectedParam?.parameterType,
    targets,
  ]);

  const lineData = useMemo(() => {
    const grouped = _.groupBy(experiments, 'batch');
    if (selectedTarget?.targetObjective === 'Match') {
      return Object.keys(grouped)
        .map((batch) => ({
          batch: parseInt(batch),
          result: closestBy(
            grouped[batch],
            selectedTargetName,
            (selectedTarget.lowerBound! + selectedTarget.upperBound!) / 2
          ),
        }))
        .filter(({ result }) => result !== undefined);
    }

    const optBy = selectedTarget?.targetObjective === 'Min' ? _.minBy : _.maxBy;

    return Object.keys(grouped)
      .map((batch) => {
        let result: number | undefined = undefined;
        const optResult = optBy(
          grouped[batch],
          `results.${selectedTargetName}`
        );
        if (optResult !== undefined) {
          result = getResult(optResult.results, selectedTargetName);
        }
        return {
          batch: parseInt(batch),
          result,
          selectedTarget,
        };
      })
      .filter(({ result }) => result !== undefined);
  }, [experiments, selectedTargetName, selectedTarget]);

  const arithmeticMeanData = useMemo(() => {
    if (selectedTarget?.targetObjective === TargetObjective.Match) {
      const mean =
        (selectedTarget.lowerBound! + selectedTarget.upperBound!) / 2;
      return mean;
    }
    return undefined;
  }, [selectedTarget]);
  return (
    <div className="h-[300px] relative">
      <div className="mt-12 z-10 right-16 absolute">
        {getToolTipById('Exp_1')}
      </div>
      <ParamChart
        selectedTargetName={selectedTargetName}
        selectedTarget={selectedTarget}
        lineData={lineData}
        scatterData={scatterData}
        selectedParam={selectedParam}
        selectedParamName={selectedParamName}
        sortedLabels={sortedLabels}
        arithmeticMeanData={arithmeticMeanData}
      />
    </div>
  );
};
export default BatchPlot;
