import { FC, useEffect, useState } from 'react';
import AppLayout from '../../layouts/AppLayout';
import SpaceLoader from 'components/ChemicalSpace/SpaceLoader';
import { useAuthentication } from 'services/user';
import { useDispatch, useSelector } from 'store';
import {
  getArchivedProjects,
  getActiveProjects,
  setSortyBy,
  changeOrder,
  setShowClosed,
  listProjects,
} from 'services/projects';
import { StateStatus, RoleFilter } from 'types/app/types';
import Header from './components/Header';
import ProjectItem from './components/ProjectItem';
import Breadcrumbs from './components/Breadcrumbs';
import { openModal } from 'services/modals';
import Disclaimer from 'layouts/Disclaimer';
import useClientinfo from 'hooks/useClientinfo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useFilteredProjects } from './hooks/useFilteredProjects';
import useNotice from 'hooks/useNotice';

interface Props {
  showFeedback?: boolean;
}

const MyProjects: FC<Props> = ({ showFeedback }) => {
  const dispatch = useDispatch();
  const user = useAuthentication();
  const projects = useSelector(getActiveProjects);
  const closedProjects = useSelector(getArchivedProjects);
  const { status, showClosed } = useSelector((state) => state.projects);
  const [filterByRole, setFilterByRole] = useState<RoleFilter>();

  const { isChrome } = useClientinfo();

  const [query, setQuery] = useState<string>();

  const isLoading = status === StateStatus.loading;

  const { trackPageView } = useMatomo();

  const { showNotice } = useNotice();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Project List',
    });
  }, []);

  useEffect(() => {
    dispatch(listProjects());
  }, [user.info?.sub]);

  useEffect(() => {
    if (showFeedback) {
      dispatch(openModal({ modalName: 'feedback' }));
    }
  }, [showFeedback]);

  const handleChangeSorting = ({
    orderBy,
    order,
  }: {
    order: 'asc' | 'desc';
    orderBy?: string;
  }) => {
    dispatch(changeOrder(order));
    if (orderBy !== undefined) {
      dispatch(setSortyBy(orderBy));
    }
  };

  const handleToggleShowClosed = () => {
    dispatch(setShowClosed(!showClosed));
  };

  const filteredClosedProjects = useFilteredProjects(closedProjects, {
    role: filterByRole,
    query,
  });

  const filteredProjects = useFilteredProjects(projects, {
    role: filterByRole,
    query,
  });

  const sortingOptions = filteredProjects.at(0) || {};

  return (
    <AppLayout>
      <div
        id="database"
        className="relative flex flex-row max-w-[1024px] mx-auto overflow-hidden"
      >
        <div className="relative flex flex-col items-start justify-center w-full max-h-screen min-h-screen pt-8 database__mainContainer">
          {!isChrome && <Disclaimer />}
          {showNotice()}
          <div className="pl-4">
            <Breadcrumbs />
          </div>
          <Header
            onSearch={setQuery}
            showClosed={showClosed}
            handleToggleShowClosed={handleToggleShowClosed}
            handleChangeSorting={handleChangeSorting}
            sortingOptions={sortingOptions}
            filterByRole={filterByRole}
            onFilterByRoleChange={setFilterByRole}
          />
          <div
            data-test="project-list-container"
            className="flex gap-4 flex-col items-center justify-start flex-grow w-full px-4 pb-6 overflow-y-auto database__content"
          >
            {filteredProjects.map(({ projectId, ...rest }) => (
              <ProjectItem key={projectId} project={{ projectId, ...rest }} />
            ))}
            {showClosed &&
              filteredClosedProjects.map(({ projectId, ...rest }) => {
                return (
                  <ProjectItem
                    key={projectId}
                    project={{ projectId, ...rest }}
                  />
                );
              })}
            {isLoading && (
              <>
                <SpaceLoader />
                {projects.length === 0 && <SpaceLoader />}
              </>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default MyProjects;
