const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT.replace(/\/+$/, '')
  : '';

export const REQUEST_OPTIONS: () => RequestInit = () => ({
  mode: 'cors' as const, // no-cors, *cors, same-origin
  cache: 'no-cache' as const, // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin' as const, // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json',
    Authorization: sessionStorage.getItem('accessToken') || '',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: 'follow' as const, // manual, *follow, error
  referrerPolicy: 'no-referrer' as const,
});

export const request = (
  path = '',
  method: string,
  data?: any,
  options?: any
) => {
  let init: RequestInit = {
    ...REQUEST_OPTIONS(),
    method,
  };
  if (data !== undefined) {
    init.body = JSON.stringify(data);
  }
  if (options !== undefined) {
    init = { ...init, ...options };
  }
  return fetch(`${apiEndpoint}/${path.replace(/^\//, '')}`, init);
};

export function get(path = '') {
  return request(path, 'GET').then(async (response) => {
    if (response.ok) {
      return response.json();
    } else if (response.bodyUsed) {
      throw new Error(await response.json());
    } else {
      throw new Error(`${response.status.toString()} ${response.statusText}`);
    }
  });
}

export function del(path = '', data = {}) {
  return request(path, 'DELETE', data).then(async (response) => {
    if (response.ok) {
      try {
        const d = await response.json();
        return d;
      } catch {
        return true;
      }
    } else if (response.bodyUsed) {
      throw new Error(await response.json());
    } else {
      throw new Error(`${response.status.toString()} ${response.statusText}`);
    }
  });
}

export function post(path = '', data = {}) {
  return request(path, 'POST', data).then(async (response) => {
    if (response.ok) {
      try {
        const d = await response.json();
        return d;
      } catch {
        return true;
      }
    } else if (response.bodyUsed) {
      throw new Error(await response.json());
    } else {
      throw new Error(`${response.status.toString()} ${response.statusText}`);
    }
  });
}

export function put(path = '', data = {}) {
  return request(path, 'PUT', data).then(async (response) => {
    if (response.ok) {
      try {
        const d = await response.json();
        return d;
      } catch {
        return true;
      }
    } else if (response.bodyUsed) {
      throw new Error(await response.json());
    } else {
      throw new Error(`${response.status.toString()} ${response.statusText}`);
    }
  });
}
