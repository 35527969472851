import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { Editor, ButtonsConfig } from 'ketcher-react';
import { Ketcher } from 'ketcher-core';
import { StandaloneStructServiceProvider } from 'ketcher-standalone';
import 'ketcher-react/dist/index.css';
import { nanoid } from 'nanoid';
import { LdButton } from '@emdgroup-liquid/liquid/dist/react';

const structServiceProvider = new StandaloneStructServiceProvider();

const config: ButtonsConfig = {
  miew: { hidden: true },
  fullscreen: { hidden: true },
  analyse: { hidden: true },
  layout: { hidden: true },
  clean: { hidden: true },
  arom: { hidden: true },
  dearom: { hidden: true },
  'enhanced-stereo': { hidden: true },
  cip: { hidden: true },
  text: { hidden: true },
};
interface Props {
  smiles?: string;
  onSave: (arg0: string) => void;
  onCancel?: () => void;
}
const ChemicalEditor: React.FC<Props> = ({ smiles, onSave, onCancel }) => {
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (init && smiles !== undefined) {
      setTimeout(renderSmiles, 50);
    }
  }, [init, smiles]);

  const renderSmiles = async () => {
    if (smiles !== undefined) KetcherFunctions.renderFromCtab(smiles);
  };

  const handleSave = async () => {
    const smiles = await KetcherFunctions.getSmiles();
    onSave(smiles);
  };

  return (
    <div className="flex flex-col w-full">
      <Editor
        key={nanoid()}
        staticResourcesUrl={process.env.PUBLIC_URL}
        buttons={config}
        structServiceProvider={structServiceProvider}
        errorHandler={(err) => console.log(err)}
        onInit={(ketcher: Ketcher) => {
          (global as any).ketcher = ketcher;
          (global as any).KetcherFunctions = KetcherAPI(
            (global as any).ketcher
          );
          setInit(true);
        }}
      />
      <div className="p-ld-8 flex justify-end gap-ld-8">
        {onCancel && (
          <LdButton mode="danger-secondary" onClick={onCancel}>
            Cancel
          </LdButton>
        )}
        <LdButton onClick={handleSave}>Save</LdButton>
      </div>
    </div>
  );
};

export default ChemicalEditor;
