import _ from 'lodash';
import { smilesValidationResponseScheme } from 'types/app/types';
import { post } from './api';

export async function validateSmiles(smiles: string) {
  const body = {
    type: 'smiles',
    value: smiles,
  };
  const result = await post(`/validator`, body);
  return smilesValidationResponseScheme.validateSync(result);
}

export const validateSmilesDebounced = _.debounce(validateSmiles, 300);
