import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import { listExperimentsForProject } from 'services/experiments';

import { Experiment, Project } from 'types/api/types';

import { SpaceDetails } from 'components/ChemicalSpace';

interface Props {
  project: Project;
  experiments?: Experiment[];
}

const ProjectItem: React.FC<Props> = ({ project }) => {
  const { projectId, ...rest } = project;
  const dispatch = useDispatch();

  const { experiments } = useSelector((state) => state.experiments);

  useEffect(() => {
    if (experiments[projectId] === undefined)
      dispatch(listExperimentsForProject(projectId));
  }, []);

  return (
    <NavLink to={`/projects/${projectId}`} className="w-full">
      <SpaceDetails
        key={projectId}
        projectId={projectId}
        experiments={experiments[projectId]}
        {...rest}
      />
    </NavLink>
  );
};

export default ProjectItem;
