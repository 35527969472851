import { value } from 'assets/styles/variables/_breakpoints';
import { TableColumn } from './types';

export const tableColumns: TableColumn[] = [
  {
    key: 'targetName',
    title: 'Target',
    inputType: 'text',
    registerOptions: ({ getValues }, existingTargets, isEditing) => ({
      required: 'Target name is required',
      validate: (value) => {
        if (!value) return false;
        if (!isEditing) {
          if (
            !Object.values(existingTargets).some(
              (t) => t?.['targetName'] === value
            )
          )
            return true;
          else return 'Target name is unique';
        } else {
          const current = getValues('targetName');
          if (
            !Object.values(existingTargets).some(
              (t) => value !== current && t?.['targetName'] === value
            )
          )
            return true;
          else return 'Target name is unique';
        }
      },
    }),
  },
  {
    key: 'targetObjective',
    title: 'Objective',
    inputType: 'select',
    options: ['Min', 'Max', 'Match'],
    registerOptions: () => ({
      required: 'Objective is required',
    }),
  },
  {
    key: 'targetWeight',
    title: 'Weight',
    inputType: 'number',
    registerOptions: (_, existingTargets, isEditing) => ({
      validate: (value) => {
        const numberOfExistingTargets = existingTargets
          ? Object.keys(existingTargets).length
          : 0;

        const hasSingleTarget =
          numberOfExistingTargets === 0 ||
          (isEditing && numberOfExistingTargets === 1);

        if (hasSingleTarget) {
          return true;
        }

        return value !== '' && value !== undefined
          ? true
          : 'Weight is required for multiple targets';
      },
    }),
  },
  {
    key: 'lowerBound',
    title: 'Lower Bound',
    inputType: 'number',
    registerOptions: ({ getValues }, existingTargets, isEditing) => ({
      validate: (value) => {
        const numberOfExistingTargets = existingTargets
          ? Object.keys(existingTargets).length
          : 0;

        const hasSingleTarget =
          numberOfExistingTargets === 0 ||
          (isEditing && numberOfExistingTargets === 1);

        const upperBound = getValues('upperBound');
        // @ts-ignore
        const isUpperBoundSet = upperBound !== '' && upperBound !== undefined;
        const isCurrentValueSet = value !== '' && value !== undefined;

        const isTargetObjectiveMatch = getValues('targetObjective') === 'Match';

        if (!isCurrentValueSet) {
          if (isUpperBoundSet) {
            return 'Lower bound is required when Upper bound is set';
          }

          if (isTargetObjectiveMatch) {
            return 'Lower bound is required when Target Objective is set to "Match"';
          }

          if (!hasSingleTarget) {
            return 'Lower bound is required for multiple targets';
          }

          return true;
        }

        const isValidComparedToUpperBound =
          isUpperBoundSet && isCurrentValueSet
            ? Number(value) < Number(upperBound)
            : true;

        return isValidComparedToUpperBound
          ? true
          : 'Lower Bound should be less than Upper Bound';
      },
    }),
  },
  {
    key: 'upperBound',
    title: 'Upper Bound',
    inputType: 'number',
    registerOptions: ({ getValues }, existingTargets, isEditing) => ({
      validate: (value) => {
        const numberOfExistingTargets = existingTargets
          ? Object.keys(existingTargets).length
          : 0;

        const hasSingleTarget =
          numberOfExistingTargets === 0 ||
          (isEditing && numberOfExistingTargets === 1);

        const lowerBound = getValues('lowerBound');
        // @ts-ignore
        const isLowerBoundSet = lowerBound !== '' && lowerBound !== undefined;
        const isCurrentValueSet = value !== '' && value !== undefined;

        const isTargetObjectiveMatch = getValues('targetObjective') === 'Match';

        if (!isCurrentValueSet) {
          if (isLowerBoundSet) {
            return 'Upper bound is required when Lower bound is set';
          }

          if (isTargetObjectiveMatch) {
            return 'Upper bound is required when Target Objective is set to "Match"';
          }

          if (!hasSingleTarget) {
            return 'Upper bound is required for multiple targets';
          }

          return true;
        }

        const isValidComparedToLowerBound =
          isLowerBoundSet && isCurrentValueSet
            ? Number(value) > Number(lowerBound)
            : true;

        return isValidComparedToLowerBound
          ? true
          : 'Upper Bound should be less than Lower Bound';
      },
    }),
  },
];
