import React, { FC } from 'react';
import Highlighter from 'react-highlight-words';
import { SmilesParameterValue } from '../../../types/api/types';

interface SubstanceSearchHit {
  objectID: string;
  'Ligand Name': string;
  'IUPAC Name': string;
  SMILES: string;
}

export type HitsProps = {
  onSelectResult: (arg: SmilesParameterValue) => void;
  hits: SubstanceSearchHit[];
  query: string;
};

export const Hits: FC<HitsProps> = ({ hits, query, onSelectResult }) => {
  return (
    <div className="flex w-full">
      <div className="self-end self-start hidden" />
      <ol className="flex flex-col fixed card-active card bg-white z-10 divide-sensitive-grey divide-y-2">
        {hits.map((hit) => (
          <li
            key={hit.objectID}
            className="flex cursor-pointer hover:bg-sensitive-grey p-ld-8 break-all"
            onClick={() =>
              onSelectResult({
                SMILES: (hit.SMILES as string).replace(/\s+/g, ''), // strip smiles string to remove faulty white spaces
                Name: hit['Ligand Name'] as string,
              })
            }
          >
            <Highlighter
              highlightClassName="font-black"
              highlightTag="span"
              searchWords={query.split(' ')}
              autoEscape={true}
              textToHighlight={hit['Ligand Name']}
            />
          </li>
        ))}
      </ol>
    </div>
  );
};
