/* eslint-disable import/named */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Constraint,
  ExpertOptions,
  Parameter,
  ParameterRecommendation,
  TargetVariable,
} from 'types/api/types';
import { del, get, post, put } from 'util/api';
import { parseTarget } from 'util/project';

export const listProjects = createAsyncThunk(
  'projects/listProjects',
  async () => get('project')
);
export const getProjectById = createAsyncThunk(
  'projects/getProjectById',
  async (projectId: string) => get('project/' + projectId)
);
export const getProjectStatusById = createAsyncThunk(
  'projects/getProjectStatusById',
  async (projectId: string) => get('project/' + projectId)
);
export const createProject = createAsyncThunk(
  'projects/createProject',
  async (body: {
    name: string;
    batchSize: number;
    targets: TargetVariable[];
    expertOptions?: ExpertOptions;
    parameterSpace: Parameter[];
    constraints: Constraint[];
  }) => {
    const { targets, ...rest } = body;
    const data = await post('project', {
      ...rest,
      targets: targets.map(parseTarget),
    });
    return data;
  }
);
export const closeProject = createAsyncThunk(
  'projects/closeProject',
  async (projectId: string) => {
    await del(`project/${projectId}`);
    return projectId;
  }
);
export const reopenProject = createAsyncThunk(
  'projects/reopenProject',
  async (projectId: string) => {
    await post(`project/${projectId}`);
    return projectId;
  }
);
export const expandChemicalSpace = createAsyncThunk(
  'projects/expandChemicalSpace',
  async ({
    projectId,
    parameterSpace,
    experiments,
  }: {
    projectId: string;
    parameterSpace: Parameter[];
    experiments: { [key: string]: ParameterRecommendation };
  }) => {
    const data = await put(`project/${projectId}`, {
      parameters: parameterSpace,
      experiments,
    });
    return data;
  }
);

export const getBaybeConfigs = async (projectId: string) => {
  const res = await get(`project/${projectId}/config`);
  return await String.fromCharCode(...res.data);
};

export const listConstraintsForProject = createAsyncThunk(
  'project/listConstraints',
  async (projectId: string) => {
    const data = await get(`/project/${projectId}/constraints`);
    return {
      projectId,
      data,
    };
  }
);
