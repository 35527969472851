import React, { useCallback } from 'react';
import { useDispatch, useSelector } from '../../../store';
import {
  revokeInvitation,
  selectInvitationsForProject,
} from '../../../services/projectInvitations';
import {
  LdBadge,
  LdButton,
  LdCard,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
// eslint-disable-next-line import/named
import { JSX } from '@emdgroup-liquid/liquid';
import moment from 'moment';
import { useUser } from '@emdgroup/react-auth';

interface InvitedUsersProps {
  projectId: string;
}

const InvitedUsers: React.FC<InvitedUsersProps> = ({ projectId }) => {
  const invitations = useSelector(selectInvitationsForProject(projectId));
  const { info } = useUser();
  const dispatch = useDispatch();

  const handleRevokeClick = useCallback(
    (inviteId: string) => {
      dispatch(
        revokeInvitation({
          projectId,
          inviteId,
        })
      );
    },
    [projectId, dispatch]
  );

  if (!invitations || !Array.isArray(invitations) || invitations.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="ld-badge--pending ld-badge--accepted ld-badge--expired ld-badge--rejected ld-badge--pending hidden" />
      <LdCard className="w-full mb-6" size="sm">
        <LdTypo variant="h4">People with access</LdTypo>
        <div className="w-1/3 border-b border-b-sensitive-grey-darkest" />
        <div className="w-full">
          {invitations.map((invitation, index) => {
            const isLast = index === invitations.length - 1;
            const icon: Record<typeof invitation.status, JSX.LdBadge['icon']> =
              {
                pending: '',
                accepted: 'checkmark',
                rejected: '',
                expired: '',
              };
            return (
              <div
                key={invitation.inviteId}
                className={`flex justify-between pt-4 pb-4${
                  isLast ? '' : ' border-b border-b-sensitive-grey-darkest'
                }`}
              >
                <div>
                  <LdTypo>{invitation.email}</LdTypo>
                  <LdTypo variant="body-xs">
                    {moment(invitation.createdAt).format('lll')}
                  </LdTypo>
                </div>
                <div className="flex items-center">
                  {invitation.invitee?.userId !== info?.sub && (
                    <LdButton
                      size="sm"
                      className="mr-2"
                      onClick={() => handleRevokeClick(invitation.inviteId)}
                    >
                      Revoke
                    </LdButton>
                  )}

                  <div className="flex flex-col items-center">
                    <LdTypo variant="label-s" className="mb-2">
                      {invitation.role}
                    </LdTypo>
                    <LdBadge
                      icon={icon[invitation.status]}
                      className={`ld-badge--${invitation.status} mr-2`}
                    >
                      {invitation.status}
                    </LdBadge>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </LdCard>
    </div>
  );
};

export default InvitedUsers;
