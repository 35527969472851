import React, { FC, useMemo } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { LdIcon, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import { User } from '../../../../types/api/types';

type UserIconProps = {
  id: string;
  changedBy?: User;
  changedAt: string;
};

export const UserIcon: FC<UserIconProps> = ({ id, changedBy, changedAt }) => {
  const email = changedBy?.email;

  const initials = useMemo<string | null>(() => {
    if (!email) {
      return null;
    }

    const [fullName] = email.split('@');

    if (!fullName) {
      return null;
    }

    const [firstName, lastName] = fullName.toUpperCase().split('.');

    if (!lastName) {
      return firstName[0];
    }

    return `${firstName[0]}${lastName[0]}`;
  }, [email]);

  const backgroundColor = useMemo<string | undefined>(() => {
    if (!initials) {
      return undefined;
    }

    return `#${intToRGB(hashCode(initials))}`;
  }, [initials]);

  const textColor = useMemo<'white' | 'black'>(() => {
    if (!backgroundColor) {
      return 'white';
    }
    const [, r1, r2, g1, g2, b1, b2] = backgroundColor.toString();
    const rgb = [`${r1}${r2}`, `${g1}${g2}`, `${b1}${b2}`];

    const brightness = Math.round(
      (parseInt(rgb[0]) * 299 +
        parseInt(rgb[1]) * 587 +
        parseInt(rgb[2]) * 114) /
        1000
    );
    return brightness > 125 ? 'black' : 'white';
  }, [backgroundColor]);

  const tooltipId = `experiment-${id}--user-tooltip`;

  const tooltipText = useMemo(() => {
    const lines = [];

    if (email) {
      lines.push(email);
    }
    if (changedAt) {
      lines.push(moment(changedAt).format('lll'));
    }

    return lines.join('<br />');
  }, [email, changedAt]);

  return (
    <div className="relative flex content-center h-ld-32 w-ld-32">
      <ReactTooltip
        id={tooltipId}
        type="info"
        place="right"
        effect="solid"
        className="tooltip"
        multiline={true}
      />
      <div
        className="relative flex items-center justify-center rounded-full h-ld-32 w-ld-32 p-ld-6 bg-rich-purple-lighter text-thm-primary"
        style={{
          backgroundColor,
          color: textColor,
        }}
        data-tip={tooltipText}
        data-for={tooltipId}
      >
        {initials ? (
          <LdTypo variant="body-s">{initials}</LdTypo>
        ) : (
          <LdIcon name="user" />
        )}
      </div>
    </div>
  );
};

function hashCode(str: string) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}
