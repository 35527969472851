import React, { FormEvent, useEffect, useRef, useState } from 'react';
import SortingBar from 'components/Search/SortingBar';
import { LdButton, LdIcon, LdInput } from '@emdgroup-liquid/liquid/dist/react';
import Dropdown from 'components/Search/Dropdown';
import { RoleFilter, roleFilterItems } from 'types/app/types';
import { Visibility, VisibilityOff } from 'components/Icons';

interface Props {
  showClosed: boolean;
  handleToggleShowClosed: () => void;
  handleChangeSorting: (arg0: {
    order: 'asc' | 'desc';
    orderBy?: string | undefined;
  }) => void;
  onSearch: (arg0?: string) => void;
  sortingOptions: {};

  filterByRole?: RoleFilter;
  onFilterByRoleChange: (role?: RoleFilter) => void;
}

const Header: React.FC<Props> = ({
  showClosed,
  handleChangeSorting,
  handleToggleShowClosed,
  onSearch,
  sortingOptions,
  filterByRole,
  onFilterByRoleChange,
}) => {
  const [query, setQuery] = useState<string>();

  const handleSearch = React.useCallback(
    (event: FormEvent<HTMLLdInputElement>) => {
      setQuery((event.target as HTMLLdInputElement).value);
    },
    []
  );
  const inputRef = useRef<HTMLLdInputElement>(null);
  const clearSearchBar = () => {
    setQuery(undefined);
  };
  useEffect(() => {
    onSearch(query);
  }, [query, onSearch]);

  return (
    <div className="w-full pl-4 pr-8 mb-6 database__head">
      <div className="flex items-center mb-5">
        <LdInput
          className="flex-grow mr-4"
          placeholder="Search..."
          size="sm"
          ref={inputRef}
          onInput={handleSearch}
          value={query}
          name="global-search-input"
          type="text"
        >
          <LdButton slot="start" size="sm" aria-label="Submit" mode="ghost">
            <LdIcon name="magnifier" />
          </LdButton>
          <LdButton
            slot="end"
            size="sm"
            aria-label="Clear"
            mode="ghost"
            type="button"
            onClick={clearSearchBar}
          >
            <LdIcon name="cross" />
          </LdButton>
        </LdInput>
        <div className="flex items-center justify-end">
          <LdButton mode="ghost" onClick={handleToggleShowClosed} size="sm">
            {showClosed ? 'Hide' : 'Show'} closed Projects
            <LdIcon>{showClosed ? <VisibilityOff /> : <Visibility />}</LdIcon>
          </LdButton>

          <Dropdown
            onChange={(value) => {
              onFilterByRoleChange(value as RoleFilter);
            }}
            options={roleFilterItems.map((s) => s)}
            placeholder="Role"
            value={filterByRole}
          />

          <SortingBar
            onChange={handleChangeSorting}
            options={sortingOptions}
            default="createdAt"
          />
        </div>
      </div>
    </div>
  );
};

export { Header as default };
