import { Routes, Route, Navigate } from 'react-router-dom';

import MyProjects from './Database';
import Upload from './Create';
import Project from './Project';
import UserGuide from './UserGuide';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import MailInvitation from './Invitations/MailInvitation';
import Invitations from './Invitations';
import Feedback from './Feedback';
import { RoadmapPage } from 'pages/RoadmapPage';
import ChangelogPage from 'pages/Changelog/ChangelogPage';
import { FaqPage } from 'pages/FaqPage';

const Pages = () => {
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/projects" />} />
        <Route path="/new-project" element={<Upload />} />
        <Route path="/projects" element={<MyProjects />} />
        <Route path="/user-guide" element={<UserGuide />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/projects/:projectId" element={<Project />} />
        <Route
          path="/invite/:projectId/:inviteId"
          element={<MailInvitation />}
        />
        <Route path="/invitations" element={<Invitations />} />
        <Route path="/roadmap" element={<RoadmapPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/changelog" element={<ChangelogPage />} />
      </Routes>
    </>
  );
};

export default Pages;
