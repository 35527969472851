// eslint-disable-next-line import/named
import { JSX } from '@emdgroup-liquid/liquid';
import React from 'react';
import { LdBadge, LdTooltip, LdTypo } from '@emdgroup-liquid/liquid/dist/react';
import { ProjectRole } from 'types/api/types';

import './styles.scss';

interface Props {
  role: ProjectRole;
}
const RoleBadge: React.FC<Props> = ({ role }) => {
  const icon: Record<typeof role, JSX.LdBadge['icon']> = {
    Owner: 'keys',
    Editor: 'pen',
    Viewer: 'visibility',
  };
  return (
    <LdTooltip arrow className="h-1">
      <LdBadge slot="trigger" icon={icon[role]} className={`mr-2`} size="lg" />
      <LdTypo className="p-ld-8" variant="body-xs">
        Role: {role}
      </LdTypo>
    </LdTooltip>
  );
};

export default RoleBadge;
