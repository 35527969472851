import algoliasearch from 'algoliasearch/lite';
import _ from 'lodash';
import React, { useState, useMemo, useEffect } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks';
import { Project } from 'types/api/types';
import { get } from 'util/api';

export const searchClient = algoliasearch(
  'P9FPFQJ3TU',
  '1b73309f9bf75af19312088e82399cc0'
);

export const SubstanceSearch: React.FC = ({ children }) => {
  return (
    <InstantSearch searchClient={searchClient} indexName="substances">
      {children}
    </InstantSearch>
  );
};

export const searchCompound = (searchTerm: string) =>
  get('search?query=' + searchTerm);

const marshallHit = (item: any) => {
  return {
    objectID: item.cid,
    Synonyms: item.synonyms,
    'Ligand Name': item.name,
    'IUPAC Name': item.iupacname,
    SMILES: item.smiles,
  };
};

export const useSearch = (limit?: number) => {
  const [query, setQuery] = useState<string>();
  const [status, setStatus] = useState<string>('idle');
  const [hits, setHits] = useState<any[]>([]);
  const refine = (value: string) => {
    setQuery(value);
    setStatus('loading');
    searchCompound(value)
      .then((hits) => {
        let results = hits;
        if (limit !== undefined) {
          results = hits.slice(0, Math.min(hits.length, limit));
        }
        setHits(results.map(marshallHit));
        setStatus('success');
      })
      .catch(() => setStatus('error'));
  };
  const debouncedRefine = useMemo(() => {
    return _.debounce(refine, 300);
  }, []);

  useEffect(() => {
    return debouncedRefine.cancel;
  }, []);

  return { query, refine: debouncedRefine, hits, status };
};

const extractQuery = (searchTerm: string): string[] => {
  return searchTerm
    .split(' ')
    .map((x) => x.toLowerCase())
    .filter((x) => x);
};

const filterProjectByTerm = (
  project: Project,
  searchTerm?: string
): boolean => {
  if (!searchTerm) return true;

  const query = extractQuery(searchTerm);
  return query.every((queryItem) => {
    return project.name.toLowerCase().includes(queryItem);
  });
};

export { filterProjectByTerm };
