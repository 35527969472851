import UAParser from 'ua-parser-js';

const useClientinfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  const isChrome = result.browser.name?.toLowerCase() === 'chrome';
  return { ...result, isChrome };
};

export { useClientinfo as default };
